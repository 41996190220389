import React from 'react';
import { Snackbar, Button } from '@material-ui/core';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';

// Only include offline plugin when ENV_PRODUCTION is true
// above import will be removed by babel during compilation
let offlinePluginRuntime: typeof OfflinePluginRuntime;
if (ENV_PRODUCTION) {
  // eslint-disable-next-line global-require
  offlinePluginRuntime = require('offline-plugin/runtime');
}

export interface UpdateComponentState {
  updateReady: boolean;
}

// Reload the page if the update is installed within this number of seconds after initial load
// After the interval the user is notified to reload the page
const INSTANT_UPDATE_INTERVAL = 1000;

class UpdateComponent extends React.Component<Record<string, unknown>, UpdateComponentState> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = {
      updateReady: false,
    };
  }

  public componentDidMount() {
    if (!offlinePluginRuntime) {
      return;
    }
    const mountTime = Date.now();
    offlinePluginRuntime.install({
      onUpdateReady: () => offlinePluginRuntime.applyUpdate(),
      onUpdated: () => {
        if (Date.now() - mountTime < INSTANT_UPDATE_INTERVAL) {
          this.doUpdate();
        } else {
          this.setState({ updateReady: true });
        }
      },
    });
  }

  private doUpdate = () => {
    window.location.reload();
  };

  public render() {
    const { updateReady } = this.state;
    const { children } = this.props;
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={updateReady}
          ContentProps={{ 'aria-describedby': 'message-id' }}
          message={<span id="message-id">There is a new version available</span>}
          action={
            <Button color="secondary" size="small" onClick={this.doUpdate}>
              Update now!
            </Button>
          }
        />
        {children}
      </>
    );
  }
}

export default UpdateComponent;
