import React from 'react';

import 'texts/i18n';
import BootstrapComponent from './bootstrap';
import Routes from './Routes';

import './style/globals.css';

const App: React.FC = () => (
  <BootstrapComponent>
    <Routes />
  </BootstrapComponent>
);

export default App;
