import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import errorIcon from 'assets/errorIcon.svg';
import styleUtils from 'style/styleUtils';
import { AnimateHeight } from 'components/animations';
import { FormikErrors } from 'formik';

const styleRules = (theme: Theme) => {
  return createStyles({
    error: {
      ...styleUtils.rowStartCenter,
      paddingTop: theme.spacing(1),
    },
    message: {
      marginLeft: theme.spacing(1),
      fontSize: theme.fontSizes['12'],
      color: theme.palette.error.main,
      lineHeight: 1,
    },
  });
};
const useStyles = makeStyles(styleRules);

export default function AppFieldError({
  message,
}: {
  message?: string | FormikErrors<Record<string, unknown>> | string[] | FormikErrors<Record<string, unknown>>[];
}) {
  const styles = useStyles();

  return (
    <AnimateHeight visible={!!message}>
      <div className={styles.error}>
        <img alt="error" src={errorIcon} />
        <span className={styles.message}>{message}</span>
      </div>
    </AnimateHeight>
  );
}
