import { addDays } from 'date-fns';

export default function currentWeek() {
  const day: number = new Date().getDay();
  const startWeek = addDays(new Date(), -day + 1);
  const endWeek = addDays(new Date(), 7 - day);
  return `deadlineStart=${startWeek.getFullYear()}-${
    startWeek.getMonth() + 1
  }-${startWeek.getDate()}&deadlineEnd=${endWeek.getFullYear()}-${endWeek.getMonth() + 1}-${endWeek.getDate()}`;
}
