import React from 'react';
import homeIconWhite from 'assets/homeIconWhite.svg';
import homeIconGrey from 'assets/homeIconGrey.svg';
import taskIconWhite from 'assets/taskIconWhite.svg';
import taskIconGrey from 'assets/taskIconGrey.svg';
import bellIconWhite from 'assets/bellIconWhite.svg';
import bellIconGrey from 'assets/bellIconGrey.svg';
import assetsIconGrey from 'assets/assetsIconGrey.svg';
import assetsIcon from 'assets/assetsIcon.svg';
import useGetNotifications from 'containers/shared/Notifications/useGetNotifications';
import useGetUser from 'utils/useGetUser.hook';
import DrawerLink from './DrawerLink';

interface Props {
  id: string;
}

const TechnicianMenu: React.FC<Props> = ({ id }) => {
  const { unreadCount } = useGetNotifications();
  const userData = useGetUser(id);

  return (
    <>
      <DrawerLink to="/technician/home" name="Home" icon={homeIconGrey} activeIcon={homeIconWhite} />
      <DrawerLink to="/technician/works" name="Tasks" icon={taskIconGrey} activeIcon={taskIconWhite} />
      <DrawerLink
        to="/technician/notifications"
        name="Notifications"
        icon={bellIconGrey}
        activeIcon={bellIconWhite}
        badge={unreadCount}
      />
      {userData?.fullAccess && (
        <DrawerLink
          to={`/technician/${id}/assets`}
          name="Asset Register"
          icon={assetsIconGrey}
          activeIcon={assetsIcon}
        />
      )}
    </>
  );
};

export default TechnicianMenu;
