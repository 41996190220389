// TODO: move apiRoot to env
export const apiRoot = process.env.API_ROOT;

function parseJSON(response: Response) {
  if (response.status === 204) {
    return undefined;
  }
  return response.json();
}

function checkStatus(response: Response) {
  if (response.status !== 200 && response.status !== 204) {
    throw response;
  }
  return response;
}

export default function request(url: string, options: RequestInit) {
  return window.fetch(url, options).then(checkStatus).then(parseJSON);
}

export function get<T>(url: string): Promise<T> {
  return request(`${apiRoot}${url}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
  });
}

export function post<T>(url: string, postData: Record<string, unknown>): Promise<T> {
  const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  return request(`${apiRoot}${url}`, {
    headers,
    method: 'POST',
    body: JSON.stringify(postData),
    credentials: 'include',
  });
}

export async function fileUpload(url: string, file: File) {
  try {
    await request(url, {
      method: 'PUT',
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: file,
    });
  } catch (e) {
    if (e.status !== 201) throw new Error('Failed to upload file');
  }
}
