const enUrls = {
  queries: {
    page: 'page',
    search: 'search',
    client: 'client',
    task: 'task',
    asset: 'asset',
    urgent: 'urgent',
    dateRange: 'date-range',
    type: 'type',
    status: 'status',
    systemType: 'systemType',
    systemRef: 'systemRef',
    owner: 'owner',
    responsible: 'responsible',
    approval: 'approval',
    deadlineStart: 'deadlineStart',
    deadlineEnd: 'deadlineEnd',
    system: 'system',
    compliant: 'compliant',
  },
};
export default enUrls;
