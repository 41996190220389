import { CSSProperties } from 'react';

const asStyleUtils = <T>(styles: { [K in keyof T]: CSSProperties }) => styles;

const styleUtils = asStyleUtils({
  rowStartCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  rowCenterCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowEndCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  columnStartCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  columnCenterCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  absoluteCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    height: '100%',
    left: 0,
    width: '100%',
  },
});

export default styleUtils;
