import { observable, action, computed, decorate } from 'mobx';
import { ApolloClient } from '@apollo/client';

import { post } from 'utils/Api';
import { Role, user_user as User } from 'models/graphql';

interface userClient {
  projects: boolean;
  modules: any;
}



export default class AuthStore {
  // These are observables
  // @observer does not work with an initial value of undefined
  // The decorate function works fine and is used at the bottom of this file
  isLoggedIn = false;
  user?: User = undefined;
  userClient?: userClient = undefined;

  @action.bound
  public login(user: User, clientDetails?: userClient): void {
    this.isLoggedIn = true;
    this.user = observable(user);
    if (clientDetails)
    {
      this.userClient = observable(clientDetails);
    }



  
  }

  @action.bound
  // eslint-disable-next-line @typescript-eslint/ban-types
  public async logout(apolloClient: ApolloClient<object>): Promise<void> {
    await Promise.all([post('auth/logout', {}), apolloClient.clearStore()]);
    window.localStorage.removeItem('selectedModules');
    this.isLoggedIn = false;
  }

  @action.bound
  public reset() {
    this.isLoggedIn = false;
    this.user = observable(undefined);
  }


  @action.bound
  public clientDetails(clientDetails: userClient): void {
    this.userClient = observable(clientDetails);
  }


  @computed get fallbackRedirect() {
    const redirectToLink = window.localStorage.getItem('redirectTo');

    if (!this.user) return '/auth/login';

    if (redirectToLink) {
      window.localStorage.removeItem('redirectTo');
      return JSON.parse(redirectToLink);
    }

    const companyId = this.user.company?.id;
    switch (this.user.role) {
      case Role.PROJECT_MANAGER:
        return '/pm';
      case Role.CLIENT_ENVIRONMENTAL_OFFICER:
      case Role.CLIENT_TEAM_MEMBER:
        if (!companyId) {
          throw Error('Invalid user data');
        }
        return `/client/${companyId}`;
      case Role.CONTRACTOR_OPERATIONS_MANAGER:
        if (!companyId) {
          throw Error(`No companyId provided for ${this.user.name}`);
        }
        return `/contractor/${companyId}`;
      case Role.CONTRACTOR_TECHNICIAN:
        if (!companyId) {
          throw Error('Invalid user data');
        }
        return '/technician';
      case Role.CLIENT_OPERATIVE:
        if (!companyId) {
          throw Error('Invalid user data');
        }
        return '/operative';
      default:
        throw Error('Not supported role');
    }
  }
}

decorate(AuthStore, {
  isLoggedIn: observable,
  user: observable,
  userClient: observable,
});
