import React from 'react';
import { List } from '@material-ui/core';
import { SET_NOTIFICATION_TO_READ, DELETE_NOTIFICATION } from 'containers/shared/Notifications/graphql';
import { useMutation } from '@apollo/client';
import {
  notificationSetToRead,
  notificationSetToReadVariables,
  notificationDelete,
  notificationDeleteVariables,
  NotificationType,
  notificationsToMe_notificationsToMe_nodes as NotificationData,
} from 'models/graphql';
import useText from 'texts/useText.hook';
import accessIcon from 'assets/accessIcon.svg';
import listIcon from 'assets/listIcon.svg';
import noNotificationsIcon from 'assets/noNotificationsIcon.svg';
import useMode, { Mode } from 'utils/useMode.hook';
import ContentStateWrapper from 'components/ContentStateWrapper';
import Notification from './Notification';

const NotificationList: React.FC<{
  loading?: boolean;
  notificationsToMe?: NotificationData[];
  onClose?: () => void;
}> = ({ notificationsToMe = [], onClose, loading }) => {
  const mode = useMode();
  const notifications = notificationsToMe.filter(
    // ensures that the deleted notifications will deleted from the notification list
    // without have to refetch the whole list.
    (notification) => !notification.deletedAt,
  );

  const [setNotificationToRead] = useMutation<notificationSetToRead, notificationSetToReadVariables>(
    SET_NOTIFICATION_TO_READ,
  );
  const [deleteNotification] = useMutation<notificationDelete, notificationDeleteVariables>(DELETE_NOTIFICATION);

  const { t, tt } = useText('notifications');

  return (
    <ContentStateWrapper
      loading={loading}
      hasData={notifications.length > 0}
      noData={{ icon: noNotificationsIcon, title: 'No notifications', subtitle: 'at this time' }}
    >
      <List>
        {notifications.map((notification) => {
          let title: string;
          let iconSrc: string;
          let subTitle: string | null | undefined;
          let additionalInfo: string | null | undefined;
          let date: Date | undefined;
          let url: string | undefined;

          if (notification.type === NotificationType.CONTRACTOR_REQUESTED) {
            iconSrc = accessIcon;
            subTitle = notification.payload?.company;
            title = tt('notifications')('titles')(notification.type);
            additionalInfo = notification.payload?.name;
            url =
              notification.payload?.entityId && mode.mode === Mode.CLIENT
                ? `/client/${mode.id}/configure/contractors/${notification.payload.entityId}`
                : undefined;
          } else if (notification.type === NotificationType.CONTRACTOR_USER_REQUESTED) {
            iconSrc = accessIcon;
            subTitle = notification.payload?.company;
            title = tt('notifications')('titles')(notification.type);
            additionalInfo = notification.payload?.name;
            url =
              notification.payload?.entityId && mode.mode === Mode.CLIENT
                ? `/client/${mode.id}/contractors/${notification.payload.entityId}/users`
                : undefined;
          } else if (
            notification.type === NotificationType.CONTRACTOR_ACCEPTED ||
            notification.type === NotificationType.CONTRACTOR_DENIED ||
            notification.type === NotificationType.CONTRACTOR_USER_ACCEPTED ||
            notification.type === NotificationType.CONTRACTOR_USER_DENIED
          ) {
            iconSrc = accessIcon;
            subTitle = notification.payload?.company;
            title = tt('notifications')('titles')(notification.type);
            additionalInfo = notification.payload?.name;
          } else if (notification.type === NotificationType.TASK_ASSIGNED_TO_INTERNAL) {
            iconSrc = listIcon;
            subTitle = notification.payload?.name;
            title = tt('notifications')('titles')(notification.type);
            date = notification.payload?.date ? new Date(notification.payload.date) : undefined;
            url = mode.mode === Mode.OPERATIVE ? `/operative/works/${notification.payload?.entityId}` : undefined;
          } else if (notification.type === NotificationType.TASK_ASSIGNED_TO_CONTRACTOR) {
            iconSrc = listIcon;
            subTitle = notification.payload?.name;
            title = tt('notifications')('titles')(notification.type);
            date = notification.payload?.date ? new Date(notification.payload.date) : undefined;
            url = mode.mode === Mode.CONTRACTOR ? `/contractor/${mode.id}/work-instructions` : undefined;
          } else if (notification.type === NotificationType.TASK_CONTRACTOR_ACCEPTED) {
            iconSrc = listIcon;
            subTitle = notification.payload?.name;
            title = tt('notifications')('titles')(notification.type);
            date = notification.payload?.date ? new Date(notification.payload.date) : undefined;
            url =
              mode.mode === Mode.CLIENT
                ? `/client/${mode.id}/tasks/${notification.payload?.siteId || ''}?task=${
                    notification.payload?.entityId
                  }`
                : undefined;
          } else if (notification.type === NotificationType.TASK_CONTRACTOR_REJECTED) {
            iconSrc = listIcon;
            subTitle = notification.payload?.name;
            title = tt('notifications')('titles')(notification.type);
            date = notification.payload?.date ? new Date(notification.payload.date) : undefined;
            url =
              mode.mode === Mode.CLIENT
                ? `/client/${mode.id}/tasks/${notification.payload?.siteId || ''}?task=${
                    notification.payload?.entityId
                  }`
                : undefined;
          } else if (notification.type === NotificationType.TASK_ASSIGNED_TO_CONTRACTOR_USER) {
            iconSrc = listIcon;
            subTitle = notification.payload?.name;
            title = tt('notifications')('titles')(notification.type);
            date = notification.payload?.date ? new Date(notification.payload.date) : undefined;
            url = mode.mode === Mode.TECHNICIAN ? `/technician/works/${notification.payload?.entityId}` : undefined;
          } else if (notification.type === NotificationType.TASK_ACCEPTED) {
            iconSrc = listIcon;
            subTitle = notification.payload?.name;
            title = tt('notifications')('titles')(notification.type);
            url = mode.mode === Mode.TECHNICIAN ? `/technician/works/${notification.payload?.entityId}` : undefined;
          } else if (notification.type === NotificationType.TASK_REJECTED) {
            iconSrc = listIcon;
            subTitle = notification.payload?.name;
            title = tt('notifications')('titles')(notification.type);
            url = mode.mode === Mode.TECHNICIAN ? `/technician/works/${notification.payload?.entityId}` : undefined;
          } else {
            return <div key={notification.id} />;
          }

          // Timestamp
          const minutesAgo = Math.floor(
            (new Date().getTime() - new Date(notification.createdAt).getTime()) / 1000 / 60,
          );
          let timestamp: string;
          if (minutesAgo === 0) timestamp = t('notifications')('justNow');
          else if (minutesAgo < 60) {
            timestamp = t('notifications')('minutesAgo', {
              count: minutesAgo,
            });
          } else if (minutesAgo < 60 * 24) {
            timestamp = t('notifications')('hoursAgo', {
              count: Math.floor(minutesAgo / 60),
            });
          } else {
            timestamp = t('notifications')('daysAgo', {
              count: Math.floor(minutesAgo / 60 / 24),
            });
          }
          return (
            <Notification
              key={notification.id}
              title={title}
              iconSrc={iconSrc}
              timestamp={timestamp}
              read={notification.read}
              subTitle={subTitle || undefined}
              additionalInfo={additionalInfo || undefined}
              date={date}
              url={url}
              onMarkAsRead={() =>
                setNotificationToRead({
                  variables: { id: notification.id },
                  optimisticResponse: {
                    notificationSetToRead: {
                      id: notification.id,
                      read: true,
                      deletedAt: notification.deletedAt,
                      __typename: 'NotificationOutput',
                    },
                  },
                })
              }
              onDelete={() =>
                deleteNotification({
                  variables: { id: notification.id },
                  optimisticResponse: {
                    notificationDelete: {
                      id: notification.id,
                      read: notification.read,
                      deletedAt: new Date(),
                      __typename: 'NotificationOutput',
                    },
                  },
                })
              }
              onLinkOpen={() => {
                if (onClose) onClose();
              }}
            />
          );
        })}
      </List>
    </ContentStateWrapper>
  );
};

export default NotificationList;
