import React from 'react';

import contactsIconGrey from 'assets/contactsIconGrey.svg';
import contactsIcon from 'assets/contactsIcon.svg';
import contractorIcon from 'assets/contractorIcon.svg';
import contractorIconGrey from 'assets/contractorIcon-grey.svg';
import dashboardIcon from 'assets/dashboardIcon.svg';
import dashboardIconGrey from 'assets/dashboardIconGrey.svg';
import DrawerLink from './DrawerLink';
import { withStores, AuthStore } from 'stores';

interface PmMenuProps {
  authStore: AuthStore;
}

interface PmMenuProps {
  authStore: AuthStore;
}


const PmMenu: React.FC<PmMenuProps>  =  ({ authStore }) => {

  const isconsultantuser= authStore.user?.isconsultant;

  if (!isconsultantuser)
  {
    return <>
    <DrawerLink to="/pm/dashboard" name="Dashboard" icon={dashboardIconGrey} activeIcon={dashboardIcon} />
    <DrawerLink to="/pm/clients" name="Clients" icon={contactsIconGrey} activeIcon={contactsIcon} />
    <DrawerLink to="/pm/contractors" name="Contractors" icon={contractorIconGrey} activeIcon={contractorIcon} />
    </>;

  }
  else
  {
    return <>
    <DrawerLink to="/pm/dashboard" name="Dashboard" icon={dashboardIconGrey} activeIcon={dashboardIcon} />
    <DrawerLink to="/pm/clients" name="Clients" icon={contactsIconGrey} activeIcon={contactsIcon} />
    </>;


  }

}


export default  withStores('authStore') (PmMenu);
