import React from 'react';
import tasksIcon from 'assets/tasksIcon.svg';
import tasksIconGrey from 'assets/tasksIconGrey.svg';
import useText from 'texts/useText.hook';
import DrawerLink from './DrawerLink';

interface Props {
  id: string;
}

const ContractorMenu: React.FC<Props> = ({ id }) => {
  const { t } = useText('tasks');

  return (
    <>
      <DrawerLink
        to={`/contractor/${id}/work-instructions`}
        name={t('tasks')('workInstructions')}
        icon={tasksIconGrey}
        activeIcon={tasksIcon}
      />
    </>
  );
};

export default ContractorMenu;
