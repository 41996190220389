import { SystemType } from 'models/graphql';

const enSystemTypes: Record<SystemType, string> = {
  [SystemType.AIR]: 'Air',
  [SystemType.PROCESS]: 'Process',
  [SystemType.FOUL]: 'Foul',
  [SystemType.STORM]: 'Storm',
  [SystemType.NOISE]: 'Noise',
  [SystemType.ODOUR]: 'Odour',
  [SystemType.BUNDS]: 'Bunds',
  [SystemType.GENERAL_FACILITY]: 'General Facility',
  [SystemType.DUST]: 'Dust',
  [SystemType.EQUIPMENT_VEHICLES]: 'Equipment / Vehicles',
  [SystemType.MISCELLANEOUS]: 'Miscellaneous',
  [SystemType.BIOFILTER]: 'Biofilter',
  [SystemType.GROUND_WATER]: 'Ground Water',
  [SystemType.FIRE_EQUIPMENT]: 'Fire Equipment',
};
export default enSystemTypes;
