import React from 'react';
import { Switch } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { useObserver } from 'mobx-react';

import PrivateRoute from 'components/PrivateRoute';
import { useStores } from 'stores';
import ClientContainer from './containers/Client';
import ContractorContainer from './containers/Contractor';
import PmContainer from './containers/Pm';
import AuthContainer from './containers/Auth';
import TechnicianContainer from './containers/Technician';
import OperativeContainer from './containers/Operative';
import DefaultContainer from './containers/Default';

const Routes: React.FC = () => {
  const { authStore } = useStores();
  const redirect = useObserver(() => authStore.fallbackRedirect);
  return (
    <Switch>
      <Route path="/auth" component={AuthContainer} />
      <DefaultContainer>
        <Switch>
          <PrivateRoute path="/client/:clientId" component={ClientContainer} />
          <PrivateRoute path="/contractor/:contractorId" component={ContractorContainer} />
          <PrivateRoute path="/operative" component={OperativeContainer} />
          <PrivateRoute path="/pm" component={PmContainer} />
          <PrivateRoute path="/technician" component={TechnicianContainer} />
          <Redirect to={redirect} />
        </Switch>
      </DefaultContainer>
    </Switch>
  );
};
export default Routes;
