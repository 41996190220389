import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { notificationsToMe } from 'models/graphql';
import { GET_NOTIFICATIONS } from './graphql';

const useGetNotifications = () => {
  const { data: notificationsData, refetch, loading } = useQuery<notificationsToMe>(GET_NOTIFICATIONS, {
    variables: {
      pagination: {
        pageSize: 10000, // get first 10000 notifications
      },
    },
  });
  const notifications = notificationsData?.notificationsToMe.nodes || [];

  const unreadCount = notifications.filter((notification) => !notification.read).length;

  // Periodically Polling notifications
  useEffect(() => {
    const pollingInterval = 360 * 1000; // 30sec
    const polling = setInterval(() => refetch(), pollingInterval);
    return () => clearInterval(polling);
  }, [refetch]);
  return { unreadCount, notifications, loading };
};

export default useGetNotifications;
