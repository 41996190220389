import { useStores } from 'stores';
import { useObserver } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';

import { Role } from 'models/graphql';
import { CLIENT_ROLES } from 'constants/roleGroups';

export enum Mode {
  NONE = 'NONE',
  PM = 'PM',
  CLIENT = 'CLIENT',
  CONTRACTOR = 'CONTRACTOR',
  OPERATIVE = 'OPERATIVE',
  TECHNICIAN = 'TECHNICIAN',
}

type None = { mode: Mode.NONE; mobile: boolean };
type Pm = { mode: Mode.PM; mobile: boolean };
type Client = { mode: Mode.CLIENT; id: string; mobile: boolean };
type Contractor = { mode: Mode.CONTRACTOR; id: string; mobile: boolean };
type Operative = { mode: Mode.OPERATIVE; id: string; mobile: boolean };
type Technician = { mode: Mode.TECHNICIAN; id: string; mobile: boolean };
export type ModeResult = None | Pm | Client | Contractor | Operative | Technician;

export default function useMode(): ModeResult {
  const { authStore } = useStores();
  const role = useObserver(() => authStore.user?.role);
  const userId = useObserver(() => authStore.user?.id);
  const isPm = useRouteMatch('/pm');
  const isClient = useRouteMatch<{ clientId: string }>('/client/:clientId');
  const isContractor = useRouteMatch<{ contractorId: string }>('/contractor/:contractorId');
  const isOperative = useRouteMatch('/operative');
  const isTechnician = useRouteMatch('/technician');

  if (isPm) {
    if (role !== Role.PROJECT_MANAGER) {
      return { mode: Mode.NONE, mobile: true };
    }

    return { mode: Mode.PM, mobile: true };
  }
  if (isClient) {
    if (!role || ![Role.PROJECT_MANAGER, ...CLIENT_ROLES.filter((r) => r !== Role.CLIENT_OPERATIVE)].includes(role)) {
      return { mode: Mode.NONE, mobile: true };
    }

    return { mode: Mode.CLIENT, id: isClient.params.clientId, mobile: true };
  }
  if (isContractor) {
    if (!role || ![Role.PROJECT_MANAGER, Role.CONTRACTOR_OPERATIONS_MANAGER].includes(role)) {
      return { mode: Mode.NONE, mobile: true };
    }

    return { mode: Mode.CONTRACTOR, id: isContractor.params.contractorId, mobile: true };
  }
  if (isOperative) {
    if (!role || !userId || ![Role.CLIENT_OPERATIVE].includes(role)) {
      return { mode: Mode.NONE, mobile: true };
    }

    return { mode: Mode.OPERATIVE, id: userId, mobile: true };
  }
  if (isTechnician) {
    if (!role || !userId || ![Role.CONTRACTOR_TECHNICIAN].includes(role)) {
      return { mode: Mode.NONE, mobile: true };
    }

    return { mode: Mode.TECHNICIAN, id: userId, mobile: true };
  }

  return { mode: Mode.NONE, mobile: true };
}
