const enDrawings = {
  searchPlaceholder: 'Search by drawing id, asset name etc.',
  drawing: 'drawing',
  drawings: 'drawings',
  noDrawings: 'No Drawings',
  filter: {
    allTypes: 'All Asset Types',
    allSystemTypes: 'All System Types',
    allSystems: 'All System References',
  },
};
export default enDrawings;
