/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export default function objectPropertyByString<T = any>(
  object: Record<string, unknown>,
  propertyString: string,
): T | undefined {
  try {
    return propertyString
      .split('.')
      .reduce((objectLevel, index) => objectLevel[index] as Record<string, unknown>, object) as T;
  } catch {
    return undefined;
  }
}
