import React from 'react';
import assetsIcon from 'assets/assetsIcon.svg';
import assetsIconGrey from 'assets/assetsIconGrey.svg';
import contractorIcon from 'assets/contractorIcon.svg';
import contractorIconGrey from 'assets/contractorIcon-grey.svg';
import dashboardIconWhite from 'assets/dashboardIconWhite.svg';
import dashboardIconGrey from 'assets/dashboardIconGrey.svg';
import documentsIcon from 'assets/documentsIcon.svg';
import documentsIconGrey from 'assets/documentsIconGrey.svg';
import projectIconGrey from 'assets/projectIconGrey.svg';
import projectIcon from 'assets/projectIcon.svg';
import tasksIconWhite from 'assets/tasksIconWhite.svg';
import tasksIconGrey from 'assets/tasksIconGrey.svg';
import { useStores } from 'stores';
import { useObserver } from 'mobx-react';
import currentWeek from 'utils/currentWeek';
import DrawerLink from './DrawerLink';

interface Props {
  id: string;
  projects: boolean;
}

const ClientMenu: React.FC<Props> = ({ id, projects }) => {
  const { contextStore } = useStores();
  const siteId = useObserver(() => contextStore.siteId);
  const currentWeekString = currentWeek();
  return (
    <>
      <DrawerLink
        to={`/client/${id}/dashboard`}
        name="Dashboard"
        icon={dashboardIconGrey}
        activeIcon={dashboardIconWhite}
      />
      <DrawerLink
        to={`/client/${id}/tasks/${siteId}?${currentWeekString}`}
        name="Tasks"
        icon={tasksIconGrey}
        activeIcon={tasksIconWhite}
      />
      <DrawerLink to={`/client/${id}/assets`} name="Asset Register" icon={assetsIconGrey} activeIcon={assetsIcon} />
      {projects ? (
      <DrawerLink to={`/client/${id}/projects`} name="Projects" icon={projectIconGrey} activeIcon={projectIcon} />
         ) : null}
      <DrawerLink to={`/client/${id}/documents`} name="Documents" icon={documentsIconGrey} activeIcon={documentsIcon} />
      <DrawerLink
        to={`/client/${id}/contractors`}
        name="Contractors"
        icon={contractorIconGrey}
        activeIcon={contractorIcon}
      />
    </>
  );
};

export default ClientMenu;
