import React from 'react';
import Loadable from 'react-loadable';
import { Button, LinearProgress } from '@material-ui/core';

interface LoadingComponentProps {
  isLoading: boolean;
  pastDelay: boolean;
  timedOut: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  retry: () => void;
}

const LoadingComponent: React.FC<LoadingComponentProps> = (props) => {
  const { error, retry, timedOut, pastDelay } = props;
  if (error) {
    return (
      <div>
        Error! <Button onClick={retry}>Retry</Button>
      </div>
    );
  }
  if (timedOut) {
    return (
      <div>
        Taking a long time... <Button onClick={retry}>Retry</Button>
      </div>
    );
  }

  if (pastDelay) {
    return <LinearProgress />;
  }

  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export default (importFunc: () => Promise<any>) =>
  Loadable({
    loader: importFunc,
    loading: LoadingComponent,
  });
