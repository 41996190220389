import React from 'react';
import ErrorComponent from 'components/Error';

export default class DefaultErrorBoundary extends React.Component<
  Record<string, unknown>,
  { isError: boolean; message: string }
> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = {
      isError: false,
      message: '',
    };
  }

  public static getDerivedStateFromError(error: Error | null) {
    return { isError: true, message: error?.message };
  }

  // TODO: log errors to sentry
  // public componentDidCatch(error: Error | null, info: object) {
  //   if (!ENV_PRODUCTION) {
  //     return;
  //   }
  //   logComponentStackToSentryPlaceholder(error, info);
  // }

  public render() {
    const { isError, message } = this.state;
    const { children } = this.props;

    return isError ? <ErrorComponent message={message} /> : children;
  }
}
