import { boolean } from '@hapi/joi';
import { observable, action, IObservableArray } from 'mobx';
import FlashMessage from 'models/FlashMessage';
import { v4 as uuidv4 } from 'uuid';

interface Breadcrumb {
  id: string;
  to: string;
  name: string;
  options?: Array<{ id: string; to: string; name: string }>;
}

interface ModulesSelected {
  modules?: Array<{module:string}>
}

export class ContextStore {

  @observable
  public flashMessages: FlashMessage[] = [];

  @observable
  public breadcrumbs: IObservableArray<Breadcrumb> = observable([]);


  @observable
  public modulessel: IObservableArray<ModulesSelected> = observable([]);

  @observable
  public selectedModules:   [] | undefined;

  @observable
  public isNavigationHidden = false;

  @observable
  public systemType: string | undefined;

  @observable
  public isPaddingBottomRemoved = false;

  @observable
  public isDrawerHidden = false;

  @observable
  public previousLocation: string | undefined;
  @observable
  public actualLocation: string | undefined;

  @observable
  public siteId: string | undefined;

  // To store the viewing of task report
  @observable
  public reportViewedValue=  false;

  // Task Filters for use after opening task report
  @observable
  public startdateFilterValue:  Date | undefined;
  @observable
  public enddateFilterValue:  Date | undefined;
  @observable
  public taskidFilterValue:   [] | undefined;
  @observable
  public tasktypeFilterValue:  [] | undefined;
  @observable
  public respforworksFilterValue:   [] | undefined;
  @observable
  public statusworkflowFilterValue:   [] | undefined;
  @observable
  public tasktitleFilterValue:  [] | undefined;
  @observable
  public reoccurFilterValue:  [] | undefined;
  @observable
  public deadlinedateFilterValue:  [] | undefined;
  @observable
  public sysrefFilterValue:  [] | undefined;
  @observable
  public compliantvalFilterValue:   [] | undefined;




  @observable
  public compFilterValue: string = 'noncompliant';

  @observable
  public pieFilterValue: string = 'all';

  @observable
  public barFilterValue: string | undefined;

  @observable
  public barncFilterValue: string | undefined;

  @observable
  public taskvFilterValue: boolean | undefined;

  @action.bound
  public setPieFilter(pieFilterType: string) {
    this.pieFilterValue = pieFilterType;
  }

  @action.bound
  public setDashboardPicks(ncpick: string, piepick: string, barpick: string, barcomppick: string, taskview: boolean) {
  
      this.compFilterValue = ncpick;
      this.pieFilterValue = piepick;
      this.barFilterValue = barpick;
      this.barncFilterValue = barcomppick;
      this.taskvFilterValue = taskview;
    
  }

  @action.bound
   public setReportViewedValue(isViewed: boolean) {
     this.reportViewedValue = isViewed;
   }

  @action.bound
  //public setTaskFilters(startdate: string, enddate: string, taskid: string, tasktype: string, respforworks: string,statusworkflow: string,tasktitle: string,reoccur: string,deadlinedate: string,sysref: string,compliantval: string,) {
  //  public setTaskFilters(startdate: Date, enddate: Date, tasktype: string) {
  public setTaskFilters(startdate: any, enddate: any, tasktype: any, respforworks: any,sysref: any,statusworkflow: any,compliantval: any, tasktitle:any, reoccur:any) {
      // Set values for on return from task report
      this.startdateFilterValue = startdate;
      this.enddateFilterValue = enddate;
    //  this.taskidFilterValue = taskid;
      this.tasktypeFilterValue = tasktype;
      this.respforworksFilterValue = respforworks;
      this.statusworkflowFilterValue = statusworkflow;
      this.tasktitleFilterValue = tasktitle;
      this.reoccurFilterValue = reoccur;
    //  this.deadlinedateFilterValue = deadlinedate;
      this.sysrefFilterValue = sysref;
      this.compliantvalFilterValue = compliantval;
  }

  @action.bound
  //public setTaskFilters(startdate: string, enddate: string, taskid: string, tasktype: string, respforworks: string,statusworkflow: string,tasktitle: string,reoccur: string,deadlinedate: string,sysref: string,compliantval: string,) {
  //  public setTaskFilters(startdate: Date, enddate: Date, tasktype: string) {
  public setTaskStartDate(startdate: any) {
    this.startdateFilterValue = startdate;
  }
  @action.bound
  //public setTaskFilters(startdate: string, enddate: string, taskid: string, tasktype: string, respforworks: string,statusworkflow: string,tasktitle: string,reoccur: string,deadlinedate: string,sysref: string,compliantval: string,) {
  //  public setTaskFilters(startdate: Date, enddate: Date, tasktype: string) {
  public setTaskEndDate(enddate: any) {
    this.enddateFilterValue = enddate;
  }

  @action.bound
  public newLocation(location: string) {
    this.previousLocation = this.actualLocation;
    this.actualLocation = location;
  }

  @action.bound
  public showMessage(message: FlashMessage): void {
    this.flashMessages.push(message);
  }

  @action.bound
  public removeMessage(id: string): void {
    const index = this.flashMessages.findIndex((element) => element.id === id);
    this.flashMessages.splice(index);
  }

  @action.bound
  public setNavigationHidden(isHidden: boolean) {
    this.isNavigationHidden = isHidden;
  }

  @action.bound
  public setPaddingBottomRemoved(isHidden: boolean) {
    this.isPaddingBottomRemoved = isHidden;
  }

  @action.bound
  public setDrawerHidden(isHidden: boolean) {
    this.isDrawerHidden = isHidden;
  }

  @action.bound
  public addBreadcrumb(to: string, name: string, options?: Array<{ to: string; name: string }>) {
    const breadcrumb = {
      id: uuidv4(),
      to,
      name,
      options: options?.map((option) => ({ ...option, id: uuidv4() })),
    };
    this.breadcrumbs.push(breadcrumb);
    return () => {
      this.breadcrumbs.replace(this.breadcrumbs.filter((c) => c.id !== breadcrumb.id));
    };
  }


 



  @action.bound
  public setSiteId(siteId: string) {
    this.siteId = siteId;
  }
  @action.bound
  public setSelectedModules(selmods: []) {
    this.selectedModules = selmods;
    window.localStorage.setItem('selectedModules', JSON.stringify(selmods));
  }


  @action.bound
  public setSystemType(systemTypeId: string | undefined) {
    this.systemType = systemTypeId;
  }
}

export default ContextStore;