import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { useStores } from 'stores';
import { useObserver } from 'mobx-react';

const PrivateRoute = ({
  component: Comp,
  path,
  exact,
  ...rest
}: {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  path: string;
  exact?: boolean;
}) => {
  const { authStore } = useStores();
  const isAuthenticated = useObserver(() => authStore.isLoggedIn);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const routeComponent = (props: any) =>
    isAuthenticated ? <Comp {...props} /> : <Redirect to={{ pathname: '/auth/login' }} />;
  return <Route path={path} exact={exact} {...rest} render={routeComponent} />;
};

export default PrivateRoute;
