import { i18n as I18nInstance, TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';
import { I18n } from 'texts/i18n';
import { NonEmptyArray } from 'utils/NonEmptyArray.type';

/**
 * It gets a strictly typed t function and i18n instance.
 * @see t function - https://www.i18next.com/translation-function/essentials
 * @see i18n instance - https://react.i18next.com/latest/i18next-instance
 *
 * @param namespaces - load these namespaces
 * @returns t - is almost the same as the i18next t function,
 *  but nested into a namespace getter. t is only for first level property access.
 *    Instead of t('common:save', options)
 *     use t('common')('save', options),
 *
 *  tt - same as t, but for nested object property access.
 *   Instead of t('common:button.cancel', options)
 *    use tn('common')('button')('cancel', options)
 *
 *  i18n - intact i18n instance from useTranslation hook
 *
 */
export default function useText<Namespaces extends NonEmptyArray<keyof I18n>>(
  ...namespaces: Namespaces
): {
  t: <Namespace extends Namespaces[number]>(
    namespace: Namespace,
  ) => <Key extends keyof I18n[Namespace]>(key: Key, options?: TOptions) => string;
  tt: <Namespace extends Namespaces[number]>(
    namespace: Namespace,
  ) => <Domain extends keyof I18n[Namespace]>(
    domain: Domain,
  ) => <Key extends keyof I18n[Namespace][Domain]>(key: Key, options?: TOptions) => string;
  i18n: I18nInstance;
} {
  const { t, i18n } = useTranslation(namespaces);
  return {
    t: (namespace) => (key, options) => t(`${namespace}:${key}`, options),
    tt: (namespace) => (domain) => (key, options) => t(`${namespace}:${domain}.${key}`, options),
    i18n,
  };
}
