import gql from 'graphql-tag';

export const GET_NOTIFICATIONS = gql`
  query notificationsToMe($pagination: PaginationInput) {
    notificationsToMe(pagination: $pagination) {
      totalNodes
      totalPages
      page
      nodes {
        id
        read
        createdAt
        deletedAt
        type
        payload {
          entityId
          siteId
          name
          company
          date
        }
      }
    }
  }
`;

export const SET_NOTIFICATION_TO_READ = gql`
  mutation notificationSetToRead($id: ID!) {
    notificationSetToRead(id: $id) {
      id
      read
      deletedAt
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation notificationDelete($id: ID!) {
    notificationDelete(id: $id) {
      id
      read
      deletedAt
    }
  }
`;
