import { Role } from 'models/graphql';

const enRoles: Record<Role, string> = {
  [Role.CLIENT_ENVIRONMENTAL_OFFICER]: 'Environmental Officer',
  [Role.CLIENT_TEAM_MEMBER]: 'Team member',
  [Role.CLIENT_OPERATIVE]: 'Operative',
  [Role.CONTRACTOR_OPERATIONS_MANAGER]: 'Operations Manager',
  [Role.CONTRACTOR_TECHNICIAN]: 'Technician',
  [Role.PROJECT_MANAGER]: 'Project Manager',
};
export default enRoles;
