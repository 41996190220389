import { TaskStatus } from 'models/graphql';

const enTaskStatuses: Record<TaskStatus, string> = {
  UNASSIGNED: 'Unassigned',
  SENT: 'Sent',
  CONTRACTOR_ACCEPTED: 'WI Accepted',
  CONTRACTOR_REJECTED: 'WI Rejected',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  SUPP_INFO_SUBMITTED: 'Supp Info Submitted',
  REVIEW_REQ_SENT: 'Review Req Sent',
  REVIEW_REQ_ACCEPTED: 'Review Req Accepted',
  REVIEW_REQ_REJECTED: 'Review Req Rejected',
  CONSULTANT_REJECTED: 'Consultant Rejected',
  CONSULTANT_APPROVED: 'Consultant Approved',
  REJECTED: 'Rejected',
  REJECTED_CLOSED: 'Rejected & Closed',
  APPROVED_CLOSED: 'Approved',
  CANCELLED: 'Cancelled',
};
export default enTaskStatuses;
