import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react';
import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import { useStores } from 'stores';
import { useHistory } from 'react-router';
import useMode from 'utils/useMode.hook';
import styleUtils from 'style/styleUtils';
import cn from 'utils/cn';
import { DESKTOP_BREAKPOINT as BREAKPOINT } from 'constants/breakpoints';
import DrawerComponent from './components/Drawer/DrawerComponent';
import FlashMessageComponent from './components/FlashMessage';
import HeaderComponent from './components/Header';

const styles = (theme: Theme) =>
  createStyles({
    header: {
      height: theme.sizes.headerHeight,
      [theme.breakpoints.down(BREAKPOINT)]: {
        height: theme.sizes.mobileHeaderHeight,
      },
    },
    content: {
      [theme.breakpoints.up(BREAKPOINT)]: {
        marginLeft: theme.sizes.drawerWidth,
      },
      padding: theme.sizes.defaultContainerPadding.desktop,
      [theme.breakpoints.down(BREAKPOINT)]: {
        marginBottom: theme.sizes.mobileHeaderHeight,
        padding: theme.spacing(1, 1, 0),
      },
    },
    contentWithoutDrawer: {
      [theme.breakpoints.down(BREAKPOINT)]: {
        marginBottom: 0,
      },
    },
    noMobile: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 9999,
      backgroundColor: 'white',
      [theme.breakpoints.up(BREAKPOINT)]: {
        display: 'none',
      },
    },
    hidden: {
      [theme.breakpoints.down(BREAKPOINT)]: {
        display: 'none',
      },
    },
    center: {
      ...styleUtils.absoluteCenter,
    },
  });
const useStyles = makeStyles(styles);

export default function DefaultContainer({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  const state = useMode();
  const isMobileEnabled = !!state.mobile;
  const { contextStore } = useStores();
  const isNavigationHidden = useObserver(() => contextStore.isNavigationHidden);
  const isDrawerHidden = useObserver(() => contextStore.isDrawerHidden);
  const isPaddingBottomRemoved = useObserver(() => contextStore.isPaddingBottomRemoved);

  const history = useHistory();
  useEffect(() => {
    contextStore.newLocation(window.location.href);
    return history.listen(() => contextStore.newLocation(window.location.href));
  }, [contextStore, history]);

  return (
    <DefaultErrorBoundary>
      {!isNavigationHidden && (
        <>
          <div className={classes.header}>
            <HeaderComponent />
          </div>
          {!isDrawerHidden && <DrawerComponent />}
        </>
      )}
      <div
        className={cn(
          isNavigationHidden ? undefined : classes.content,
          isMobileEnabled ? undefined : classes.hidden,
          isDrawerHidden ? classes.contentWithoutDrawer : undefined,
        )}
        style={isPaddingBottomRemoved ? { paddingBottom: 0 } : undefined}
      >
        {children}
      </div>
      <div className={cn(classes.noMobile, isMobileEnabled ? classes.hidden : undefined)}>
        <div className={classes.center}>
          <Typography variant="h2">Only desktop view is supported.</Typography>
        </div>
      </div>
      <FlashMessageComponent />
    </DefaultErrorBoundary>
  );
}
