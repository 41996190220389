import { AssetType } from 'models/graphql';

const enAssetTypes: Record<AssetType, string> = {
  [AssetType.AIR_MONITOR]: 'Air Monitor/Location',
  [AssetType.BUNDS]: 'Bunds',
  [AssetType.GULLEY]: 'Gulley',
  [AssetType.INTERCEPTOR]: 'Interceptor',
  [AssetType.NOISE_MONITOR]: 'Noise Monitor / Location',
  [AssetType.ODOUR_MONITOR]: 'Odour Monitor / Location',
  [AssetType.DRAIN]: 'Drain / Pipe',
  [AssetType.MANHOLE]: 'Manhole',
  [AssetType.SAMPLING_POINT]: 'Sampling Point',
  [AssetType.SURFACE_WATER]: 'Surface water',
  [AssetType.GREASE_TRAP]: 'Grease Trap',
  [AssetType.PUMP]: 'Pump',
  [AssetType.GROUND_WATER]: 'Ground Water',
  [AssetType.BIOFILTER]: 'Biofilter',
  [AssetType.MISCELLANEOUS]: 'Miscellaneous',
  [AssetType.GENERAL_FACILITY]: 'General Facility',
  [AssetType.EQUIPMENT_VEHICLES]: 'Equipment / Vehicles',
  [AssetType.PEST_CONTROL_POINT]: 'Pest Control Point',
  [AssetType.TANKS]: 'Tanks',
  [AssetType.PITS]: 'Pits',
  [AssetType.FIRE_EQUIPMENT]: 'Fire Equipment',
};
export default enAssetTypes;