import { ContractorRequestAccessStatus, Expertise } from 'models/graphql';

const enContractors = {
  contractor: 'Contractor',
  addContractor: 'Add Contractor',
  editContractor: 'Edit Contractor',
  companyName: 'Company Name',
  address: 'Address',
  contactName: 'Contact Name',
  contactEmail: 'Contact Email',
  contactPhone: 'Contact Number',
  expertise: 'Expertise',
  expertises: {
    [Expertise.AIR]: 'Air',
    [Expertise.ODOUR]: 'Odour',
    [Expertise.NOISE]: 'Noise',
    [Expertise.DRAIN_PIPE_CCTV]: 'Drain/Pipe CCTV',
    [Expertise.DRAIN_PIPE_REPAIR]: 'Drain/Pipe Repair',
    [Expertise.MANHOLE_SURVEYING]: 'Manhole Surveying',
    [Expertise.MANHOLE_REPAIR]: 'Manhole Repair',
    [Expertise.BUNDS]: 'Bunds',
    [Expertise.WATER_SAMPLING]: 'Water Sampling',
    [Expertise.PUMP_SURVEY]: 'Pump Survey',
    [Expertise.PUMP_REPAIR]: 'Pump Repair',
    [Expertise.BOREHOLES]: 'Boreholes',
    [Expertise.INTERCEPTOR_SURVEY]: 'Interceptor Survey',
    [Expertise.INTERCEPTOR_REPAIR]: 'Interceptor Repair',
    [Expertise.GULLEY_SURVEY]: 'Gulley Survey',
    [Expertise.GULLEY_REPAIR]: 'Gulley Repair',
    [Expertise.INTEGRITY_TESTING]: 'Integrity Testing',
    [Expertise.WATER_TESTING]: 'Water Testing',
  },
  accessStatuses: {
    [ContractorRequestAccessStatus.ACCEPTED]: 'Accepted',
    [ContractorRequestAccessStatus.DENIED]: 'Denied',
    [ContractorRequestAccessStatus.REQUESTED]: 'Requested',
  },
  acceptContractor: 'Add as Contractor',
  acceptContractorError: 'Can not add Contractor',
  contractNumber: 'Contract Number',
  expiracy: 'Expiracy Date',
  worksOrderNumber: 'Works Order Number',
  addWorksOrder: 'Add Another Works Order',
  supplierManager: 'Supplier Manager',
  noContractorUser: '{{contractor}} needs to have at least 1 user.',
  twoFactorAuth: 'Use 2 factor authentication',
  editContractorAccess: 'Edit Contractor Access Rights',
  disableContractor: 'Disable Access',
  basicDetails: 'Basic Details',
  accessRights: 'Access Rights',
  users: 'Users',
};
export default enContractors;
