import React from 'react';
import { createStyles, Theme, makeStyles, ListItem, useTheme } from '@material-ui/core';
import styleUtils from 'style/styleUtils';
import cn from 'utils/cn';
import removeSimpleIcon from 'assets/removeSimpleIcon.svg';
import { Link } from 'react-router-dom';
import calendarIcon from 'assets/calendarIcon.svg';

const iconSize = 40;
const actionButtonSize = 23;
const actionDeleteIconSize = 9;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      padding: theme.spacing(2),
      borderBottom: `solid 1px ${theme.palette.grey[300]}`,
    },
    item: {
      width: '100%',
      display: 'flex',
    },
    main: {
      flexGrow: 1,
    },
    mainContainer: {
      display: 'flex',
    },
    icon: {
      width: iconSize,
      height: iconSize,
      marginRight: theme.spacing(1),
      ...styleUtils.rowCenterCenter,
      background: theme.palette.grey[100],
      borderRadius: '50%',
    },
    subTitle: {
      color: theme.palette.grey[500],
      fontSize: theme.fontSizes[11],
      textTransform: 'uppercase',
    },
    title: {
      fontSize: theme.fontSizes[12],
      fontWeight: theme.typography.fontWeightBold,
    },
    additionalInfo: {
      marginTop: theme.spacing(1),
      fontSize: theme.fontSizes[11],
    },
    date: {
      marginTop: theme.spacing(1),
      ...styleUtils.rowStartCenter,
      fontSize: theme.fontSizes[12],
      color: theme.palette.grey[500],
    },
    dateIcon: {
      marginRight: theme.spacing(1),
    },
    side: {
      marginLeft: theme.spacing(5),
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    actions: {
      ...styleUtils.rowStartCenter,
      marginBottom: theme.spacing(1),
    },
    action: {
      width: actionButtonSize,
      height: actionButtonSize,
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: '50%',
    },
    actionMarkAsRead: {
      position: 'relative',
      '&::after': {
        content: '""',
        ...styleUtils.absoluteCenter,
        width: (actionButtonSize - 1) / 2,
        height: (actionButtonSize - 1) / 2,
        background: theme.palette.error.main,
        borderRadius: '50%',
      },
    },
    actionDelete: {
      ...styleUtils.rowCenterCenter,
      marginLeft: theme.spacing(0.5),
    },
    actionDeleteIcon: {
      width: actionDeleteIconSize,
      height: actionDeleteIconSize,
    },
    timestamp: {
      fontSize: theme.fontSizes[11],
      color: theme.palette.grey[500],
    },
  }),
);

const Notification: React.FC<{
  title: string;
  iconSrc: string;
  timestamp: string;
  read: boolean;
  subTitle?: string;
  additionalInfo?: string;
  date?: Date;
  url?: string;
  onDelete: () => void;
  onMarkAsRead: () => void;
  onLinkOpen: () => void;
}> = ({ title, iconSrc, timestamp, read, subTitle, additionalInfo, date, url, onDelete, onMarkAsRead, onLinkOpen }) => {
  const styles = useStyles();
  const theme = useTheme();

  const main = (
    <div className={styles.mainContainer}>
      <div className={styles.icon}>
        <img alt="notification" src={iconSrc} />
      </div>
      <div>
        {subTitle ? <div className={styles.subTitle}>{subTitle}</div> : <></>}
        <div className={styles.title}>{title}</div>
        {additionalInfo ? <div className={styles.additionalInfo}>{additionalInfo}</div> : null}
        {date ? (
          <div className={styles.date}>
            <img alt="date" src={calendarIcon} className={styles.dateIcon} />
            {theme.dateFormat.normal(date)}
          </div>
        ) : null}
      </div>
    </div>
  );

  return (
    <ListItem className={styles.itemContainer}>
      <div className={styles.item}>
        <div className={styles.main}>
          {url ? (
            <Link to={url} onClick={onLinkOpen}>
              {main}
            </Link>
          ) : (
            main
          )}
        </div>
        <div className={styles.side}>
          <div className={styles.actions}>
            {!read ? (
              <button type="button" onClick={onMarkAsRead}>
                <div className={cn(styles.action, styles.actionMarkAsRead)} />
              </button>
            ) : null}
            <button type="button" onClick={onDelete}>
              <div className={cn(styles.action, styles.actionDelete)}>
                <img className={styles.actionDeleteIcon} alt="remove" src={removeSimpleIcon} />
              </div>
            </button>
          </div>
          <div className={styles.timestamp}>{timestamp}</div>
        </div>
      </div>
    </ListItem>
  );
};

export default Notification;
