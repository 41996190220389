import React from 'react';
import { makeStyles, Theme, Typography, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'grid',
    justifyItems: 'center',
    textAlign: 'center',
    minWidth: theme.spacing(30),
    marginTop: theme.spacing(6),
  },
  icon: {
    marginBottom: theme.spacing(2),
    justifySelf: 'center',
  },
  title: {
    fontSize: theme.fontSizes[14],
    fontWeight: 'bold',
    color: theme.palette.grey[600],
  },
  subtitle: {
    fontSize: theme.fontSizes[12],
    color: theme.palette.grey[400],
  },
}));

interface Props {
  hasData?: boolean;
  loading?: boolean;
  noData?: {
    icon?: string;
    title?: string;
    subtitle?: string;
  };
}

const ContentStateWrapper: React.FC<Props> = ({ loading, noData, hasData, children }) => {
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.wrapper}>
        <CircularProgress />
      </div>
    );
  }

  if (hasData === false) {
    const title = noData?.title || 'No Data';
    return (
      <div className={classes.wrapper}>
        {noData?.icon && <img alt={title} src={noData.icon} className={classes.icon} />}
        <Typography className={classes.title}>{title}</Typography>
        {noData?.subtitle && <Typography className={classes.subtitle}>{noData.subtitle}</Typography>}
      </div>
    );
  }

  return <>{children}</>;
};

export default ContentStateWrapper;
