import React, { useEffect, useState } from 'react';
import { AuthStore, withStores } from 'stores';
import { whoami } from 'containers/Auth/api';
import { useApolloClient } from '@apollo/client';
import { user, userVariables } from 'models/graphql';
import { GET_USER } from 'containers/shared/graphql';
import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import styleUtils from 'style/styleUtils';

import { GET_CLIENT } from 'containers/shared/ClientConfiguration/graphql';
import { getClient, getClientVariables } from 'models/graphql';

const styleRules = (theme: Theme) =>
  createStyles({
    container: {
      ...styleUtils.absoluteFill,
      ...styleUtils.columnCenterCenter,
      size: theme.fontSizes[12],
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
    loader: {},
  });
const useStyles = makeStyles(styleRules);

const AutoLogIn: React.FC<{
  authStore: AuthStore;
}> = ({ children, authStore }) => {
  const [signingIn, setSigningIn] = useState(true);
  const client = useApolloClient();
  const styles = useStyles();

  useEffect(() => {
    async function checkLoginStatus() {
      try {
        const { id } = await whoami();
        const { data } = await client.query<user, userVariables>({
          query: GET_USER,
          variables: { id },
        });
        if (!data) {
          throw Error('Unable to load user data');
        }
        let clientId = data.user.company?.id;
        let clientDetails = {'projects':false,'modules': ''}
        if (data.user.role !== 'PROJECT_MANAGER')
        {
          let clientdata = await client.query<getClient, getClientVariables>({
            query: GET_CLIENT,
            variables: { id: clientId },
          }); 
          if (!clientdata) {
            throw Error('Unable to get user client data');
          }
          clientDetails = {'projects':clientdata.data.client.projects,'modules': clientdata.data.client.modules}
          authStore.login(data.user, clientDetails);
        }
        else
        {
          authStore.login(data.user);
        }

      } catch (e) {
        // User do not have an authenticated session
      } finally {
        setSigningIn(false);
      }
    }
    checkLoginStatus().then();
  }, [authStore, client]);

  return (
    <>
      {signingIn ? (
        <div className={styles.container}>
          <CircularProgress className={styles.loader} />
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default withStores('authStore')(AutoLogIn);
