import gql from 'graphql-tag';

export const UPDATE_USER_STATUS = gql`
  mutation userUpdateStatus($id: ID!, $isActive: Boolean!) {
    userUpdateStatus(id: $id, isActive: $isActive) {
      id
      active
    }
  }
`;

export const GET_CLIENT_REQUESTS = gql`
  query getClientRequests($clientId: ID!) {
    client(id: $clientId) {
      id
      requests {
        id
        contractor {
          id
        }
        accessStatus
      }
    }
  }
`;

export const REQUEST_CONTRACTOR = gql`
  mutation contractorRequest($contractorId: ID!, $clientId: ID!) {
    contractorRequest(contractorId: $contractorId, clientId: $clientId) {
      id
      contractor {
        id
        requests {
          id
          client {
            id
          }
        }
      }
      client {
        id
      }
    }
  }
`;

export const REMOVE_REQUEST = gql`
  mutation removeRequest($contractorId: ID!, $clientId: ID!) {
    removeRequest(contractorId: $contractorId, clientId: $clientId) {
      contractor {
        id
        requests {
          id
          client {
            id
          }
        }
      }
      client {
        id
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation userCreate($companyId: ID!, $user: UserCreate!) {
    userCreate(companyId: $companyId, user: $user) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation userUpdate($id: ID!, $user: UserUpdate!) {
    userUpdate(id: $id, user: $user) {
      id
      name
      email
      phonenumber
      role
      isAdmin
      fullAccess
      adhoc
      sites {
        id
        name
      }
      picture {
        id
        srcUrl
      }
    }
  }
`;

export const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      name
      email
      phonenumber
      role
      isAdmin
      isconsultant
      fullAccess
      adhoc
      notificationSchedule
      notificationStatus
      sites {
        id
      }
      picture {
        id
        srcUrl
      }
      company {
        id
        name
      }
    }
  }
`;

export const REQUEST_USER = gql`
  mutation userRequest($userId: ID!, $clientId: ID!) {
    userRequest(userId: $userId, clientId: $clientId) {
      id
      clients {
        id
      }
    }
  }
`;

export const ACCEPT_USER_REQUEST = gql`
  mutation userAcceptRequest($userId: ID!, $clientId: ID!) {
    userAcceptRequest(userId: $userId, clientId: $clientId) {
      id
      accessStatus
    }
  }
`;

export const DENY_USER_REQUEST = gql`
  mutation userDenyRequest($userId: ID!, $clientId: ID!) {
    userDenyRequest(userId: $userId, clientId: $clientId) {
      id
      accessStatus
    }
  }
`;

export const REMOVE_USER_REQUEST = gql`
  mutation userRemoveRequest($userId: ID!, $clientId: ID!) {
    userRemoveRequest(userId: $userId, clientId: $clientId) {
      id
      clients {
        id
      }
    }
  }
`;

export const GET_DRAWING = gql`
  query drawing($drawingId: ID!) {
    drawing(drawingId: $drawingId) {
      id
      drawingId
      site {
        id
        name
      }
      status
      file {
        id
        originalName
      }
      systemType
      rotation
      assets {
        id
        system {
          id
          type
        }
        name
        type
      }
    }
  }
`;

export const GET_CLIENT_ASSETS = gql`
  query clientAssets($id: ID!, $search: String) {
    client(id: $id) {
      id
      assets(search: $search) {
        id
        site {
          id
          name
        }
        upcomingWorks {
          id
          type
          deadline
        }
        recentWorks {
          id
          type
          deadline
        }
        department
        system {
          id
          name
          type
        }
        name
        type
        createdAt
        owner {
          id
          name
          picture {
            srcUrl
          }
        }
        status
        coordinates {
          longitude
          latitude
        }
        additionalInformation
        expireOption
        expiration
        drawings {
          id
          drawingId
          file {
            id
            originalName
            extension
          }
        }
      }
    }
  }
`;

export const GET_CONTRACTORS = gql`
  query getContractors($search: String, $archived: Boolean) {
    contractors(search: $search, archived: $archived) {
      id
      companyName
      contactName
      contactEmail
      address
      status
      expertise
      rating
      address
    }
  }
`;

export const GET_CONTRACTOR_FIELDS = gql`
  fragment getContractorFields on Contractor {
    id
    companyName
    contactName
    contactEmail
    contactPhone
    address
    status
    expertise
    rating
    logo {
      id
      srcUrl
    }
  }
`;

export const GET_CONTRACTOR = gql`
  query getContractor($id: ID!) {
    contractor(id: $id) {
      ...getContractorFields
    }
  }
  ${GET_CONTRACTOR_FIELDS}
`;

export const GET_CONTRACTOR_FOR_CLIENT = gql`
  query getContractorForClient($contractorId: ID!, $clientId: ID!) {
    contractor(id: $contractorId) {
      ...getContractorFields
      request(clientId: $clientId) {
        id
        accessStatus
        sites {
          id
          name
        }
        systemTypes
        contractNumber
        contractExpiracy
        workOrders {
          id
          expiracy
        }
        manager {
          id
          name
        }
        twoFactorAuth
        customMessage
      }
    }
  }
  ${GET_CONTRACTOR_FIELDS}
`;

export const GET_CLIENT_SITES = gql`
  query clientSites($id: ID!) {
    client(id: $id) {
      id
      sites {
        id
        name
      }
    }
  }
`;

export const GET_USER_SITES = gql`
  query userSites($id: ID!) {
    user(id: $id) {
      id
      sites {
        id
        name
      }
    }
  }
`;

export const TASK_COMMENT_FIELDS = gql`
  fragment taskCommentFields on TaskComment {
    id
    message
    createdAt
    archived
    author {
      id
      name
      companyName
      picture {
        id
        srcUrl
      }
    }
  }
`;
export const GET_CLIENT_TASK_COMMENTS = gql`
  query taskClientComments($taskId: ID!) {
    task(id: $taskId) {
      id
      status
      commentsWithArchived {
        ...taskCommentFields
      }
    }
  }
  ${TASK_COMMENT_FIELDS}
`;
export const GET_CONTRACTOR_TASK_COMMENTS = gql`
  query taskContractorComments($taskId: ID!) {
    task(id: $taskId) {
      id
      status
      comments {
        ...taskCommentFields
      }
    }
  }
  ${TASK_COMMENT_FIELDS}
`;

export const ADD_COMMENT_CLIENT = gql`
  mutation taskClientComment($taskId: ID!, $message: String!) {
    taskComment(taskId: $taskId, message: $message) {
      id
      commentsWithArchived {
        ...taskCommentFields
      }
    }
  }
  ${TASK_COMMENT_FIELDS}
`;

export const ADD_COMMENT_CONTRACTOR = gql`
  mutation taskContractorComment($taskId: ID!, $message: String!) {
    taskComment(taskId: $taskId, message: $message) {
      id
      comments {
        ...taskCommentFields
      }
    }
  }
  ${TASK_COMMENT_FIELDS}
`;

export const LOGS = gql`
  query logs($dataId: String, $gqlOperation: String) {
    logs(dataId: $dataId, gqlOperation: $gqlOperation) {
      id
      entityType
      logType
      dataId
      gqlOperation
      user {
        id
        name
        companyName
        picture {
          id
          srcUrl
        }
      }
      createdAt
    }
  }
`;

export const UPDATE_CONTRACTOR = gql`
  mutation updateContractor($id: ID!, $changes: ContractorDetails!) {
    contractorUpdate(id: $id, changes: $changes) {
      id
      companyName
      contactName
      contactEmail
      contactPhone
      address
      logo {
        id
        srcUrl
      }
      expertise
    }
  }
`;

export const CREATE_CONTRACTOR = gql`
  mutation createContractor($contractor: ContractorDetails!) {
    contractorCreate(contractor: $contractor) {
      id
    }
  }
`;

export const GET_TASK_ASSET_DRAWINGS = gql`
  query getTaskAssetDrawings($id: ID!) {
    taskAsset(id: $id) {
      id
      asset {
        id
        drawings {
          id
          file {
            id
            originalName
            extension
          }
        }
      }
      task {
        id
        adhoc
        assets {
          id
          task {
            id
            status
            type
          }
          asset {
            id
            name
            type
          }
        }
      }
    }
  }
`;

export const ROTATE_DRAWING = gql`
  mutation rotateDrawing($id: ID!, $rotation: Int!) {
    drawingRotate(id: $id, rotation: $rotation) {
      id
    }
  }
`;

export const GET_TASK_REPORT = gql`
  query taskReport($id: ID!) {
    task(id: $id) {
      id
      status
      idReadable
      tasktitle
      deadline
      type
      urgent
      attachments {
        id
        originalName
        srcUrl
      }
      contractorResponsibleUser {
        id
        name
      }
      internalResponsible {
        id
        name
      }
      assets {
        id
        asset {
          id
          name
          type
          createdAt
          department
          additionalInformation
          expireOption
          expiration
          coordinates {
            longitude
            latitude
          }
          system {
            id
            type
            name
          }
          owner {
            id
            name
          }
          site {
            id
            name
          }
        }
        formValues
        formTemplate {
          id
          content
          fileMultiTemplate {
            id
            originalName
          }
          fileSingleTemplate {
            id
            originalName
          }
          allowSingleReportPdf
          allowMultiReportPdf
        }
      }
    }
  }
`;

export const GET_CONTRACTOR_USERS = gql`
  query contractorUsers($id: ID!, $search: String, $archived: Boolean) {
    contractor(id: $id) {
      id
      companyName
      users(search: $search, archived: $archived) {
        id
        name
        email
        phonenumber
        role
        active
        picture {
          id
          srcUrl
        }
        clients {
          id
        }
      }
      requests {
        id
        accessStatus
        client {
          id
          companyName
        }
        userRequests {
          id
          accessStatus
          user {
            id
            name
            email
            phonenumber
            role
            active
            picture {
              id
              srcUrl
            }
          }
        }
      }
    }
  }
`;

export const GET_CONTRACTOR_REQUESTED_USERS = gql`
  query contractorRequestedUsers($contractorId: ID!, $clientId: ID!) {
    contractor(id: $contractorId) {
      id
      request(clientId: $clientId) {
        id
        accessStatus
        userRequests {
          id
          accessStatus
          user {
            id
            name
            email
            phonenumber
            role
            active
            picture {
              id
              srcUrl
            }
          }
        }
      }
    }
  }
`;

export const ACCEPT_TASK = gql`
  mutation taskResponsibleAccept($taskId: ID!, $comment: String) {
    taskResponsibleAccept(taskId: $taskId, comment: $comment) {
      id
      status
      comments {
        ...taskCommentFields
      }
    }
  }
  ${TASK_COMMENT_FIELDS}
`;

export const REJECT_TASK = gql`
  mutation taskResponsibleReject($taskId: ID!, $comment: String!) {
    taskResponsibleReject(taskId: $taskId, comment: $comment) {
      id
      status
      comments {
        ...taskCommentFields
      }
    }
  }
  ${TASK_COMMENT_FIELDS}
`;