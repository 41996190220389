import React from 'react';
import AnimateStyle, { AnimateStyleProps } from 'components/animations/AnimateStyle';

const AnimateHeight: React.FC<AnimateStyleProps> = ({ ...props }) => {
  return (
    <AnimateStyle
      states={{ initial: { height: 0 }, visible: { height: 'auto' } }}
      style={{ overflow: 'hidden' }}
      {...props}
    />
  );
};

export default AnimateHeight;
