import React from 'react';
import {
  Button,
  Card,
  CardContent,
  Typography,
  CardActions,
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
      marginTop: theme.spacing(4),
      maxWidth: '600px',
    },
  });

const reload = () => {
  window.location.reload();
};

const ErrorComponent: React.FC<{ message?: string } & WithStyles<typeof styles>> = ({ message, classes }) => {
  const history = useHistory();

  return (
    <Card className={classes.root}>
      {message ? (
        <>
          <CardContent>
            <Typography variant="h3" component="h3" gutterBottom>
              {message}
            </Typography>
            <Typography variant="body1">Please ask for administrator.</Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={async () => {
                await history.push('/');
                await window.location.reload();
              }}
            >
              Back to home
            </Button>
          </CardActions>
        </>
      ) : (
        <>
          <CardContent>
            <Typography variant="h3" component="h1" gutterBottom>
              Something went wrong.
            </Typography>
            <Typography variant="body1">
              We apologize for the inconvenience. Problems are reported automatically and we will try to resolve it as
              soon as possible.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={reload}>
              Reload the page
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default withStyles(styles)(ErrorComponent);
