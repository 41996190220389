import { useQuery } from '@apollo/client';
import { user as userQuery, userVariables } from 'models/graphql';
import { GET_USER } from 'containers/shared/graphql';

export default function useGetUser(userId?: string) {
  const { data: userData } = useQuery<userQuery, userVariables>(GET_USER, {
    skip: !userId,
    variables: { id: userId! },
  });

  return userData?.user;
}
