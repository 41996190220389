import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Box, Drawer, makeStyles, Theme } from '@material-ui/core';

import styleUtils from 'style/styleUtils';
import edacLogo from 'assets/edacLogo.svg';
import useMode, { ModeResult, Mode } from 'utils/useMode.hook';
import { GET_CLIENT } from 'containers/shared/ClientConfiguration/graphql';
import { getClient, getClientVariables } from 'models/graphql';
import { DESKTOP_BREAKPOINT as BREAKPOINT } from 'constants/breakpoints';
import PmMenu from './PmMenu';
import ClientMenu from './ClientMenu';
import TechnicianMenu from './TechnicianMenu';
import ContractorMenu from './ContractorMenu';
import OperativeMenu from './OperativeMenu';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: theme.sizes.drawerWidth,
    boxShadow: theme.shadows[3],
    background: theme.brandColors.dark,
    border: 0,
    [theme.breakpoints.down(BREAKPOINT)]: {
      display: 'none',
    },
  },
  container: {
    display: 'flex',
    [theme.breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'row',
      background: 'white',
      justifyContent: 'space-evenly',
    },
    [theme.breakpoints.up(BREAKPOINT)]: {
      flexDirection: 'column',
    },
    alignItems: 'center',
    height: '100%',
  },
  logoContainer: {
    ...styleUtils.rowCenterCenter,
    height: theme.sizes.headerHeight,
    width: '100%',
    background: theme.palette.background.default,
  },
  maintainer: {
    ...styleUtils.columnStartCenter,
    marginTop: 'auto',
    color: theme.palette.primary.contrastText,
    fontSize: theme.fontSizes['12'],
  },
  maintainerLogo: {
    width: 67,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  navigation: {
    paddingTop: theme.spacing(1),
  },
  mobileNav: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    height: theme.sizes.mobileHeaderHeight,
    [theme.breakpoints.up(BREAKPOINT)]: {
      display: 'none',
    },
    borderTop: `solid 1px ${theme.palette.grey['300']}`,
    background: theme.palette.background.default,
    zIndex: 9999,
  },
}));

const renderContent = (state: ModeResult, hasprojects: boolean) => {
  switch (state.mode) {
    case Mode.PM:
      return <PmMenu />;
    case Mode.CLIENT:
      return <ClientMenu id={state.id} projects={hasprojects} />;
    case Mode.CONTRACTOR:
      return <ContractorMenu id={state.id} />;
    case Mode.OPERATIVE:
      return <OperativeMenu id={state.id} />;
    case Mode.TECHNICIAN:
      return <TechnicianMenu id={state.id} />;
    case Mode.NONE:
    default:
      return null;
  }
};

const DrawerComponent: React.FC = () => {
  const classes = useStyles();
  const state = useMode();
  const apolloClient = useApolloClient();
  const [companyLogo, setCompanyLogo] = useState(edacLogo);
  const [hasprojects, setHasprojects] = useState(false);

  useEffect(() => {
    console.log("state.id:", state.id)
    const setLogo = async () => {
      if (state.mode === Mode.CLIENT && state.id) {
        const { data } = await apolloClient.query<getClient, getClientVariables>({
          query: GET_CLIENT,
          variables: { id: state.id },
        });
        if (data && data.client.logo) {setCompanyLogo(data.client.logo.srcUrl);
        console.log("Projects Value:", data.client.projects) 
        setHasprojects(data.client.projects)
       
       }
      }
    };
    setLogo();
  }, [state, apolloClient]);

  return (
    <>
      <Drawer variant="permanent" anchor="left" classes={{ paper: classes.drawer }}>
        <div className={classes.container}>
          <div className={classes.logoContainer}>
            <img alt="company logo" src={companyLogo} />
          </div>

          {/* Navigation */}

          <div className={classes.navigation}>{renderContent(state, hasprojects)}</div>
          <div className={classes.maintainer}>
            Powered by
            <br />
            <img alt="edac logo" src={edacLogo} className={classes.maintainerLogo} />
          </div>
        </div>
      </Drawer>
      <Box className={classes.mobileNav}>
        <div className={classes.container}>{renderContent(state, hasprojects)}</div>
      </Box>
    </>
  );
};

export default DrawerComponent;
