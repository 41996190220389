import { get, post } from 'utils/Api';
import sha1 from 'sha1';

// DO NOT CHANGE AFTER APP IS IN PRODUCTION
// Function creates a new password that is the hash of plaintext password
// To the backend the hash is the password
// This makes it harder to detect the plaintext password
// It does not increase the security for authenticating to the backend
const hashPassword = (plaintext: string): string => {
  const salt = '6B5gDfKELf8yQ6hkBvy6PEA4vAxJDEsN';
  return sha1(salt + plaintext) as string;
};
// END OF DO NOT CHANGE AFTER APP IS IN PRODUCTION

export const login = (email: string, password: string) => {
  const postData = {
    email,
    password: hashPassword(password), // DO NOT CHANGE
  };
  return post<{ id: string; email: string }>('auth/login', postData);
};

export const whoami = () => {
  return get<{ id: string; email: string }>('auth/whoami');
};

export const changePassword = (token: string, newPassword: string) => {
  const postData = {
    token,
    password: hashPassword(newPassword), // DO NOT CHANGE
  };
  return post('auth/changePassword', postData);
};

export const resetPassword = (email: string) => {
  const postData = {
    email,
  };
  return post('auth/resetPassword', postData);
};

export const sendSmsCode = () => {
  return post('auth/sendSmsAuthCode', {});
};

export const verifySmsCode = (code: string) => {
  const postData = {
    code,
  };
  return post('auth/verifySMSAuthCode', postData);
};

export default { login };
