import React from 'react';
import { ContextStore, withStores } from 'stores';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export interface FlashMessageComponentProps {
  contextStore: ContextStore;
}

const FlashMessageComponent: React.FC<FlashMessageComponentProps> = ({ contextStore }) => (
  <>
    {contextStore.flashMessages.map((message) => (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        key={message.id}
        open={message.open}
        autoHideDuration={message.duration}
        onClose={message.close}
        onExited={() => contextStore.removeMessage(message.id)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={message.content}
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={message.close}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    ))}
  </>
);

export default withStores('contextStore')(FlashMessageComponent);
