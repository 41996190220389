import gql from 'graphql-tag';

export const GET_LICENSEPAGE_DATA = gql`
  query clientSitesAssetTypes($id: ID!) {
    client(id: $id) {
      id
      modules
      sites {
        id
        name
        assets(archived: false) {
          id
          name
          type
        }
        licenses {
          id
          file {
            id
            originalName
          }
        }
        formTemplates {
          id
          formType
          assetType
          content
          active
          subForm
          fileSingleTemplate {
            id
            originalName
          }
          fileMultiTemplate {
            id
            originalName
          }
          allowSingleReportPdf
          allowMultiReportPdf
          docsRequired
        }
      }
    }
  }
`;

export const CREATE_LICENSE = gql`
  mutation licenseCreate($siteId: String!, $fileId: String!) {
    licenseCreate(siteId: $siteId, fileId: $fileId) {
      id
      file {
        id
        originalName
      }
    }
  }
`;

export const ARCHIVE_LICENSE = gql`
  mutation licenseArchive($licenseId: String!) {
    licenseArchive(licenseId: $licenseId) {
      id
    }
  }
`;

export const GET_CLIENT_USERS = gql`
  query clientUsers($id: ID!, $search: String, $archived: Boolean) {
    client(id: $id) {
      id
      users(search: $search, archived: $archived) {
        id
        name
        email
        phonenumber
        role
        isAdmin
        active
        sites {
          name
        }
      }
    }
  }
`;

export const CREATE_OR_UPDATE_FORMTEMPLATE = gql`
  mutation formTemplateCreateOrUpdate(
    $siteId: String!
    $formType: TaskType!
    $assetType: AssetType!
    $subForm: String
    $module: String
    $content: String
    $fileSingleTemplate: String
    $fileMultiTemplate: String
    $allowSingleReportPdf: Boolean
    $allowMultiReportPdf: Boolean
    $docsRequired: Boolean
  
  ) {
    formTemplateCreateOrUpdate(
      siteId: $siteId
      formType: $formType
      assetType: $assetType
      subForm: $subForm
      module: $module
      content: $content
      fileSingleTemplate: $fileSingleTemplate
      fileMultiTemplate: $fileMultiTemplate
      allowSingleReportPdf: $allowSingleReportPdf
      allowMultiReportPdf: $allowMultiReportPdf
      docsRequired: $docsRequired
    ) {
      id
      formType
      assetType
      subForm
      content
      active
      siteId
      fileSingleTemplate {
        id
        originalName
      }
      fileMultiTemplate {
        id
        originalName
      }
      allowSingleReportPdf
      allowMultiReportPdf
      docsRequired
    }
  }
`;

export const ACTIVATE_FORMTEMPLATE = gql`
  mutation formTemplateActivate($siteId: String!, $formType: TaskType!, $assetType: AssetType!, $subForm: String!) {
    formTemplateActivate(siteId: $siteId, formType: $formType, assetType: $assetType, subForm: $subForm) {
      id
      content
      formType
      assetType
      subForm
      active
      siteId
    }
  }
`;

export const GET_CLIENT = gql`
  query getClientForConfiguration($id: ID!) {
    client(id: $id) {
      id
      companyName
      contactName
      contactEmail
      contactPhone
      twoFactorAuth
      status
      projects
      modules
      logo {
        id
        srcUrl
      }
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation subscriptionCreate($clientId: ID!, $subscription: SubscriptionCreate!) {
    subscriptionCreate(clientId: $clientId, subscription: $subscription) {
      id
    }
  }
`;

export const CREATE_DRAWING = gql`
  mutation drawingCreate($drawing: DrawingCreateInput!) {
    drawingCreate(drawing: $drawing) {
      id
      drawingId
      site {
        id
        name
      }
      assets {
        id
      }
      status
      file {
        id
        originalName
      }
      systemType
    }
  }
`;

export const GET_SUBSCRIPTION = gql`
  query getSubscription($id: ID!) {
    client(id: $id) {
      id
      subscription {
        id
        idReadable
        billingFrequency
        startingMonth
        sitesMaxNumber
        contractorsMaxNumber
        allowedAssets
        sites {
          id
          name
          address
        }
      }
    }
  }
`;

export const UPDATE_ALLOWED_ASSETS = gql`
  mutation subscriptionAllowedAssetsUpdate($id: ID!, $allowedAssets: [AssetType!]!) {
    subscriptionAllowedAssetsUpdate(id: $id, allowedAssets: $allowedAssets) {
      id
      allowedAssets
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation subscriptionUpdate($id: ID!, $subscription: SubscriptionUpdate!) {
    subscriptionUpdate(id: $id, subscription: $subscription) {
      id
      sites {
        id
        name
      }
      idReadable
      billingFrequency
      startingMonth
      sitesMaxNumber
      contractorsMaxNumber
    }
  }
`;

export const GET_CREATE_ASSET_DATA = gql`
  query getCreateAssetData($id: ID!) {
    client(id: $id) {
      id
      sites {
        id
        name
        drawings {
          id
          file {
            id
            originalName
          }
          drawingId
        }
      }
      allowedAssets
      users {
        id
        name
      }
      systems {
        id
        type
        name
        site {
          id
        }
      }
    }
  }
`;

export const GET_ASSETS = gql`
  query getAssets($ids: [ID!]!) {
    assets(ids: $ids) {
      id
      name
      site {
        id
      }
      department
      additionalInformation
      expireOption
      expiration
      coordinates {
        latitude
        longitude
      }
      system {
        id
        type
      }
      type
      owner {
        id
      }
      drawings {
        id
      }
      specificInfo {
        pumps {
          name
          pass
        }
      }
    }
  }
`;

export const CREATE_ASSET = gql`
  mutation assetCreate($asset: AssetCreate!) {
    assetCreate(asset: $asset) {
      id
      system {
        id
      }
      site {
        id
      }
    }
  }
`;
export const CREATE_ASSETS = gql`
  mutation assetsCreate($assets: [AssetCreate!]!) {
    assetsCreate(assets: $assets) {
      id
      system {
        id
      }
      site {
        id
      }
    }
  }
`;

export const UPDATE_ASSETS = gql`
  mutation assetsUpdate($assets: [AssetUpdate!]!) {
    assetsUpdate(assets: $assets) {
      id
      name
      department
      additionalInformation
      expireOption
      expiration
      coordinates {
        latitude
        longitude
      }
      system {
        id
        type
      }
      type
      site {
        id
        name
      }
      owner {
        id
        name
      }
      drawings {
        id
      }
      specificInfo {
        pumps {
          name
          pass
        }
      }
    }
  }
`;

export const GET_DRAWINGS = gql`
  query drawings($clientId: ID!, $search: String, $activeOnly: Boolean) {
    drawings(clientId: $clientId, search: $search, activeOnly: $activeOnly) {
      id
      drawingId
      site {
        id
        name
      }
      assets {
        id
      }
      status
      file {
        id
        originalName
      }
      systemType
    }
  }
`;

export const UPDATE_DRAWING = gql`
  mutation drawingUpdate($drawing: DrawingUpdateInput!) {
    drawingUpdate(drawing: $drawing) {
      id
      drawingId
      site {
        id
        name
      }
      assets {
        id
      }
      status
      file {
        id
        originalName
      }
      systemType
    }
  }
`;

export const DELETE_DRAWING = gql`
  mutation drawingDelete($id: ID!) {
    drawingDelete(id: $id) {
      id
      site {
        id
      }
    }
  }
`;

export const UPDATE_DRAWING_STATUS = gql`
  mutation drawingUpdateStatus($id: ID!, $isActive: Boolean!) {
    drawingUpdateStatus(id: $id, isActive: $isActive) {
      id
      status
      site {
        id
      }
    }
  }
`;

export const GET_REQUESTS = gql`
  query contractorRequests($clientId: ID!, $search: String) {
    contractorRequests(clientId: $clientId, search: $search) {
      id
      contractor {
        id
        companyName
        contactName
        contactEmail
        status
        expertise
        rating
        address
      }
      accessStatus
    }
  }
`;

export const GET_CONTRACTORS_FOR_CLIENT = gql`
  query getContractorsForClient($search: String, $archived: Boolean) {
    contractors(search: $search, archived: $archived) {
      id
      companyName
      contactName
      address
      status
      expertise
      rating
      requests {
        id
        client {
          companyName
          id
        }
      }
    }
  }
`;

export const UPDATE_ASSET_STATUS = gql`
  mutation assetStatusUpdate($id: ID!, $status: AssetStatus!) {
    assetStatusUpdate(id: $id, status: $status) {
      id
      status
      site {
        id
      }
    }
  }
`;

export const GET_SITES = gql`
  query getClientSites($clientId: ID!) {
    client(id: $clientId) {
      id
      sites {
        id
        name
      }
      allowedSystems
    }
  }
`;

export const CREATE_SYSTEM = gql`
  mutation systemCreate($siteId: ID!, $system: SystemCreateInput!) {
    systemCreate(siteId: $siteId, system: $system) {
      id
    }
  }
`;

export const GET_SYSTEMS = gql`
  query getSystems($clientId: ID!) {
    client(id: $clientId) {
      id
      sites {
        id
        name
      }
      allowedSystems
      systems {
        id
        name
        type
        numberOfAssets
        site {
          id
          name
        }
      }
    }
  }
`;

export const GET_SYSTEM = gql`
  query getSystem($id: ID!) {
    system(id: $id) {
      id
      name
      type
      site {
        id
        name
      }
    }
  }
`;

export const UPDATE_SYSTEM = gql`
  mutation systemUpdate($id: ID!, $system: SystemUpdateInput!) {
    systemUpdate(id: $id, system: $system) {
      id
      name
    }
  }
`;

export const GET_CONTRACTOR_REQUEST_STATUS = gql`
  query contractorRequestStatus($id: ID!, $clientId: ID!) {
    contractor(id: $id) {
      id
      request(clientId: $clientId) {
        id
        accessStatus
      }
    }
  }
`;
