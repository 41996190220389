import React from 'react';
import AnimateStyle, { AnimateStyleProps } from 'components/animations/AnimateStyle';

const AnimateWidth: React.FC<AnimateStyleProps> = ({ ...props }) => {
  return (
    <AnimateStyle
      states={{ initial: { width: 0 }, visible: { width: 'auto' } }}
      style={{ overflow: 'hidden' }}
      {...props}
    />
  );
};

export default AnimateWidth;
