import React from 'react';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'mobx-react';

import { createStores } from 'stores';
import DefaultErrorBoundary from 'utils/DefaultErrorBoundary';
import theme from 'style/theme';
import UpdateComponent from './UpdateComponent';
import AutoLogIn from './AutoLogIn';
import Apollo from './Apollo';

const stores = createStores();

const BootstrapComponent: React.FC = ({ children }) => (
  <DefaultErrorBoundary>
    <Provider {...stores}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <UpdateComponent>
          <Router>
            <Apollo>
              <AutoLogIn>{children}</AutoLogIn>
            </Apollo>
          </Router>
        </UpdateComponent>
      </MuiThemeProvider>
    </Provider>
  </DefaultErrorBoundary>
);

export default BootstrapComponent;
