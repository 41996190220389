import { ReOccurenceType, ReOccurenceWeekIndex } from 'models/graphql';

const types: Record<ReOccurenceType, string> = {
  NA: 'N/A',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
  NEVER: 'Do not repeat',
  DAILY: 'Daily',
};

const weekIndexes: Record<ReOccurenceWeekIndex, string> = {
  FIRST: 'First',
  SECOND: 'Second',
  THIRD: 'Third',
  FOURTH: 'Fourth',
  LAST: 'Last',
};

const enReOccurence = {
  ReOccurence: 'Re-occurence',
  endDate: 'End date',
  on: 'On the',
  onDay: 'On Day',
  onMonths: 'On Months',
  onDays: 'On Days',
  types,
  weekIndexes,
};
export default enReOccurence;