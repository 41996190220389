const enTasks = {
  tasks: 'Tasks',
  task: 'Task',
  tasksTitle: 'Task Management',
  workInstructions: 'Work Instructions',
  taskInstructions: 'Task Instructions',
  searchPlaceholder: 'Search by Task ID',
  createTask: 'New Task',
  phaseAssets: 'Select Assets',
  system_optionalPlural: 'System(s)',
  asset_optionalPlural: 'Asset(s)',
  phaseType: 'Create a New Task',
  phaseDetails: 'Create {{type}}',
  editTask: 'Edit Task',
  createCorrectiveTask: 'Corrective Task',
  createDuplicateTask: 'Duplicate Task',
  responsible: 'Responsible for Works',
  assignedTo: 'Assigned to',
  internalResponsible: 'Internal',
  contractorResponsible: 'Contractor',
  approval: 'Responsible for Approval',
  noUsersForApproval: 'No users for approval.',
  workOrderId: 'Work Order ID',
  noContractor: 'You have no accepted contractor.',
  noWorkOrder: 'There is no valid Work Order ID attached to this contractor.',
  noUsers: 'You have no users.',
  notes: 'Notes',
  tasktitle: 'Task Title',
  deadline: 'Works Deadline',
  documentDeadline: 'Document Deadline',
  documentDeadlineBeforeDeadlineError: 'Document Deadline date must be the same date or later than Works Deadline date',
  urgent: 'Urgent',
  noTasks: 'No Tasks',
  taskType: 'Task Type',
  taskId: 'Task ID',
  contractorResponsibleWorkOrderId: 'Work Order ID',
  workInstructionType: 'WI Type',
  workOrderId_short: 'WI No',
  deadline_short: 'Task D/L',
  workInstructionDeadline_short: 'Works D/L',
  taskInstructionDeadline_short: 'Tasks D/L',
  documentDeadline_short: 'Document D/L',
  responsible_short: 'Resp. for Works',
  status: 'Status of Workflow',
  details: 'Details',
  history: 'History',
  comments: 'Comments',
  priority: 'Priority',
  priorityNormal: 'Normal',
  priorityUrgent: 'Urgent',
  assignedAt: 'Assigned Time',
  worksTime: 'Works completed in',
  assets: 'Assets',
  numberOfAssets: 'No of Assets',
  attachments: 'Attachments',
  noAttachments: 'No attachments',
  moreInfo: 'More info',
  noAssets: 'No assets',
  addDocument: 'Add Document',
  addComment: 'Add a Comment',
  addCommentSubmit: 'Send',
  applyChangesToFutureOccurences: 'Use these assignments for all the upcoming tasks',
  client: 'Client',
  site: 'Site',
  technician: 'Technician',
  assignToTechnician: 'Assign to Technician',
  reAssignToTechnician: 'Re-assign to Technician',
  reAssign: 'Reassign',
  reAssignTitle: 'Reassign Task to a new contractor',
  removeComments: 'Remove comments posted before the date of reassignment',
  internal: 'Internal',
  sendForReApproval: 'Send for Re-approval',
  addAttachment: 'Add attachment',
  name: 'Name',
  role: 'Role',
  clients: 'Clients',
  upcomingTasks: 'Upcoming WIs',
  assign: 'Assign',
  filter: {
    monthly: 'Monthly',
    weekly: 'Weekly',
    allClients: 'All Clients',
    priorityAll: 'All Priority',
    priorityUrgents: 'Only Urgents',
    allTypes: 'All task types',
    allStatuses: 'All statuses',
    allResponsibles: 'All Resp. for works',
    allAssetTypes: 'All Asset types',
    allSystemTypes: 'All System types',
    allSystems: 'All System References',
    allApprovals: 'All Resp. for approval',
    allCompliance: 'All compliance statuses',
    start: 'D/L Start',
    end: 'D/L End',
  },
  timeSpent: 'Time Spent',
  startWorks: 'Start Works',
  startTasks: 'Start Tasks',
  completeWorks: 'Complete Works',
  completeTasks: 'Complete Tasks',
  complete_short: 'Complete',
  completeErrorTitleWorks: 'Works cannot be completed',
  completeErrorTitleTasks: 'Tasks cannot be completed',
  completeErrorIncompleteAssetsDescription: 'There are incompleted forms:',
  submitSuppInfo: 'Submit Supp Info',
  submittedSuppInfo: 'Supp Info Submitted',
  completeForm: 'Complete',
  saveFormDraft: 'Save as Draft',
  inspector: 'Inspector',
  formEmpty: 'Nothing filled in the form yet.',
  reason: 'Reason',
  close: 'Close the task',
  report: 'Task Results',
  editForm: 'Edit task results',
  created: 'Task created',
  updated: 'Task updated',
  accepted: 'Task approved',
  rejected: 'Task rejected',
  canceled: 'Task cancelled',
  started: 'Task started',
  completed: 'Task completed',
  closed: 'Task closed',
  correctiveOf: 'Original Task',
  correctiveTask: 'Corrective Task Created',
  responsibleRejected: 'Task rejected by Task Responsible',
  responsibleAccepted: 'Task accepted by Task Responsible',
  sentForReApproval: 'Task sent for Re-approval',
  reassigned: 'Task has been reassigned',
  rating: 'How would you rate this contractor for this task?',
  ratingNeeded: 'Please choose a rating before submitting',
  noAcceptedUser: 'There is no user with accepted Request for the Client.',
  signHere: 'Please sign here',
  formTemplateMissing:
    "Form template in site '{{siteName}}'\nfor asset type '{{assetType}}' and task type '{{taskType}}'\nhas not defined yet. You can define it in the ENVIRONMENTAL LICENSE page.",
};
export default enTasks;
