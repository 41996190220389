import { makeStyles } from '@material-ui/core';
import { fade, createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  const { height } = theme.sizes.button;
  const { borderRadius } = theme.shape;
  const grey = theme.palette.grey['300'];

  return createStyles({
    fakeField: {
      height,
      lineHeight: `${height}px`,
      border: `solid 1px ${grey}`,
      borderRadius,
      textAlign: 'center',
    },
    field: {
      height,
      width: '100%',
      minWidth: theme.sizes.button.minWidth,
      '&.no-minwidth': {
        minWidth: 'initial',
      },
      borderRadius,
      '& .MuiInputBase-root, &.MuiInputBase-root': {
        height,
        '& input': {
          paddingTop: 0,
          paddingBottom: 0,
          height,
          borderRadius,
          border: 0,
          fontSize: theme.fontSizes['14'],

          '&.Mui-disabled': {
            background: fade(grey, 0.1),
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          height,
          top: 0,
          '& legend': {
            display: 'none',
          },
        },
        '&:not(.Mui-focused):not(.Mui-error)': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: grey,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey['600'],
          },
        },
      },
    },
    label: {
      width: '100%',
      alignItems: 'flex-start',
      margin: 0,
      '& .MuiFormControlLabel-label': {
        paddingBottom: theme.spacing(1),
        fontSize: theme.fontSizes['12'],
        fontWeight: theme.typography.fontWeightBold,
        '&.Mui-disabled': {
          color: fade(theme.palette.text.primary, 0.4),
        },
      },
      '&.no-label  .MuiFormControlLabel-label': {
        padding: 0,
      },
    },
    labelModules: {
      width: '100%',
      alignItems: 'flex-end',
      margin: 0,
      '& .MuiFormControlLabel-label': {
        paddingBottom: theme.spacing(1),
        fontSize: theme.fontSizes['12'],
        fontWeight: theme.typography.fontWeightBold,
        '&.Mui-disabled': {
          color: fade(theme.palette.text.primary, 0.4),
        },
      },
      '&.no-label  .MuiFormControlLabel-label': {
        padding: 0,
      },
    },
  });
};
const useFieldStyles = makeStyles(styles);
export default useFieldStyles;
