import React from 'react';
import { Avatar, Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    greyBackground: {
      background: theme.palette.grey[500],
    },
  });
});

const makeMonogram = (name?: string) => {
  if (!name) return undefined;
  const names = name.split(' ');
  const monogram: string[] = [];
  names.forEach((obj) => monogram.push(obj.charAt(0)));

  return monogram
    .join('')
    .substr(0, 3) // max length
    .replace(/[^A-Za-z]/gi, ''); // only alphabetic characters allowed
};
const AppAvatar: React.FC<{ imageSrc?: string; name: string }> = ({ imageSrc, name }) => {
  const classes = useStyles();
  return (
    <Avatar alt={name} src={imageSrc} className={classes.greyBackground}>
      {makeMonogram(name)}
    </Avatar>
  );
};

export default AppAvatar;
