import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCommon from 'texts/en/enCommon';
import enClients from 'containers/Pm/Clients/texts/enClients';
import enContractors from 'containers/shared/Contractors/texts/en/enContractors';
import enNotifications from 'containers/Default/components/Header/Notifications/texts/enNotifications';
import enRoles from 'containers/shared/texts/en/enRoles';
import enSystemTypes from 'containers/shared/texts/en/enSystemTypes';
import enAssetTypes from 'containers/shared/texts/en/enAssetTypes';
import enTaskTypes from 'containers/shared/texts/en/enTaskTypes';
import enTasks from 'containers/shared/Tasks/texts/en/enTasks';
import enTaskStatuses from 'containers/shared/Tasks/texts/en/enTaskStatuses';
import enDocuments from 'containers/Client/Documents/texts/en/enDocuments';
import enProjects from 'containers/Client/Projects/texts/en/enProjects';
import enReOccurence from 'components/form/ReOccurenceField/texts/en/enReOccurence';
import enAssets from 'containers/shared/texts/en/enAssets';
import enDrawings from 'containers/shared/texts/en/enDrawings';
import enUrls from './en/enUrls';
import enForm from './en/enForm';
import enCompliance from './en/enCompliance';
import enModuleTypes  from 'containers/shared/texts/en/enModuleTypes';

const en = {
  common: enCommon,
  form: enForm,
  urls: enUrls,
  assets: enAssets,
  assetTypes: enAssetTypes,
  drawings: enDrawings,
  systemTypes: enSystemTypes,
  taskStatuses: enTaskStatuses,
  taskTypes: enTaskTypes,
  tasks: enTasks,
  clients: enClients,
  roles: enRoles,
  contractors: enContractors,
  notifications: enNotifications,
  documents: enDocuments,
  reOccurence: enReOccurence,
  compliance: enCompliance,
  projects: enProjects,
  modules: enModuleTypes,
};

i18n.use(initReactI18next).init({
  ns: Object.keys(en),
  resources: {
    en,
  },
  lng: 'en',
  fallbackLng: 'en',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

export type I18n = typeof en;
export default i18n;
