import { TaskType } from 'models/graphql';

const enTaskTypes: Record<TaskType, string> = {
  [TaskType.COMPLIANCE_CHECK]: 'Compliance check',
  [TaskType.SURVEY]: 'Survey',
  [TaskType.REPAIR]: 'Repair work',
  [TaskType.SAMPLING]: 'Sampling',
  [TaskType.TESTING]: 'Testing',
  [TaskType.AUDIT]: 'Audit',
  [TaskType.OTHER]: 'Other',
  [TaskType.TOOLBOX_TALK]: 'Toolbox talk',
  [TaskType.HEALTH_AND_SAFETY]: 'Health and safety',
  [TaskType.CLEANING]: 'Cleaning',
  [TaskType.PEST_CONTROL]: 'Pest Control',
  [TaskType.REPORT]: 'Report',
};
export default enTaskTypes;
