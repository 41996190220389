const enAssets = {
  assets: 'Assets',
  asset: 'Asset',
  searchPlaceholder: 'Search',
  filter: {
    allTypes: 'All Asset Types',
    allSystemTypes: 'All System Types',
    allSystemRefs: 'All System Refs',
    allOwners: 'All Owners',
  },
};
export default enAssets;
