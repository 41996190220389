const enForm = {
  unsavedChangesTitle: 'Unsaved Changes',
  unsavedChangesDescription:
    'You are about to close the form with unsaved changes. Would you like to save these changes?',
  saveChanges: 'Save Changes',
  discard: 'Discard',
  fieldRequired: 'Please fill out this field!',
  someTokensMissing: 'Some of tokens are missed',
  fieldWillNotBeShown: "These fields won't be shown in the uploaded template:\n",
  noTokens: 'No tokens present',
  noFields:
    'There are no tokens it the uploaded file. \n' +
    '\n' +
    'Please download a sample XLSX file and use it as a template. ',
  fieldPlaceholderRange: 'from {{a}} to {{b}}',
};
export default enForm;
