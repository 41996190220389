/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: documentFileList
// ====================================================


import { Pagination } from 'utils/pagination/Pagination.interface';

export interface documentFileList_documents_file {
  __typename: 'File';
  id: string;
}

export interface documentFileList_documents {
  __typename: 'Document';
  file: documentFileList_documents_file;
  name: string;
}

export interface documentFileList {
  documents: documentFileList_documents[];
}

export interface documentFileListVariables {
  siteId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadRequest
// ====================================================

export interface uploadRequest_uploadRequest {
  __typename: 'UploadRequest';
  url: string;
  srcurl: string;
  id: string;
  extension: string;
}

export interface uploadRequest {
  uploadRequest: uploadRequest_uploadRequest;
}

export interface uploadRequestVariables {
  fileName: string;
  public: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: downloadRequest
// ====================================================

export interface downloadRequest_downloadRequest {
  __typename: 'DownloadRequest';
  url: string;
}

export interface downloadRequest {
  downloadRequest: downloadRequest_downloadRequest;
}

export interface downloadRequestVariables {
  fileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: downloadRequestWithSrc
// ====================================================

export interface downloadRequestWithSrc_downloadRequest {
  __typename: 'DownloadRequest';
  url: string;
  extension: string;
}

export interface downloadRequestWithSrc {
  downloadRequest: downloadRequestWithSrc_downloadRequest;
}

export interface downloadRequestWithSrcVariables {
  fileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAssetForRegister
// ====================================================

export interface getAssetForRegister_asset_system {
  __typename: 'System';
  id: string;
  type: SystemType;
  name: string;
}

export interface getAssetForRegister_asset_owner_picture {
  __typename: 'File';
  srcUrl: string;
}

export interface getAssetForRegister_asset_owner {
  __typename: 'User';
  id: string;
  name: string;
  picture: getAssetForRegister_asset_owner_picture | null;
}

export interface getAssetForRegister_asset_coordinates {
  __typename: 'AssetCoordinatesOutput';
  longitude: number;
  latitude: number;
}

export interface getAssetForRegister_asset_drawings_file {
  __typename: 'File';
  id: string;
  originalName: string;
  extension: string;
}

export interface getAssetForRegister_asset_drawings {
  __typename: 'Drawing';
  id: string;
  drawingId: string;
  file: getAssetForRegister_asset_drawings_file;
}

export interface getAssetForRegister_asset {
  __typename: 'AssetOutput';
  id: string;
  department: string | null;
  system: getAssetForRegister_asset_system;
  name: string;
  type: AssetType;
  createdAt: any;
  owner: getAssetForRegister_asset_owner;
  status: AssetStatus;
  coordinates: getAssetForRegister_asset_coordinates | null;
  additionalInformation: string | null;
  drawings: getAssetForRegister_asset_drawings[];
  expireOption: boolean | false;
  expiration: string;
}

export interface getAssetForRegister {
  asset: getAssetForRegister_asset;
}

export interface getAssetForRegisterVariables {
  assetId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assetsForSite
// ====================================================

export interface assetsForSite_site_assets_site {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface assetsForSite_site_assets_upcomingWorks {
  __typename: 'Task';
  id: string;
  type: TaskType;
  deadline: string | null;
  idReadable: string | undefined;
}

export interface assetsForSite_site_assets_recentWorks {
  __typename: 'Task';
  id: string;
  type: TaskType;
  deadline: string | null;
  idReadable: string | undefined;
}

export interface assetsForSite_site_assets_system {
  __typename: 'System';
  id: string;
  type: SystemType;
  name: string;
}

export interface assetsForSite_site_assets_owner_picture {
  __typename: 'File';
  srcUrl: string;
}

export interface assetsForSite_site_assets_owner {
  __typename: 'User';
  id: string;
  name: string;
  picture: assetsForSite_site_assets_owner_picture | null;
}

export interface assetsForSite_site_assets_coordinates {
  __typename: 'AssetCoordinatesOutput';
  longitude: number;
  latitude: number;
}

export interface assetsForSite_site_assets_drawings_file {
  __typename: 'File';
  id: string;
  originalName: string;
  extension: string;
}

export interface assetsForSite_site_assets_drawings {
  __typename: 'Drawing';
  id: string;
  drawingId: string;
  file: assetsForSite_site_assets_drawings_file;
}

export interface assetsForSite_site_assets_pumpInfo {
  __typename: 'FetchedPumpInfoOutput';
  id: string;
  name: string;
  pump1: boolean;
  pump2: boolean;
  temp: number;
  batt: number;
  tankLevel: number;
  pump1runtime: number;
  pump2runtime: number;
}

export interface assetsForSite_site_assets {
  __typename: 'AssetOutput';
  id: string;
  site: assetsForSite_site_assets_site;
  upcomingWorks: assetsForSite_site_assets_upcomingWorks[] | null;
  recentWorks: assetsForSite_site_assets_recentWorks[] | null;
  department: string | null;
  system: assetsForSite_site_assets_system;
  name: string;
  type: AssetType;
  createdAt: any;
  owner: assetsForSite_site_assets_owner;
  status: AssetStatus;
  coordinates: assetsForSite_site_assets_coordinates | null;
  additionalInformation: string | null;
  drawings: assetsForSite_site_assets_drawings[];
  compliant: boolean | null;
  pumpInfo: assetsForSite_site_assets_pumpInfo[] | null;
  expireOption: boolean | false;
  expiration: string;
}

export interface assetsForSite_site {
  __typename: 'Site';
  id: string;
  assets: assetsForSite_site_assets[];
}

export interface assetsForSite {
  site: assetsForSite_site;
}

export interface assetsForSiteVariables {
  siteId: string;
  search?: string | null;
  type?: AssetType | null;
  systemType?: SystemType | null;
  systemRef?: string | null;
  owner?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: drawingsForSite
// ====================================================

export interface drawingsForSite_site_drawings_site {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface drawingsForSite_site_drawings_file {
  __typename: 'File';
  id: string;
  originalName: string;
  extension: string;
}

export interface drawingsForSite_site_drawings_assets_system {
  __typename: 'System';
  name: string;
  id: string;
}

export interface drawingsForSite_site_drawings_assets {
  __typename: 'AssetOutput';
  type: AssetType;
  id: string;
  system: drawingsForSite_site_drawings_assets_system;
}

export interface drawingsForSite_site_drawings {
  __typename: 'Drawing';
  id: string;
  drawingId: string;
  site: drawingsForSite_site_drawings_site;
  file: drawingsForSite_site_drawings_file;
  systemType: SystemType;
  assets: drawingsForSite_site_drawings_assets[];
}

export interface drawingsForSite_site {
  __typename: 'Site';
  id: string;
  drawings: drawingsForSite_site_drawings[];
}

export interface drawingsForSite {
  site: drawingsForSite_site;
}

export interface drawingsForSiteVariables {
  siteId: string;
  search?: string | null;
  type?: AssetType | null;
  systemRef?: string | null;
  systemType?: SystemType | null;
  hasAsset?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAssetFilterBySite
// ====================================================

export interface getAssetFilterBySite_assetFilter_owners {
  __typename: 'User';
  id: string;
  name: string;
}

export interface getAssetFilterBySite_assetFilter {
  __typename: 'AssetFilterOutput';
  assetsTypes: AssetType[];
  systemTypes: SystemType[];
  systemRefs: string[];
  owners: getAssetFilterBySite_assetFilter_owners[];
}

export interface getAssetFilterBySite {
  assetFilter: getAssetFilterBySite_assetFilter;
}

export interface getAssetFilterBySiteVariables {
  siteId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAssetFilterByUser
// ====================================================

export interface getAssetFilterByUser_assetFilter_owners {
  __typename: 'User';
  id: string;
  name: string;
}

export interface getAssetFilterByUser_assetFilter {
  __typename: 'AssetFilterOutput';
  assetsTypes: AssetType[];
  systemTypes: SystemType[];
  owners: getAssetFilterByUser_assetFilter_owners[];
}

export interface getAssetFilterByUser {
  assetFilter: getAssetFilterByUser_assetFilter;
}

export interface getAssetFilterByUserVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientForAcceptRequest
// ====================================================

export interface getClientForAcceptRequest_client_sites {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface getClientForAcceptRequest_client_subscription {
  __typename: 'SubscriptionOutput';
  allowedSystems: SystemType[];
}

export interface getClientForAcceptRequest_client {
  __typename: 'Client';
  id: string;
  sites: getClientForAcceptRequest_client_sites[];
  subscription: getClientForAcceptRequest_client_subscription | null;
}

export interface getClientForAcceptRequest {
  client: getClientForAcceptRequest_client;
}

export interface getClientForAcceptRequestVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getContractorsForAcceptRequest
// ====================================================

export interface getContractorsForAcceptRequest_contractors_users {
  __typename: 'User';
  id: string;
  name: string;
}

export interface getContractorsForAcceptRequest_contractors {
  __typename: 'Contractor';
  id: string;
  companyName: string;
  users: getContractorsForAcceptRequest_contractors_users[];
}

export interface getContractorsForAcceptRequest {
  contractors: getContractorsForAcceptRequest_contractors[];
}

export interface getContractorsForAcceptRequestVariables {
  search?: string | null;
  archived?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clientAcceptRequest
// ====================================================

export interface clientAcceptRequest_clientAcceptRequest_sites {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface clientAcceptRequest_clientAcceptRequest_workOrders {
  __typename: 'WorkOrderOutput';
  id: string;
  expiracy: string;
}

export interface clientAcceptRequest_clientAcceptRequest_manager {
  __typename: 'User';
  id: string;
  name: string;
}

export interface clientAcceptRequest_clientAcceptRequest {
  __typename: 'ContractorRequestOutput';
  id: string;
  accessStatus: ContractorRequestAccessStatus;
  sites: clientAcceptRequest_clientAcceptRequest_sites[];
  systemTypes: SystemType[] | null;
  contractNumber: string | null;
  contractExpiracy: string | null;
  workOrders: clientAcceptRequest_clientAcceptRequest_workOrders[] | null;
  manager: clientAcceptRequest_clientAcceptRequest_manager | null;
  twoFactorAuth: boolean | null;
}

export interface clientAcceptRequest {
  clientAcceptRequest: clientAcceptRequest_clientAcceptRequest;
}

export interface clientAcceptRequestVariables {
  clientId: string;
  contractorId: string;
  accessRights: ContractorRequestAccessRights;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clientDenyRequest
// ====================================================

export interface clientDenyRequest_clientDenyRequest {
  __typename: 'ContractorRequestOutput';
  id: string;
  accessStatus: ContractorRequestAccessStatus;
}

export interface clientDenyRequest {
  clientDenyRequest: clientDenyRequest_clientDenyRequest;
}

export interface clientDenyRequestVariables {
  clientId: string;
  contractorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: siteAssetsStatistic
// ====================================================

export interface siteAssetsStatistic_site_upcomingTasks_contractorResponsible {
  __typename: 'Contractor';
  id: string;
  companyName: string;
}

export interface siteAssetsStatistic_site_upcomingTasks_internalResponsible {
  __typename: 'User';
  id: string;
  name: string;
}

export interface siteAssetsStatistic_site_upcomingTasks {
  __typename: 'Task';
  id: string;
  idReadable: string;
  type: TaskType;
  contractorResponsible: siteAssetsStatistic_site_upcomingTasks_contractorResponsible | null;
  internalResponsible: siteAssetsStatistic_site_upcomingTasks_internalResponsible | null;
  deadline: string | null;
}

export interface siteAssetsStatistic_site_assets {
  __typename: 'AssetOutput';
  id: string;
  type: AssetType;
  compliant: boolean | null;
}

export interface siteAssetsStatistic_site_assetTypesStatistic {
  __typename: 'AssetTypeStatisticOutput';
  type: AssetType;
  isAllowed: boolean;
  assetsNumber: number;
  drawingsNumber: number;
}

export interface siteAssetsStatistic_site {
  __typename: 'Site';
  id: string;
  upcomingTasks: siteAssetsStatistic_site_upcomingTasks[];
  assets: siteAssetsStatistic_site_assets[];
  assetTypesStatistic: siteAssetsStatistic_site_assetTypesStatistic[];
}

export interface siteAssetsStatistic {
  site: siteAssetsStatistic_site;
}

export interface siteAssetsStatisticVariables {
  id: string;
  types: AssetType[];
}

export interface siteAssetsStatisticVariables_Upcoming_Only {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDocuments
// ====================================================

export interface getDocuments_documents_systems {
  __typename: 'System';
  id: string;
  name: string;
  type: SystemType;
}

export interface getDocuments_documents_assets {
  __typename: 'AssetOutput';
  id: string;
  name: string;
}

export interface getDocuments_documents_file {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface getDocuments_documents_uploadedBy {
  __typename: 'User';
  name: string;
}

export interface getDocuments_documents {
  __typename: 'Document';
  id: string;
  name: string;
  category: DocumentCategory;
  status: DocumentStatus;
  systems: getDocuments_documents_systems[] | null;
  assets: getDocuments_documents_assets[] | null;
  file: getDocuments_documents_file;
  uploadedAt: any;
  uploadedBy: getDocuments_documents_uploadedBy;
}

export interface getDocuments {
  documents: getDocuments_documents[];
}

export interface getDocumentsVariables {
  siteId: string;
  search?: string | null;
  archived?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: clientSitesWithAssets
// ====================================================

export interface clientSitesWithAssets_client_sites_assets {
  __typename: 'AssetOutput';
  id: string;
  name: string;
}

export interface clientSitesWithAssets_client_sites_systems {
  __typename: 'System';
  id: string;
  name: string;
}

export interface clientSitesWithAssets_client_sites {
  __typename: 'Site';
  id: string;
  name: string;
  assets: clientSitesWithAssets_client_sites_assets[];
  systems: clientSitesWithAssets_client_sites_systems[];
}

export interface clientSitesWithAssets_client {
  __typename: 'Client';
  id: string;
  sites: clientSitesWithAssets_client_sites[];
}

export interface clientSitesWithAssets {
  client: clientSitesWithAssets_client;
}

export interface clientSitesWithAssetsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDocument
// ====================================================

export interface getDocument_document_systems {
  __typename: 'System';
  id: string;
  name: string;
  type: SystemType;
}

export interface getDocument_document_assets {
  __typename: 'AssetOutput';
  id: string;
  name: string;
}

export interface getDocument_document_file {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface getDocument_document_uploadedBy {
  __typename: 'User';
  name: string;
}

export interface getDocument_document_site {
  __typename: 'Site';
  id: string;
}

export interface getDocument_document {
  __typename: 'Document';
  id: string;
  name: string;
  category: DocumentCategory;
  systems: getDocument_document_systems[] | null;
  status: DocumentStatus;
  assets: getDocument_document_assets[] | null;
  file: getDocument_document_file;
  uploadedAt: any;
  uploadedBy: getDocument_document_uploadedBy;
  site: getDocument_document_site;
}

export interface getDocument {
  document: getDocument_document;
}

export interface getDocumentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: documentCreate
// ====================================================

export interface documentCreate_documentCreate {
  __typename: 'Document';
  id: string;
}

export interface documentCreate {
  documentCreate: documentCreate_documentCreate;
}

export interface documentCreateVariables {
  document: DocumentCreate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: documentUpdate
// ====================================================

export interface documentUpdate_documentUpdate {
  __typename: 'Document';
  id: string;
  name: string;
  category: DocumentCategory;
}

export interface documentUpdate {
  documentUpdate: documentUpdate_documentUpdate;
}

export interface documentUpdateVariables {
  id: string;
  changes: DocumentUpdate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: documentStatusUpdate
// ====================================================

export interface documentStatusUpdate_documentStatusUpdate {
  __typename: 'Document';
  id: string;
  status: DocumentStatus;
}

export interface documentStatusUpdate {
  documentStatusUpdate: documentStatusUpdate_documentStatusUpdate;
}

export interface documentStatusUpdateVariables {
  id: string;
  status: DocumentStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientForTaskCreation
// ====================================================

export interface getClientForTaskCreation_client_requests_contractor_logo {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface getClientForTaskCreation_client_requests_contractor {
  __typename: 'Contractor';
  id: string;
  companyName: string;
  logo: getClientForTaskCreation_client_requests_contractor_logo | null;
}

export interface getClientForTaskCreation_client_requests_workOrders {
  __typename: 'WorkOrderOutput';
  id: string;
  expiracy: string;
}

export interface getClientForTaskCreation_client_requests {
  __typename: 'ContractorRequestOutput';
  id: string;
  contractor: getClientForTaskCreation_client_requests_contractor;
  workOrders: getClientForTaskCreation_client_requests_workOrders[] | null;
}

export interface getClientForTaskCreation_client_users_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface getClientForTaskCreation_client_users {
  __typename: 'User';
  id: string;
  name: string;
  role: Role;
  picture: getClientForTaskCreation_client_users_picture | null;
}

export interface getClientForTaskCreation_client {
  projects: string | number | boolean | {} | ReactElement<any, string | JSXElementConstructor<any>> | ReactNodeArray | ReactPortal | null | undefined;
  __typename: 'Client';
  id: string;
  modules: any;
  requests: getClientForTaskCreation_client_requests[];
  users: getClientForTaskCreation_client_users[];
}

export interface getClientForTaskCreation_site_assets_system {
  __typename: 'System';
  id: string;
  type: SystemType;
}

export interface getClientForTaskCreation_site_assets {
  __typename: 'AssetOutput';
  id: string;
  name: string;
  system: getClientForTaskCreation_site_assets_system;
  type: AssetType;
}

export interface getClientForTaskCreation_site_formTemplates {
  __typename: 'FormTemplate';
  id: string;
  formType: TaskType;
  assetType: AssetType;
  active: boolean;
}

export interface getClientForTaskCreation_site {
  __typename: 'Site';
  id: string;
  name: string;
  assets: getClientForTaskCreation_site_assets[];
  formTemplates: getClientForTaskCreation_site_formTemplates[];
}

export interface getClientForTaskCreation {
  client: getClientForTaskCreation_client;
  site: getClientForTaskCreation_site;
}

export interface getClientForTaskCreationVariables {
  clientId: string;
  siteId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: taskForEdition
// ====================================================

export interface taskForEdition_task_internalResponsible {
  __typename: 'User';
  id: string;
  name: string;
}

export interface taskForEdition_task_contractorResponsible {
  __typename: 'Contractor';
  id: string;
  companyName: string;
}

export interface taskForEdition_task_approval {
  __typename: 'User';
  id: string;
  name: string;
}

export interface taskForEdition_task_attachments {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface taskForEdition_task_assets_asset {
  __typename: 'AssetOutput';
  id: string;
}

export interface taskForEdition_task_assets {
  __typename: 'TaskAsset';
  id: string;
  asset: taskForEdition_task_assets_asset;
}

export interface taskForEdition_task {
  __typename: 'Task';
  id: string;
  idReadable: string;
  status: TaskStatus;
  type: TaskType;
  notes: string | null;
  tasktitle: string | null;
  urgent: boolean | null;
  internalResponsible: taskForEdition_task_internalResponsible | null;
  contractorResponsible: taskForEdition_task_contractorResponsible | null;
  contractorResponsibleWorkOrderId: string | null;
  approval: taskForEdition_task_approval | null;
  attachments: taskForEdition_task_attachments[];
  assets: taskForEdition_task_assets[];
  deadline: string | null;
  documentDeadline: string | null;
  haveAnyFutureOccurence: boolean;
  projectid: string | null;
  taskstart: string | null;
}

export interface taskForEdition {
  task: taskForEdition_task;
}

export interface taskForEditionVariables {
  taskId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskCreate
// ====================================================

export interface taskCreate_taskCreate {
  __typename: 'Task';
  id: string;
}

export interface taskCreate {
  taskCreate: taskCreate_taskCreate;
}

export interface taskCreateVariables {
  task: TaskCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: tasksForClient
// ====================================================

export interface tasksForClient_site_systems {
  __typename: 'System';
  id: string;
  name: string;
}

export interface tasksForClient_site_tasks_nodes_approval {
  __typename: 'User';
  id: string;
}

export interface tasksForClient_site_tasks_nodes_internalResponsible {
  __typename: 'User';
  id: string;
  name: string;
}

export interface tasksForClient_site_tasks_nodes_contractorResponsible {
  __typename: 'Contractor';
  id: string;
  companyName: string;
}

export interface tasksForClient_site_tasks_nodes_assets_asset_system {
  __typename: 'System';
  name: string;
}

export interface tasksForClient_site_tasks_nodes_assets_asset {
  __typename: 'AssetOutput';
  system: tasksForClient_site_tasks_nodes_assets_asset_system;
}

export interface tasksForClient_site_tasks_nodes_assets {
  __typename: 'TaskAsset';
  asset: tasksForClient_site_tasks_nodes_assets_asset;
  compliant: boolean;
}

export interface tasksForClient_site_tasks_nodes {
  __typename: 'Task';
  id: string;
  idReadable: string;
  type: TaskType;
  approval: tasksForClient_site_tasks_nodes_approval | null;
  internalResponsible: tasksForClient_site_tasks_nodes_internalResponsible | null;
  contractorResponsible: tasksForClient_site_tasks_nodes_contractorResponsible | null;
  assets: tasksForClient_site_tasks_nodes_assets[];
  compliant: boolean | null;
  contractorResponsibleWorkOrderId: string | null;
  deadline: string | null;
  documentDeadline: string | null;
  status: TaskStatus;
}

export interface tasksForClient_site_tasks {
  __typename: 'PaginatedTaskOutput';
  nodes: tasksForClient_site_tasks_nodes[];
  totalNodes: number;
  page: number;
  totalPages: number;
}

export interface tasksForClient_site {
  __typename: 'Site';
  id: string;
  systems: tasksForClient_site_systems[];
  tasks: tasksForClient_site_tasks;
}

export interface tasksForClient {
  site: tasksForClient_site;
}

export interface tasksForClientVariables {
  siteId: string;
  search?: string | null;
  pagination?: PaginationInput | null;
  type?: TaskType | null;
  status?: TaskStatus | null;
  responsible?: string | null;
  approval?: string | null;
  deadlineStart?: any | null;
  deadlineEnd?: any | null;
  assets?: AssetType | null;
  systemRef?: string | null;
  compliant?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: taskForClient
// ====================================================

export interface taskForClient_task_internalResponsible {
  __typename: 'User';
  id: string;
  name: string;
}

export interface taskForClient_task_contractorResponsible {
  __typename: 'Contractor';
  id: string;
  companyName: string;
}

export interface taskForClient_task_approval_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
  originalName: string;
}

export interface taskForClient_task_approval {
  __typename: 'User';
  id: string;
  name: string;
  picture: taskForClient_task_approval_picture | null;
}

export interface taskForClient_task_attachments {
  __typename: 'File';
  id: string;
  originalName: string;
  srcUrl: string;
}

export interface taskForClient_task_assets_asset_system {
  __typename: 'System';
  id: string;
  name: string;
}

export interface taskForClient_task_assets_asset {
  __typename: 'AssetOutput';
  id: string;
  name: string;
  system: taskForClient_task_assets_asset_system;
}

export interface taskForClient_task_assets {
  __typename: 'TaskAsset';
  id: string;
  compliant: boolean | null;
  status: TaskAssetStatus;
  updatedAt:  any | null;
  asset: taskForClient_task_assets_asset;
}

export interface taskForClient_task_correctiveOf {
  __typename: 'Task';
  id: string;
  idReadable: string;
}

export interface taskForClient_task_corrective {
  __typename: 'Task';
  id: string;
  idReadable: string;
}

export interface taskForClient_task_timer {
  __typename: 'TaskTimerOutput';
  timeSpent: number;
}

export interface taskForClient_task {
  __typename: 'Task';
  id: string;
  idReadable: string;
  type: TaskType;
  internalResponsible: taskForClient_task_internalResponsible | null;
  contractorResponsible: taskForClient_task_contractorResponsible | null;
  contractorResponsibleWorkOrderId: string | null;
  approval: taskForClient_task_approval | null;
  assignedAt: any | null;
  notes: string | null;
  tasktitle: string | null;
  urgent: boolean | null;
  attachments: taskForClient_task_attachments[];
  status: TaskStatus;
  assets: taskForClient_task_assets[];
  correctiveOf: taskForClient_task_correctiveOf | null;
  corrective: taskForClient_task_corrective | null;
  deadline: string | null;
  documentDeadline: string | null;
  timer: taskForClient_task_timer;
  projectid:  string | null;
}

export interface taskForClient {
  task: taskForClient_task;
}

export interface taskForClientVariables {
  taskId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskUpdate
// ====================================================

export interface taskUpdate_taskUpdate_approval_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
  originalName: string;
}

export interface taskUpdate_taskUpdate_approval {
  __typename: 'User';
  id: string;
  name: string;
  picture: taskUpdate_taskUpdate_approval_picture | null;
}

export interface taskUpdate_taskUpdate_internalResponsible {
  __typename: 'User';
  id: string;
  name: string;
}

export interface taskUpdate_taskUpdate_contractorResponsible {
  __typename: 'Contractor';
  id: string;
  companyName: string;
}

export interface taskUpdate_taskUpdate_assets_asset_system {
  __typename: 'System';
  name: string;
  id: string;
}

export interface taskUpdate_taskUpdate_assets_asset {
  __typename: 'AssetOutput';
  system: taskUpdate_taskUpdate_assets_asset_system;
  id: string;
  name: string;
}

export interface taskUpdate_taskUpdate_assets {
  __typename: 'TaskAsset';
  asset: taskUpdate_taskUpdate_assets_asset;
  id: string;
  compliant: boolean | null;
}

export interface taskUpdate_taskUpdate_attachments {
  __typename: 'File';
  id: string;
  originalName: string;
  srcUrl: string;
}

export interface taskUpdate_taskUpdate_correctiveOf {
  __typename: 'Task';
  id: string;
  idReadable: string;
}

export interface taskUpdate_taskUpdate_corrective {
  __typename: 'Task';
  id: string;
  idReadable: string;
}

export interface taskUpdate_taskUpdate {
  __typename: 'Task';
  id: string;
  idReadable: string;
  type: TaskType;
  approval: taskUpdate_taskUpdate_approval | null;
  internalResponsible: taskUpdate_taskUpdate_internalResponsible | null;
  contractorResponsible: taskUpdate_taskUpdate_contractorResponsible | null;
  assets: taskUpdate_taskUpdate_assets[];
  compliant: boolean | null;
  contractorResponsibleWorkOrderId: string | null;
  deadline: string | null;
  documentDeadline: string | null;
  status: TaskStatus;
  assignedAt: any | null;
  tasktitle: string | null;
  notes: string | null;
  urgent: boolean | null;
  attachments: taskUpdate_taskUpdate_attachments[];
  correctiveOf: taskUpdate_taskUpdate_correctiveOf | null;
  corrective: taskUpdate_taskUpdate_corrective | null;
}

export interface taskUpdate {
  taskUpdate: taskUpdate_taskUpdate;
}

export interface taskUpdateVariables {
  id: string;
  changes: TaskUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskUpdateOperative
// ====================================================

export interface taskUpdateOperative_taskUpdate {
  __typename: 'Task';
  id: string;
}

export interface taskUpdateOperative {
  taskUpdate: taskUpdateOperative_taskUpdate;
}

export interface taskUpdateOperativeVariables {
  id: string;
  changes: TaskUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskCancel
// ====================================================

export interface taskCancel_taskCancel {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
}

export interface taskCancel {
  taskCancel: taskCancel_taskCancel;
}

export interface taskCancelVariables {
  taskId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteTask
// ====================================================

export interface deleteTask_deleteTask {
  __typename: 'Task';
  id: string;
}

export interface deleteTask {
  deleteTask: deleteTask_deleteTask;
}

export interface deleteTaskVariables {
  taskId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskAccept
// ====================================================

export interface taskAccept_taskAccept_commentsWithArchived_author_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface taskAccept_taskAccept_commentsWithArchived_author {
  __typename: 'User';
  id: string;
  name: string;
  companyName: string;
  picture: taskAccept_taskAccept_commentsWithArchived_author_picture | null;
}

export interface taskAccept_taskAccept_commentsWithArchived {
  __typename: 'TaskComment';
  id: string;
  message: string;
  createdAt: any;
  archived: boolean;
  author: taskAccept_taskAccept_commentsWithArchived_author;
}

export interface taskAccept_taskAccept {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
  commentsWithArchived: taskAccept_taskAccept_commentsWithArchived[];
}

export interface taskAccept {
  taskAccept: taskAccept_taskAccept;
}

export interface taskAcceptVariables {
  taskId: string;
  rating?: number | null;
  comment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskReject
// ====================================================

export interface taskReject_taskReject_commentsWithArchived_author_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface taskReject_taskReject_commentsWithArchived_author {
  __typename: 'User';
  id: string;
  name: string;
  companyName: string;
  picture: taskReject_taskReject_commentsWithArchived_author_picture | null;
}

export interface taskReject_taskReject_commentsWithArchived {
  __typename: 'TaskComment';
  id: string;
  message: string;
  createdAt: any;
  archived: boolean;
  author: taskReject_taskReject_commentsWithArchived_author;
}

export interface taskReject_taskReject {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
  commentsWithArchived: taskReject_taskReject_commentsWithArchived[];
}

export interface taskReject {
  taskReject: taskReject_taskReject;
}

export interface taskRejectVariables {
  taskId: string;
  comment: string;
  rating?: number | null;
  close?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskClose
// ====================================================

export interface taskClose_taskClose {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
}

export interface taskClose {
  taskClose: taskClose_taskClose;
}

export interface taskCloseVariables {
  taskId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientUsersContractors
// ====================================================

export interface getClientUsersContractors_client_contractors {
  __typename: 'Contractor';
  id: string;
  companyName: string;
}

export interface getClientUsersContractors_client_users {
  __typename: 'User';
  id: string;
  name: string;
}

export interface getClientUsersContractors_client {
  __typename: 'Client';
  id: string;
  contractors: getClientUsersContractors_client_contractors[];
  users: getClientUsersContractors_client_users[];
}

export interface getClientUsersContractors {
  client: getClientUsersContractors_client;
}

export interface getClientUsersContractorsVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskReassign
// ====================================================

export interface taskReassign_taskReassign_internalResponsible {
  __typename: 'User';
  id: string;
}

export interface taskReassign_taskReassign_contractorResponsible {
  __typename: 'Contractor';
  id: string;
}

export interface taskReassign_taskReassign_contractorResponsibleUser {
  __typename: 'User';
  id: string;
}

export interface taskReassign_taskReassign_commentsWithArchived {
  __typename: 'TaskComment';
  id: string;
}

export interface taskReassign_taskReassign {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
  contractorResponsibleWorkOrderId: string | null;
  internalResponsible: taskReassign_taskReassign_internalResponsible | null;
  contractorResponsible: taskReassign_taskReassign_contractorResponsible | null;
  contractorResponsibleUser: taskReassign_taskReassign_contractorResponsibleUser | null;
  commentsWithArchived: taskReassign_taskReassign_commentsWithArchived[];
}

export interface taskReassign {
  taskReassign: taskReassign_taskReassign;
}

export interface taskReassignVariables {
  taskId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTaskFilter
// ====================================================

export interface getTaskFilter_taskFilter_systems {
  __typename: 'System';
  id: string;
  name: string;
}

export interface getTaskFilter_taskFilter_responsibles {
  __typename: 'User';
  id: string;
  name: string;
}

export interface getTaskFilter_taskFilter_contractors {
  __typename: 'Contractor';
  id: string;
  contactName: string;
}

export interface getTaskFilter_taskFilter_approvals {
  __typename: 'User';
  id: string;
  name: string;
}

export interface getTaskFilter_taskFilter {
  __typename: 'TaskFilterOutput';
  taskTypes: TaskType[];
  systems: getTaskFilter_taskFilter_systems[];
  responsibles: getTaskFilter_taskFilter_responsibles[];
  contractors: getTaskFilter_taskFilter_contractors[];
  approvals: getTaskFilter_taskFilter_approvals[];
  statuses: TaskStatus[];
  assets: AssetType[];
}

export interface getTaskFilter {
  taskFilter: getTaskFilter_taskFilter;
}

export interface getTaskFilterVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: tasksForContractor
// ====================================================

export interface tasksForContractor_contractor_tasks_nodes_contractorResponsibleUser {
  __typename: 'User';
  id: string;
  name: string;
}

export interface tasksForContractor_contractor_tasks_nodes {
  __typename: 'Task';
  id: string;
  type: TaskType;
  contractorResponsibleWorkOrderId: string | null;
  contractorResponsibleUser: tasksForContractor_contractor_tasks_nodes_contractorResponsibleUser | null;
  assets: tasksForClient_site_tasks_nodes_assets[];
  compliant: boolean | null;
  deadline: string | null;
  documentDeadline: string | null;
  status: TaskStatus;
}

export interface tasksForContractor_contractor_tasks {
  __typename: 'PaginatedTaskOutput';
  nodes: tasksForContractor_contractor_tasks_nodes[];
  totalNodes: number;
  page: number;
  totalPages: number;
}

export interface tasksForContractor_contractor {
  __typename: 'Contractor';
  id: string;
  tasks: tasksForContractor_contractor_tasks;
}

export interface tasksForContractor {
  contractor: tasksForContractor_contractor;
}

export interface tasksForContractorVariables {
  contractorId: string;
  search?: string | null;
  pagination?: PaginationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: taskForContractor
// ====================================================

export interface taskForContractor_task_timer {
  __typename: 'TaskTimerOutput';
  timeSpent: number;
}

export interface taskForContractor_task_approval_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
  originalName: string;
}

export interface taskForContractor_task_approval {
  __typename: 'User';
  id: string;
  name: string;
  email: string;
  phonenumber: string;
  picture: taskForContractor_task_approval_picture | null;
}

export interface taskForContractor_task_site {
  __typename: 'Site';
  id: string;
  name: string;
  companyName: string;
}

export interface taskForContractor_task_attachments {
  __typename: 'File';
  id: string;
  originalName: string;
  srcUrl: string;
}

export interface taskForContractor_task_assets_asset_system {
  __typename: 'System';
  id: string;
  name: string;
}

export interface taskForContractor_task_assets_asset {
  __typename: 'AssetOutput';
  id: string;
  name: string;
  system: taskForContractor_task_assets_asset_system;
}

export interface taskForContractor_task_assets {
  __typename: 'TaskAsset';
  id: string;
  compliant: boolean | null;
  status: TaskAssetStatus;
  updatedAt:  any | null;
  asset: taskForContractor_task_assets_asset;
}

export interface taskForContractor_task_correctiveOf {
  __typename: 'Task';
  id: string;
  idReadable: string;
}

export interface taskForContractor_task_corrective {
  __typename: 'Task';
  id: string;
  idReadable: string;
}

export interface taskForContractor_task {
  __typename: 'Task';
  id: string;
  timer: taskForContractor_task_timer;
  status: TaskStatus;
  approval: taskForContractor_task_approval | null;
  site: taskForContractor_task_site;
  contractorResponsibleWorkOrderId: string | null;
  notes: string | null;
  tasktitle: string | null;
  urgent: boolean | null;
  attachments: taskForContractor_task_attachments[];
  assets: taskForContractor_task_assets[];
  correctiveOf: taskForContractor_task_correctiveOf | null;
  corrective: taskForContractor_task_corrective | null;
  projectid: string | null;
}

export interface taskForContractor {
  task: taskForContractor_task;
}

export interface taskForContractorVariables {
  taskId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskSendForReApproval
// ====================================================

export interface taskSendForReApproval_taskSendForReApproval {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
}

export interface taskSendForReApproval {
  taskSendForReApproval: taskSendForReApproval_taskSendForReApproval;
}

export interface taskSendForReApprovalVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTechnicians
// ====================================================

export interface getTechnicians_contractor_users_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface getTechnicians_contractor_users_clients {
  __typename: 'Client';
  id: string;
  companyName: string;
}

export interface getTechnicians_contractor_users {
  __typename: 'User';
  id: string;
  name: string;
  picture: getTechnicians_contractor_users_picture | null;
  role: Role;
  clients: getTechnicians_contractor_users_clients[];
  upcomingTasksCount: number;
}

export interface getTechnicians_contractor {
  __typename: 'Contractor';
  users: getTechnicians_contractor_users[];
}

export interface getTechnicians_task {
  __typename: 'Task';
  id: string;
  clientId: string;
}

export interface getTechnicians {
  contractor: getTechnicians_contractor;
  task: getTechnicians_task;
}

export interface getTechniciansVariables {
  contractorId: string;
  taskId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskAssignToContractorUser
// ====================================================

export interface taskAssignToContractorUser_taskAssignToContractorUser_contractorResponsibleUser {
  __typename: 'User';
  id: string;
  name: string;
}

export interface taskAssignToContractorUser_taskAssignToContractorUser {
  __typename: 'Task';
  id: string;
  contractorResponsibleUser: taskAssignToContractorUser_taskAssignToContractorUser_contractorResponsibleUser | null;
}

export interface taskAssignToContractorUser {
  taskAssignToContractorUser: taskAssignToContractorUser_taskAssignToContractorUser;
}

export interface taskAssignToContractorUserVariables {
  taskId: string;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAssetForTask
// ====================================================

export interface getAssetForTask_asset_system {
  __typename: 'System';
  id: string;
  type: SystemType;
  name: string;
}

export interface getAssetForTask_asset_owner {
  __typename: 'User';
  id: string;
  name: string;
}

export interface getAssetForTask_asset_site {
  __typename: 'Site';
  name: string;
}

export interface getAssetForTask_asset_coordinates {
  __typename: 'AssetCoordinatesOutput';
  longitude: number;
  latitude: number;
}

export interface getAssetForTask_asset_drawings_file {
  __typename: 'File';
  id: string;
  originalName: string;
  extension: string;
}

export interface getAssetForTask_asset_drawings {
  __typename: 'Drawing';
  id: string;
  file: getAssetForTask_asset_drawings_file;
}

export interface getAssetForTask_asset {
  __typename: 'AssetOutput';
  id: string;
  name: string;
  type: AssetType;
  system: getAssetForTask_asset_system;
  createdAt: any;
  owner: getAssetForTask_asset_owner;
  department: string | null;
  site: getAssetForTask_asset_site;
  coordinates: getAssetForTask_asset_coordinates | null;
  additionalInformation: string | null;
  drawings: getAssetForTask_asset_drawings[];
}

export interface getAssetForTask {
  asset: getAssetForTask_asset;
}

export interface getAssetForTaskVariables {
  assetId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: operativeHome
// ====================================================

export interface operativeHome_user_tasks_site {
  __typename: 'Site';
  companyName: string;
}

export interface operativeHome_user_tasks {
  __typename: 'Task';
  id: string;
  type: TaskType;
  notes: string | null;
  tasktitle: string | null;
  adhoc: boolean;
  deadline: string | null;
  urgent: boolean | null;
  assetsCount: number;
  assets: tasksForClient_site_tasks_nodes_assets[];
  compliant: boolean | null;
  status: TaskStatus;
  site: operativeHome_user_tasks_site;
}

export interface operativeHome_user {
  __typename: 'User';
  id: string;
  tasks: operativeHome_user_tasks[];
}

export interface operativeHome {
  user: operativeHome_user;
}

export interface operativeHomeVariables {
  id: string;
  withoutRejected?: boolean | null;
  status?: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: tasksForOperative
// ====================================================

export interface tasksForOperative_user_tasks_site {
  __typename: 'Site';
  id: string;
  companyName: string;
}

export interface tasksForOperative_user_tasks {
  __typename: 'Task';
  id: string;
  type: TaskType;
  notes: string | null;
  tasktitle: string | null;
  adhoc: boolean;
  deadline: string | null;
  status: TaskStatus;
  assets: tasksForClient_site_tasks_nodes_assets[];
  site: tasksForOperative_user_tasks_site;
  assetsCount: number;
  urgent: boolean | null;
  compliant: boolean | null;
}

export interface tasksForOperative_user_clients {
  __typename: 'Client';
  id: string;
  companyName: string;
}

export interface tasksForOperative_user {
  __typename: 'User';
  id: string;
  tasks: tasksForOperative_user_tasks[];
  clients: tasksForOperative_user_clients[];
}

export interface tasksForOperative {
  user: tasksForOperative_user;
}

export interface tasksForOperativeVariables {
  userId: string;
  dayDateRange?: DayDateRange | null;
  type?: TaskType | null;
  urgent?: boolean | null;
  withoutRequests?: boolean | null;
  withoutRejected?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskCreateMyself
// ====================================================

export interface taskCreateMyself_taskCreate {
  __typename: 'Task';
  id: string;
}

export interface taskCreateMyself {
  taskCreate: taskCreateMyself_taskCreate;
}

export interface taskCreateMyselfVariables {
  task: TaskCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assetsAndFormTypesForSite
// ====================================================

export interface assetsAndFormTypesForSite_site_formTemplates {
  __typename: 'FormTemplate';
  id: string;
  formType: TaskType;
  assetType: AssetType;
  subForm: string;
}

export interface assetsAndFormTypesForSite_site_assets {
  __typename: 'AssetOutput';
  id: string;
  name: string;
  type: AssetType;
}

export interface assetsAndFormTypesForSite_site {
  __typename: 'Site';
  id: string;
  formTemplates: assetsAndFormTypesForSite_site_formTemplates[];
  assets: assetsAndFormTypesForSite_site_assets[];
}

export interface assetsAndFormTypesForSite {
  site: assetsAndFormTypesForSite_site;
}

export interface assetsAndFormTypesForSiteVariables {
  siteId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clientCreate
// ====================================================

export interface clientCreate_clientCreate {
  __typename: 'Client';
  id: string;
}

export interface clientCreate {
  clientCreate: clientCreate_clientCreate;
}

export interface clientCreateVariables {
  client: ClientCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClients
// ====================================================

export interface getClients_clients {
  __typename: 'Client';
  id: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  status: ClientStatus;
}

export interface getClients {
  clients: getClients_clients[];
}

export interface getClientsVariables {
  search?: string | null;
  archived?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientsStatistic
// ====================================================

export interface getClientsStatistic_clients_logo {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface getClientsStatistic_clients {
  __typename: 'Client';
  id: string;
  companyName: string;
  sitesNumber: number;
  usersNumber: number;
  assetsNumber: number | null;
  logo: getClientsStatistic_clients_logo | null;
}

export interface getClientsStatistic {
  clients: getClientsStatistic_clients[];
}

export interface getClientsStatisticVariables {
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clientStatusUpdate
// ====================================================

export interface clientStatusUpdate_clientStatusUpdate {
  __typename: 'Client';
  id: string;
  status: ClientStatus;
}

export interface clientStatusUpdate {
  clientStatusUpdate: clientStatusUpdate_clientStatusUpdate;
}

export interface clientStatusUpdateVariables {
  id: string;
  status: ClientStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClient
// ====================================================

export interface getClient_client_logo {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface getClient_client {
  __typename: 'Client';
  id: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  twoFactorAuth: boolean;
  projects: boolean;
  modules: any;
  status: ClientStatus;
  logo: getClient_client_logo | null;
}

export interface getClient {
  client: getClient_client;
}

export interface getClientVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: clientUpdate
// ====================================================

export interface clientUpdate_clientUpdate_logo {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface clientUpdate_clientUpdate {
  __typename: 'Client';
  id: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  twoFactorAuth: boolean;
  logo: clientUpdate_clientUpdate_logo | null;
}

export interface clientUpdate {
  clientUpdate: clientUpdate_clientUpdate;
}

export interface clientUpdateVariables {
  id: string;
  changes: ClientUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getContractorsWithRequests
// ====================================================

export interface getContractorsWithRequests_contractors_requests_client {
  __typename: 'Client';
  id: string;
  companyName: string;
}

export interface getContractorsWithRequests_contractors_requests {
  __typename: 'ContractorRequestOutput';
  id: string;
  client: getContractorsWithRequests_contractors_requests_client;
}

export interface getContractorsWithRequests_contractors {
  __typename: 'Contractor';
  id: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  address: string;
  status: ContractorStatus;
  expertise: Expertise[];
  rating: number | null;
  requests: getContractorsWithRequests_contractors_requests[];
}

export interface getContractorsWithRequests {
  contractors: getContractorsWithRequests_contractors[];
}

export interface getContractorsWithRequestsVariables {
  search?: string | null;
  archived?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: contractorStatusUpdate
// ====================================================

export interface contractorStatusUpdate_contractorStatusUpdate {
  __typename: 'Contractor';
  id: string;
  status: ContractorStatus;
}

export interface contractorStatusUpdate {
  contractorStatusUpdate: contractorStatusUpdate_contractorStatusUpdate;
}

export interface contractorStatusUpdateVariables {
  id: string;
  status: ContractorStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: clientSitesAssetTypes
// ====================================================

export interface clientSitesAssetTypes_client_sites_assets {
  __typename: 'AssetOutput';
  id: string;
  name: string;
  type: AssetType;
}

export interface clientSitesAssetTypes_client_sites_licenses_file {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface clientSitesAssetTypes_client_sites_licenses {
  __typename: 'License';
  id: string;
  file: clientSitesAssetTypes_client_sites_licenses_file;
}

export interface clientSitesAssetTypes_client_sites_formTemplates_fileSingleTemplate {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface clientSitesAssetTypes_client_sites_formTemplates_fileMultiTemplate {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface clientSitesAssetTypes_client_sites_formTemplates {
  __typename: 'FormTemplate';
  id: string;
  formType: TaskType;
  assetType: AssetType;
  content: string;
  subForm: string;
  active: boolean;
  fileSingleTemplate: clientSitesAssetTypes_client_sites_formTemplates_fileSingleTemplate | null;
  fileMultiTemplate: clientSitesAssetTypes_client_sites_formTemplates_fileMultiTemplate | null;
  allowSingleReportPdf: boolean;
  allowMultiReportPdf: boolean;
  docsRequired: boolean;
}

export interface clientSitesAssetTypes_client_sites {
  __typename: 'Site';
  id: string;
  name: string;
  assets: clientSitesAssetTypes_client_sites_assets[];
  licenses: clientSitesAssetTypes_client_sites_licenses[];
  formTemplates: clientSitesAssetTypes_client_sites_formTemplates[];
}

export interface clientSitesAssetTypes_client {
  __typename: 'Client';
  id: string;
  modules: any;
  sites: clientSitesAssetTypes_client_sites[];
}

export interface clientSitesAssetTypes {
  client: clientSitesAssetTypes_client;
}

export interface clientSitesAssetTypesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: licenseCreate
// ====================================================

export interface licenseCreate_licenseCreate_file {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface licenseCreate_licenseCreate {
  __typename: 'License';
  id: string;
  file: licenseCreate_licenseCreate_file;
}

export interface licenseCreate {
  licenseCreate: licenseCreate_licenseCreate;
}

export interface licenseCreateVariables {
  siteId: string;
  fileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: licenseArchive
// ====================================================

export interface licenseArchive_licenseArchive {
  __typename: 'License';
  id: string;
}

export interface licenseArchive {
  licenseArchive: licenseArchive_licenseArchive;
}

export interface licenseArchiveVariables {
  licenseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: clientUsers
// ====================================================

export interface clientUsers_client_users_sites {
  __typename: 'Site';
  name: string;
}

export interface clientUsers_client_users {
  __typename: 'User';
  id: string;
  name: string;
  email: string;
  phonenumber: string;
  role: Role;
  isAdmin: boolean;
  active: boolean;
  sites: clientUsers_client_users_sites[] | null;
}

export interface clientUsers_client {
  __typename: 'Client';
  id: string;
  users: clientUsers_client_users[];
}

export interface clientUsers {
  client: clientUsers_client;
}

export interface clientUsersVariables {
  id: string;
  search?: string | null;
  archived?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: formTemplateCreateOrUpdate
// ====================================================

export interface formTemplateCreateOrUpdate_formTemplateCreateOrUpdate_fileSingleTemplate {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface formTemplateCreateOrUpdate_formTemplateCreateOrUpdate_fileMultiTemplate {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface formTemplateCreateOrUpdate_formTemplateCreateOrUpdate {
  __typename: 'FormTemplate';
  id: string;
  formType: TaskType;
  assetType: AssetType;
  content: string;
  active: boolean;
  siteId: string;
  fileSingleTemplate: formTemplateCreateOrUpdate_formTemplateCreateOrUpdate_fileSingleTemplate | null;
  fileMultiTemplate: formTemplateCreateOrUpdate_formTemplateCreateOrUpdate_fileMultiTemplate | null;
  allowSingleReportPdf?: boolean | null;
  allowMultiReportPdf?: boolean | null;
  docsRequired?: boolean | null;
  module?: string | null;
}

export interface formTemplateCreateOrUpdate {
  formTemplateCreateOrUpdate: formTemplateCreateOrUpdate_formTemplateCreateOrUpdate;
}

export interface formTemplateCreateOrUpdateVariables {
  siteId: string;
  formType: TaskType;
  assetType: AssetType;
  content?: string | null;
  fileSingleTemplate?: string | null;
  fileMultiTemplate?: string | null;
  allowSingleReportPdf?: boolean | null;
  allowMultiReportPdf?: boolean | null;
  subForm?: string | null;
  docsRequired?: boolean | null;
  module?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: formTemplateActivate
// ====================================================

export interface formTemplateActivate_formTemplateActivate {
  __typename: 'FormTemplate';
  id: string;
  content: string;
  formType: TaskType;
  assetType: AssetType;
  subForm: string;
  active: boolean;
  siteId: string;
}

export interface formTemplateActivate {
  formTemplateActivate: formTemplateActivate_formTemplateActivate;
}

export interface formTemplateActivateVariables {
  siteId: string;
  formType: TaskType;
  assetType: AssetType;
  subForm: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientForConfiguration
// ====================================================

export interface getClientForConfiguration_client_logo {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface getClientForConfiguration_client {
  __typename: 'Client';
  id: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  twoFactorAuth: boolean;
  projects: boolean;
  modules: any;
  logo: getClientForConfiguration_client_logo | null;
}

export interface getClientForConfiguration {
  client: getClientForConfiguration_client;
}

export interface getClientForConfigurationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: subscriptionCreate
// ====================================================

export interface subscriptionCreate_subscriptionCreate {
  __typename: 'SubscriptionOutput';
  id: string;
}

export interface subscriptionCreate {
  subscriptionCreate: subscriptionCreate_subscriptionCreate;
}

export interface subscriptionCreateVariables {
  clientId: string;
  subscription: SubscriptionCreate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: drawingCreate
// ====================================================

export interface drawingCreate_drawingCreate_site {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface drawingCreate_drawingCreate_assets {
  __typename: 'AssetOutput';
  id: string;
}

export interface drawingCreate_drawingCreate_file {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface drawingCreate_drawingCreate {
  __typename: 'Drawing';
  id: string;
  drawingId: string;
  site: drawingCreate_drawingCreate_site;
  assets: drawingCreate_drawingCreate_assets[];
  status: DrawingStatus;
  file: drawingCreate_drawingCreate_file;
  systemType: SystemType;
}

export interface drawingCreate {
  drawingCreate: drawingCreate_drawingCreate[];
}

export interface drawingCreateVariables {
  drawing: DrawingCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSubscription
// ====================================================

export interface getSubscription_client_subscription_sites {
  __typename: 'Site';
  id: string;
  name: string;
  address: string;
}

export interface getSubscription_client_subscription {
  __typename: 'SubscriptionOutput';
  id: string;
  idReadable: string;
  billingFrequency: string;
  startingMonth: number;
  sitesMaxNumber: number;
  contractorsMaxNumber: number;
  allowedAssets: AssetType[];
  sites: getSubscription_client_subscription_sites[];
}

export interface getSubscription_client {
  __typename: 'Client';
  id: string;
  subscription: getSubscription_client_subscription | null;
}

export interface getSubscription {
  client: getSubscription_client;
}

export interface getSubscriptionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: subscriptionAllowedAssetsUpdate
// ====================================================

export interface subscriptionAllowedAssetsUpdate_subscriptionAllowedAssetsUpdate {
  __typename: 'SubscriptionOutput';
  id: string;
  allowedAssets: AssetType[];
}

export interface subscriptionAllowedAssetsUpdate {
  subscriptionAllowedAssetsUpdate: subscriptionAllowedAssetsUpdate_subscriptionAllowedAssetsUpdate;
}

export interface subscriptionAllowedAssetsUpdateVariables {
  id: string;
  allowedAssets: AssetType[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: subscriptionUpdate
// ====================================================

export interface subscriptionUpdate_subscriptionUpdate_sites {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface subscriptionUpdate_subscriptionUpdate {
  __typename: 'SubscriptionOutput';
  id: string;
  sites: subscriptionUpdate_subscriptionUpdate_sites[];
  idReadable: string;
  billingFrequency: string;
  startingMonth: number;
  sitesMaxNumber: number;
  contractorsMaxNumber: number;
}

export interface subscriptionUpdate {
  subscriptionUpdate: subscriptionUpdate_subscriptionUpdate;
}

export interface subscriptionUpdateVariables {
  id: string;
  subscription: SubscriptionUpdate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCreateAssetData
// ====================================================

export interface getCreateAssetData_client_sites_drawings_file {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface getCreateAssetData_client_sites_drawings {
  __typename: 'Drawing';
  id: string;
  file: getCreateAssetData_client_sites_drawings_file;
  drawingId: string;
}

export interface getCreateAssetData_client_sites {
  __typename: 'Site';
  id: string;
  name: string;
  drawings: getCreateAssetData_client_sites_drawings[];
}

export interface getCreateAssetData_client_users {
  __typename: 'User';
  id: string;
  name: string;
}

export interface getCreateAssetData_client_systems_site {
  __typename: 'Site';
  id: string;
}

export interface getCreateAssetData_client_systems {
  __typename: 'System';
  id: string;
  type: SystemType;
  name: string;
  site: getCreateAssetData_client_systems_site;
}

export interface getCreateAssetData_client {
  __typename: 'Client';
  id: string;
  sites: getCreateAssetData_client_sites[];
  allowedAssets: AssetType[] | null;
  users: getCreateAssetData_client_users[];
  systems: getCreateAssetData_client_systems[];
}

export interface getCreateAssetData {
  client: getCreateAssetData_client;
}

export interface getCreateAssetDataVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAssets
// ====================================================

export interface getAssets_assets_site {
  __typename: 'Site';
  id: string;
}

export interface getAssets_assets_coordinates {
  __typename: 'AssetCoordinatesOutput';
  latitude: number;
  longitude: number;
}

export interface getAssets_assets_system {
  __typename: 'System';
  id: string;
  type: SystemType;
}

export interface getAssets_assets_owner {
  __typename: 'User';
  id: string;
}

export interface getAssets_assets_drawings {
  __typename: 'Drawing';
  id: string;
}

export interface getAssets_assets_specificInfo_pumps {
  __typename: 'PumpInfoOutput';
  name: string;
  pass: string;
}

export interface getAssets_assets_specificInfo {
  __typename: 'SpecificInfoOutput';
  pumps: getAssets_assets_specificInfo_pumps[];
}

export interface getAssets_assets {
  __typename: 'AssetOutput';
  id: string;
  name: string;
  site: getAssets_assets_site;
  department: string | null;
  additionalInformation: string | null;
  coordinates: getAssets_assets_coordinates | null;
  system: getAssets_assets_system;
  type: AssetType;
  owner: getAssets_assets_owner;
  drawings: getAssets_assets_drawings[];
  specificInfo: getAssets_assets_specificInfo | null;
  expireOption: boolean | false;
  expiration: string | null;
}

export interface getAssets {
  assets: getAssets_assets[];
}

export interface getAssetsVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: assetCreate
// ====================================================

export interface assetCreate_assetCreate_system {
  __typename: 'System';
  id: string;
}

export interface assetCreate_assetCreate_site {
  __typename: 'Site';
  id: string;
}

export interface assetCreate_assetCreate {
  __typename: 'AssetOutput';
  id: string;
  system: assetCreate_assetCreate_system;
  site: assetCreate_assetCreate_site;
}

export interface assetCreate {
  assetCreate: assetCreate_assetCreate;
}

export interface assetCreateVariables {
  asset: AssetCreate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: assetsCreate
// ====================================================

export interface assetsCreate_assetsCreate_system {
  __typename: 'System';
  id: string;
}

export interface assetsCreate_assetsCreate_site {
  __typename: 'Site';
  id: string;
}

export interface assetsCreate_assetsCreate {
  __typename: 'AssetOutput';
  id: string;
  system: assetsCreate_assetsCreate_system;
  site: assetsCreate_assetsCreate_site;
}

export interface assetsCreate {
  assetsCreate: assetsCreate_assetsCreate[];
}

export interface assetsCreateVariables {
  assets: AssetCreate[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: assetsUpdate
// ====================================================

export interface assetsUpdate_assetsUpdate_coordinates {
  __typename: 'AssetCoordinatesOutput';
  latitude: number;
  longitude: number;
}

export interface assetsUpdate_assetsUpdate_system {
  __typename: 'System';
  id: string;
  type: SystemType;
}

export interface assetsUpdate_assetsUpdate_site {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface assetsUpdate_assetsUpdate_owner {
  __typename: 'User';
  id: string;
  name: string;
}

export interface assetsUpdate_assetsUpdate_drawings {
  __typename: 'Drawing';
  id: string;
}

export interface assetsUpdate_assetsUpdate_specificInfo_pumps {
  __typename: 'PumpInfoOutput';
  name: string;
  pass: string;
}

export interface assetsUpdate_assetsUpdate_specificInfo {
  __typename: 'SpecificInfoOutput';
  pumps: assetsUpdate_assetsUpdate_specificInfo_pumps[];
}

export interface assetsUpdate_assetsUpdate {
  __typename: 'AssetOutput';
  id: string;
  name: string;
  department: string | null;
  additionalInformation: string | null;
  expireOption: boolean | false;
  expiration: string | null;
  coordinates: assetsUpdate_assetsUpdate_coordinates | null;
  system: assetsUpdate_assetsUpdate_system;
  type: AssetType;
  site: assetsUpdate_assetsUpdate_site;
  owner: assetsUpdate_assetsUpdate_owner;
  drawings: assetsUpdate_assetsUpdate_drawings[];
  specificInfo: assetsUpdate_assetsUpdate_specificInfo | null;
}

export interface assetsUpdate {
  assetsUpdate: assetsUpdate_assetsUpdate[];
}

export interface assetsUpdateVariables {
  assets: AssetUpdate[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: drawings
// ====================================================

export interface drawings_drawings_site {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface drawings_drawings_assets {
  __typename: 'AssetOutput';
  id: string;
}

export interface drawings_drawings_file {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface drawings_drawings {
  __typename: 'Drawing';
  id: string;
  drawingId: string;
  site: drawings_drawings_site;
  assets: drawings_drawings_assets[];
  status: DrawingStatus;
  file: drawings_drawings_file;
  systemType: SystemType;
}

export interface drawings {
  drawings: drawings_drawings[];
}

export interface drawingsVariables {
  clientId: string;
  search?: string | null;
  activeOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: drawingUpdate
// ====================================================

export interface drawingUpdate_drawingUpdate_site {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface drawingUpdate_drawingUpdate_assets {
  __typename: 'AssetOutput';
  id: string;
}

export interface drawingUpdate_drawingUpdate_file {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface drawingUpdate_drawingUpdate {
  __typename: 'Drawing';
  id: string;
  drawingId: string;
  site: drawingUpdate_drawingUpdate_site;
  assets: drawingUpdate_drawingUpdate_assets[];
  status: DrawingStatus;
  file: drawingUpdate_drawingUpdate_file;
  systemType: SystemType;
}

export interface drawingUpdate {
  drawingUpdate: drawingUpdate_drawingUpdate;
}

export interface drawingUpdateVariables {
  drawing: DrawingUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: drawingDelete
// ====================================================

export interface drawingDelete_drawingDelete_site {
  __typename: 'Site';
  id: string;
}

export interface drawingDelete_drawingDelete {
  __typename: 'Drawing';
  id: string;
  site: drawingDelete_drawingDelete_site;
}

export interface drawingDelete {
  drawingDelete: drawingDelete_drawingDelete;
}

export interface drawingDeleteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: drawingUpdateStatus
// ====================================================

export interface drawingUpdateStatus_drawingUpdateStatus_site {
  __typename: 'Site';
  id: string;
}

export interface drawingUpdateStatus_drawingUpdateStatus {
  __typename: 'Drawing';
  id: string;
  status: DrawingStatus;
  site: drawingUpdateStatus_drawingUpdateStatus_site;
}

export interface drawingUpdateStatus {
  drawingUpdateStatus: drawingUpdateStatus_drawingUpdateStatus;
}

export interface drawingUpdateStatusVariables {
  id: string;
  isActive: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: contractorRequests
// ====================================================

export interface contractorRequests_contractorRequests_contractor {
  __typename: 'Contractor';
  id: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  status: ContractorStatus;
  expertise: Expertise[];
  rating: number | null;
  address: string;
}

export interface contractorRequests_contractorRequests {
  __typename: 'ContractorRequestOutput';
  id: string;
  contractor: contractorRequests_contractorRequests_contractor;
  accessStatus: ContractorRequestAccessStatus;
}

export interface contractorRequests {
  contractorRequests: contractorRequests_contractorRequests[];
}

export interface contractorRequestsVariables {
  clientId: string;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getContractorsForClient
// ====================================================

export interface getContractorsForClient_contractors_requests_client {
  __typename: 'Client';
  companyName: string;
  id: string;
}

export interface getContractorsForClient_contractors_requests {
  __typename: 'ContractorRequestOutput';
  id: string;
  client: getContractorsForClient_contractors_requests_client;
}

export interface getContractorsForClient_contractors {
  __typename: 'Contractor';
  id: string;
  companyName: string;
  contactName: string;
  address: string;
  status: ContractorStatus;
  expertise: Expertise[];
  rating: number | null;
  requests: getContractorsForClient_contractors_requests[];
}

export interface getContractorsForClient {
  contractors: getContractorsForClient_contractors[];
}

export interface getContractorsForClientVariables {
  search?: string | null;
  archived?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: assetStatusUpdate
// ====================================================

export interface assetStatusUpdate_assetStatusUpdate_site {
  __typename: 'Site';
  id: string;
}

export interface assetStatusUpdate_assetStatusUpdate {
  __typename: 'AssetOutput';
  id: string;
  status: AssetStatus;
  site: assetStatusUpdate_assetStatusUpdate_site;
}

export interface assetStatusUpdate {
  assetStatusUpdate: assetStatusUpdate_assetStatusUpdate;
}

export interface assetStatusUpdateVariables {
  id: string;
  status: AssetStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientSites
// ====================================================

export interface getClientSites_client_sites {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface getClientSites_client {
  __typename: 'Client';
  id: string;
  sites: getClientSites_client_sites[];
  allowedSystems: SystemType[] | null;
}

export interface getClientSites {
  client: getClientSites_client;
}

export interface getClientSitesVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: systemCreate
// ====================================================

export interface systemCreate_systemCreate {
  __typename: 'System';
  id: string;
}

export interface systemCreate {
  systemCreate: systemCreate_systemCreate;
}

export interface systemCreateVariables {
  siteId: string;
  system: SystemCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSystems
// ====================================================

export interface getSystems_client_sites {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface getSystems_client_systems_site {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface getSystems_client_systems {
  __typename: 'System';
  id: string;
  name: string;
  type: SystemType;
  numberOfAssets: number;
  site: getSystems_client_systems_site;
}

export interface getSystems_client {
  __typename: 'Client';
  id: string;
  sites: getSystems_client_sites[];
  allowedSystems: SystemType[] | null;
  systems: getSystems_client_systems[];
}

export interface getSystems {
  client: getSystems_client;
}

export interface getSystemsVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSystem
// ====================================================

export interface getSystem_system_site {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface getSystem_system {
  __typename: 'System';
  id: string;
  name: string;
  type: SystemType;
  site: getSystem_system_site;
}

export interface getSystem {
  system: getSystem_system;
}

export interface getSystemVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: systemUpdate
// ====================================================

export interface systemUpdate_systemUpdate {
  __typename: 'System';
  id: string;
  name: string;
}

export interface systemUpdate {
  systemUpdate: systemUpdate_systemUpdate;
}

export interface systemUpdateVariables {
  id: string;
  system: SystemUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: contractorRequestStatus
// ====================================================

export interface contractorRequestStatus_contractor_request {
  __typename: 'ContractorRequestOutput';
  id: string;
  accessStatus: ContractorRequestAccessStatus;
}

export interface contractorRequestStatus_contractor {
  __typename: 'Contractor';
  id: string;
  request: contractorRequestStatus_contractor_request | null;
}

export interface contractorRequestStatus {
  contractor: contractorRequestStatus_contractor;
}

export interface contractorRequestStatusVariables {
  id: string;
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userUpdateStatus
// ====================================================

export interface userUpdateStatus_userUpdateStatus {
  __typename: 'User';
  id: string;
  active: boolean;
}

export interface userUpdateStatus {
  userUpdateStatus: userUpdateStatus_userUpdateStatus;
}

export interface userUpdateStatusVariables {
  id: string;
  isActive: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientRequests
// ====================================================

export interface getClientRequests_client_requests_contractor {
  __typename: 'Contractor';
  id: string;
}

export interface getClientRequests_client_requests {
  __typename: 'ContractorRequestOutput';
  id: string;
  contractor: getClientRequests_client_requests_contractor;
  accessStatus: ContractorRequestAccessStatus;
}

export interface getClientRequests_client {
  __typename: 'Client';
  id: string;
  requests: getClientRequests_client_requests[];
}

export interface getClientRequests {
  client: getClientRequests_client;
}

export interface getClientRequestsVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: contractorRequest
// ====================================================

export interface contractorRequest_contractorRequest_contractor_requests_client {
  __typename: 'Client';
  id: string;
}

export interface contractorRequest_contractorRequest_contractor_requests {
  __typename: 'ContractorRequestOutput';
  id: string;
  client: contractorRequest_contractorRequest_contractor_requests_client;
}

export interface contractorRequest_contractorRequest_contractor {
  __typename: 'Contractor';
  id: string;
  requests: contractorRequest_contractorRequest_contractor_requests[];
}

export interface contractorRequest_contractorRequest_client {
  __typename: 'Client';
  id: string;
}

export interface contractorRequest_contractorRequest {
  __typename: 'ContractorRequestOutput';
  id: string;
  contractor: contractorRequest_contractorRequest_contractor;
  client: contractorRequest_contractorRequest_client;
}

export interface contractorRequest {
  contractorRequest: contractorRequest_contractorRequest;
}

export interface contractorRequestVariables {
  contractorId: string;
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeRequest
// ====================================================

export interface removeRequest_removeRequest_contractor_requests_client {
  __typename: 'Client';
  id: string;
}

export interface removeRequest_removeRequest_contractor_requests {
  __typename: 'ContractorRequestOutput';
  id: string;
  client: removeRequest_removeRequest_contractor_requests_client;
}

export interface removeRequest_removeRequest_contractor {
  __typename: 'Contractor';
  id: string;
  requests: removeRequest_removeRequest_contractor_requests[];
}

export interface removeRequest_removeRequest_client {
  __typename: 'Client';
  id: string;
}

export interface removeRequest_removeRequest {
  __typename: 'ContractorRequestOutput';
  contractor: removeRequest_removeRequest_contractor;
  client: removeRequest_removeRequest_client;
}

export interface removeRequest {
  removeRequest: removeRequest_removeRequest;
}

export interface removeRequestVariables {
  contractorId: string;
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userCreate
// ====================================================

export interface userCreate_userCreate {
  __typename: 'User';
  id: string;
}

export interface userCreate {
  userCreate: userCreate_userCreate;
}

export interface userCreateVariables {
  companyId: string;
  user: UserCreate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userUpdate
// ====================================================

export interface userUpdate_userUpdate_sites {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface userUpdate_userUpdate_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface userUpdate_userUpdate {
  __typename: 'User';
  id: string;
  name: string;
  email: string;
  phonenumber: string;
  role: Role;
  isAdmin: boolean;
  fullAccess: boolean;
  adhoc: boolean;
  sites: userUpdate_userUpdate_sites[] | null;
  picture: userUpdate_userUpdate_picture | null;
}

export interface userUpdate {
  userUpdate: userUpdate_userUpdate;
}

export interface userUpdateVariables {
  id: string;
  user: UserUpdate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: user
// ====================================================

export interface user_user_sites {
  __typename: 'Site';
  id: string;
}

export interface user_user_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface user_user_company {
  __typename: 'Company';
  id: string;
  name: string;
}

export interface user_user {
  __typename: 'User';
  id: string;
  name: string;
  email: string;
  phonenumber: string;
  role: Role;
  isAdmin: boolean;
  fullAccess: boolean;
  adhoc: boolean;
  notificationSchedule: NotificationScheduleType[];
  notificationStatus: TaskStatus[];
  sites: user_user_sites[] | null;
  picture: user_user_picture | null;
  company: user_user_company | null;
  isconsultant: boolean;
}

export interface user {
  user: user_user;
}

export interface userVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userRequest
// ====================================================

export interface userRequest_userRequest_clients {
  __typename: 'Client';
  id: string;
}

export interface userRequest_userRequest {
  __typename: 'User';
  id: string;
  clients: userRequest_userRequest_clients[];
}

export interface userRequest {
  userRequest: userRequest_userRequest;
}

export interface userRequestVariables {
  userId: string;
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userAcceptRequest
// ====================================================

export interface userAcceptRequest_userAcceptRequest {
  __typename: 'UserRequest';
  id: string;
  accessStatus: UserRequestAccessStatus;
}

export interface userAcceptRequest {
  userAcceptRequest: userAcceptRequest_userAcceptRequest;
}

export interface userAcceptRequestVariables {
  userId: string;
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userDenyRequest
// ====================================================

export interface userDenyRequest_userDenyRequest {
  __typename: 'UserRequest';
  id: string;
  accessStatus: UserRequestAccessStatus;
}

export interface userDenyRequest {
  userDenyRequest: userDenyRequest_userDenyRequest;
}

export interface userDenyRequestVariables {
  userId: string;
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userRemoveRequest
// ====================================================

export interface userRemoveRequest_userRemoveRequest_clients {
  __typename: 'Client';
  id: string;
}

export interface userRemoveRequest_userRemoveRequest {
  __typename: 'User';
  id: string;
  clients: userRemoveRequest_userRemoveRequest_clients[];
}

export interface userRemoveRequest {
  userRemoveRequest: userRemoveRequest_userRemoveRequest;
}

export interface userRemoveRequestVariables {
  userId: string;
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: drawing
// ====================================================

export interface drawing_drawing_site {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface drawing_drawing_file {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface drawing_drawing_assets_system {
  __typename: 'System';
  id: string;
  type: SystemType;
}

export interface drawing_drawing_assets {
  __typename: 'AssetOutput';
  id: string;
  system: drawing_drawing_assets_system;
  name: string;
  type: AssetType;
}

export interface drawing_drawing {
  __typename: 'Drawing';
  id: string;
  drawingId: string;
  site: drawing_drawing_site;
  status: DrawingStatus;
  file: drawing_drawing_file;
  systemType: SystemType;
  rotation: number | null;
  assets: drawing_drawing_assets[];
}

export interface drawing {
  drawing: drawing_drawing;
}

export interface drawingVariables {
  drawingId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: clientAssets
// ====================================================

export interface clientAssets_client_assets_site {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface clientAssets_client_assets_upcomingWorks {
  __typename: 'Task';
  id: string;
  type: TaskType;
  deadline: string | null;
  idReadable: string | null;
}

export interface clientAssets_client_assets_recentWorks {
  __typename: 'Task';
  id: string;
  type: TaskType;
  deadline: string | null;
  idReadable: string | null;
}

export interface clientAssets_client_assets_system {
  __typename: 'System';
  id: string;
  name: string;
  type: SystemType;
}

export interface clientAssets_client_assets_owner_picture {
  __typename: 'File';
  srcUrl: string;
}

export interface clientAssets_client_assets_owner {
  __typename: 'User';
  id: string;
  name: string;
  picture: clientAssets_client_assets_owner_picture | null;
}

export interface clientAssets_client_assets_coordinates {
  __typename: 'AssetCoordinatesOutput';
  longitude: number;
  latitude: number;
}

export interface clientAssets_client_assets_drawings_file {
  __typename: 'File';
  id: string;
  originalName: string;
  extension: string;
}

export interface clientAssets_client_assets_drawings {
  __typename: 'Drawing';
  id: string;
  drawingId: string;
  file: clientAssets_client_assets_drawings_file;
}

export interface clientAssets_client_assets {
  __typename: 'AssetOutput';
  id: string;
  site: clientAssets_client_assets_site;
  upcomingWorks: clientAssets_client_assets_upcomingWorks[] | null;
  recentWorks: clientAssets_client_assets_recentWorks[] | null;
  department: string | null;
  system: clientAssets_client_assets_system;
  name: string;
  type: AssetType;
  createdAt: any;
  owner: clientAssets_client_assets_owner;
  status: AssetStatus;
  coordinates: clientAssets_client_assets_coordinates | null;
  additionalInformation: string | null;
  drawings: clientAssets_client_assets_drawings[];
  expireOption: boolean | false;
  expiration: string;
}

export interface clientAssets_client {
  __typename: 'Client';
  id: string;
  assets: clientAssets_client_assets[];
}

export interface clientAssets {
  client: clientAssets_client;
}

export interface clientAssetsVariables {
  id: string;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getContractors
// ====================================================

export interface getContractors_contractors {
  __typename: 'Contractor';
  id: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  address: string;
  status: ContractorStatus;
  expertise: Expertise[];
  rating: number | null;
}

export interface getContractors {
  contractors: getContractors_contractors[];
}

export interface getContractorsVariables {
  search?: string | null;
  archived?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getContractor
// ====================================================

export interface getContractor_contractor_logo {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface getContractor_contractor {
  __typename: 'Contractor';
  id: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  status: ContractorStatus;
  expertise: Expertise[];
  rating: number | null;
  logo: getContractor_contractor_logo | null;
}

export interface getContractor {
  contractor: getContractor_contractor;
}

export interface getContractorVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getContractorForClient
// ====================================================

export interface getContractorForClient_contractor_logo {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface getContractorForClient_contractor_request_sites {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface getContractorForClient_contractor_request_workOrders {
  __typename: 'WorkOrderOutput';
  id: string;
  expiracy: string;
}

export interface getContractorForClient_contractor_request_manager {
  __typename: 'User';
  id: string;
  name: string;
}

export interface getContractorForClient_contractor_request {
  __typename: 'ContractorRequestOutput';
  id: string;
  accessStatus: ContractorRequestAccessStatus;
  sites: getContractorForClient_contractor_request_sites[];
  systemTypes: SystemType[] | null;
  contractNumber: string | null;
  contractExpiracy: string | null;
  workOrders: getContractorForClient_contractor_request_workOrders[] | null;
  manager: getContractorForClient_contractor_request_manager | null;
  twoFactorAuth: boolean | null;
  customMessage: string | null;
}

export interface getContractorForClient_contractor {
  __typename: 'Contractor';
  id: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  status: ContractorStatus;
  expertise: Expertise[];
  rating: number | null;
  logo: getContractorForClient_contractor_logo | null;
  request: getContractorForClient_contractor_request | null;
}

export interface getContractorForClient {
  contractor: getContractorForClient_contractor;
}

export interface getContractorForClientVariables {
  contractorId: string;
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: clientSites
// ====================================================

export interface clientSites_client_sites {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface clientSites_client {
  __typename: 'Client';
  id: string;
  sites: clientSites_client_sites[];
}

export interface clientSites {
  client: clientSites_client;
}

export interface clientSitesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userSites
// ====================================================

export interface userSites_user_sites {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface userSites_user {
  __typename: 'User';
  id: string;
  sites: userSites_user_sites[] | null;
}

export interface userSites {
  user: userSites_user;
}

export interface userSitesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: taskClientComments
// ====================================================

export interface taskClientComments_task_commentsWithArchived_author_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface taskClientComments_task_commentsWithArchived_author {
  __typename: 'User';
  id: string;
  name: string;
  companyName: string;
  picture: taskClientComments_task_commentsWithArchived_author_picture | null;
}

export interface taskClientComments_task_commentsWithArchived {
  __typename: 'TaskComment';
  id: string;
  message: string;
  createdAt: any;
  archived: boolean;
  author: taskClientComments_task_commentsWithArchived_author;
}

export interface taskClientComments_task {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
  commentsWithArchived: taskClientComments_task_commentsWithArchived[];
}

export interface taskClientComments {
  task: taskClientComments_task;
}

export interface taskClientCommentsVariables {
  taskId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: taskContractorComments
// ====================================================

export interface taskContractorComments_task_comments_author_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface taskContractorComments_task_comments_author {
  __typename: 'User';
  id: string;
  name: string;
  companyName: string;
  picture: taskContractorComments_task_comments_author_picture | null;
}

export interface taskContractorComments_task_comments {
  __typename: 'TaskComment';
  id: string;
  message: string;
  createdAt: any;
  archived: boolean;
  author: taskContractorComments_task_comments_author;
}

export interface taskContractorComments_task {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
  comments: taskContractorComments_task_comments[];
}

export interface taskContractorComments {
  task: taskContractorComments_task;
}

export interface taskContractorCommentsVariables {
  taskId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskClientComment
// ====================================================

export interface taskClientComment_taskComment_commentsWithArchived_author_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface taskClientComment_taskComment_commentsWithArchived_author {
  __typename: 'User';
  id: string;
  name: string;
  companyName: string;
  picture: taskClientComment_taskComment_commentsWithArchived_author_picture | null;
}

export interface taskClientComment_taskComment_commentsWithArchived {
  __typename: 'TaskComment';
  id: string;
  message: string;
  createdAt: any;
  archived: boolean;
  author: taskClientComment_taskComment_commentsWithArchived_author;
}

export interface taskClientComment_taskComment {
  __typename: 'Task';
  id: string;
  commentsWithArchived: taskClientComment_taskComment_commentsWithArchived[];
}

export interface taskClientComment {
  taskComment: taskClientComment_taskComment;
}

export interface taskClientCommentVariables {
  taskId: string;
  message: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskContractorComment
// ====================================================

export interface taskContractorComment_taskComment_comments_author_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface taskContractorComment_taskComment_comments_author {
  __typename: 'User';
  id: string;
  name: string;
  companyName: string;
  picture: taskContractorComment_taskComment_comments_author_picture | null;
}

export interface taskContractorComment_taskComment_comments {
  __typename: 'TaskComment';
  id: string;
  message: string;
  createdAt: any;
  archived: boolean;
  author: taskContractorComment_taskComment_comments_author;
}

export interface taskContractorComment_taskComment {
  __typename: 'Task';
  id: string;
  comments: taskContractorComment_taskComment_comments[];
}

export interface taskContractorComment {
  taskComment: taskContractorComment_taskComment;
}

export interface taskContractorCommentVariables {
  taskId: string;
  message: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: logs
// ====================================================

export interface logs_logs_user_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface logs_logs_user {
  __typename: 'User';
  id: string;
  name: string;
  companyName: string;
  picture: logs_logs_user_picture | null;
}

export interface logs_logs {
  __typename: 'Log';
  id: string;
  entityType: string;
  logType: LogType;
  dataId: string;
  gqlOperation: string;
  user: logs_logs_user;
  createdAt: any;
}

export interface logs {
  logs: logs_logs[];
}

export interface logsVariables {
  dataId?: string | null;
  gqlOperation?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateContractor
// ====================================================

export interface updateContractor_contractorUpdate_logo {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface updateContractor_contractorUpdate {
  __typename: 'Contractor';
  id: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  logo: updateContractor_contractorUpdate_logo | null;
  expertise: Expertise[];
}

export interface updateContractor {
  contractorUpdate: updateContractor_contractorUpdate;
}

export interface updateContractorVariables {
  id: string;
  changes: ContractorDetails;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createContractor
// ====================================================

export interface createContractor_contractorCreate {
  __typename: 'Contractor';
  id: string;
}

export interface createContractor {
  contractorCreate: createContractor_contractorCreate;
}

export interface createContractorVariables {
  contractor: ContractorDetails;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTaskAssetDrawings
// ====================================================

export interface getTaskAssetDrawings_taskAsset_asset_drawings_file {
  __typename: 'File';
  id: string;
  originalName: string;
  extension: string;
}

export interface getTaskAssetDrawings_taskAsset_asset_drawings {
  __typename: 'Drawing';
  id: string;
  file: getTaskAssetDrawings_taskAsset_asset_drawings_file;
}

export interface getTaskAssetDrawings_taskAsset_asset {
  __typename: 'AssetOutput';
  id: string;
  drawings: getTaskAssetDrawings_taskAsset_asset_drawings[];
}

export interface getTaskAssetDrawings_taskAsset_task_assets_task {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
  type: TaskType;
}

export interface getTaskAssetDrawings_taskAsset_task_assets_asset {
  __typename: 'AssetOutput';
  id: string;
  name: string;
  type: AssetType;
}

export interface getTaskAssetDrawings_taskAsset_task_assets {
  __typename: 'TaskAsset';
  id: string;
  task: getTaskAssetDrawings_taskAsset_task_assets_task;
  asset: getTaskAssetDrawings_taskAsset_task_assets_asset;
}

export interface getTaskAssetDrawings_taskAsset_task {
  __typename: 'Task';
  id: string;
  adhoc: boolean;
  assets: getTaskAssetDrawings_taskAsset_task_assets[];
}

export interface getTaskAssetDrawings_taskAsset {
  __typename: 'TaskAsset';
  id: string;
  asset: getTaskAssetDrawings_taskAsset_asset;
  task: getTaskAssetDrawings_taskAsset_task;
}

export interface getTaskAssetDrawings {
  taskAsset: getTaskAssetDrawings_taskAsset;
}

export interface getTaskAssetDrawingsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rotateDrawing
// ====================================================

export interface rotateDrawing_drawingRotate {
  __typename: 'Drawing';
  id: string;
}

export interface rotateDrawing {
  drawingRotate: rotateDrawing_drawingRotate;
}

export interface rotateDrawingVariables {
  id: string;
  rotation: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: taskReport
// ====================================================

export interface taskReport_task_contractorResponsibleUser {
  __typename: 'User';
  id: string;
  name: string;
}

export interface taskReport_task_internalResponsible {
  __typename: 'User';
  id: string;
  name: string;
}

export interface taskReport_task_assets_asset_coordinates {
  __typename: 'AssetCoordinatesOutput';
  longitude: number;
  latitude: number;
}

export interface taskReport_task_assets_asset_system {
  __typename: 'System';
  id: string;
  type: SystemType;
  name: string;
}

export interface taskReport_task_assets_asset_owner {
  __typename: 'User';
  id: string;
  name: string;
}

export interface taskReport_task_assets_asset_site {
  __typename: 'Site';
  id: string;
  name: string;
}

export interface taskReport_task_assets_asset {
  __typename: 'AssetOutput';
  id: string;
  name: string;
  type: AssetType;
  createdAt: any;
  department: string | null;
  additionalInformation: string | null;
  coordinates: taskReport_task_assets_asset_coordinates | null;
  system: taskReport_task_assets_asset_system;
  owner: taskReport_task_assets_asset_owner;
  site: taskReport_task_assets_asset_site;
}

export interface taskReport_task_assets_formTemplate_fileMultiTemplate {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface taskReport_task_assets_formTemplate_fileSingleTemplate {
  __typename: 'File';
  id: string;
  originalName: string;
}

export interface taskReport_task_assets_formTemplate {
  allowMultiReportPdf: boolean;
  allowSingleReportPdf: boolean;
  __typename: 'FormTemplate';
  id: string;
  content: string;
  fileMultiTemplate: taskReport_task_assets_formTemplate_fileMultiTemplate | null;
  fileSingleTemplate: taskReport_task_assets_formTemplate_fileSingleTemplate | null;
}

export interface taskReport_task_assets {
  __typename: 'TaskAsset';
  id: string;
  status: TaskStatus;
  asset: taskReport_task_assets_asset;
  formValues: string | null;
  formTemplate: taskReport_task_assets_formTemplate;
}

export interface taskReport_task {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
  idReadable: string;
  tasktitle: string;
  type: TaskType;
  deadline: string;
  contractorResponsibleUser: taskReport_task_contractorResponsibleUser | null;
  internalResponsible: taskReport_task_internalResponsible | null;
  assets: taskReport_task_assets[];
}

export interface taskReport {
  task: taskReport_task;
}

export interface taskReportVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: contractorUsers
// ====================================================

export interface contractorUsers_contractor_users_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface contractorUsers_contractor_users_clients {
  __typename: 'Client';
  id: string;
}

export interface contractorUsers_contractor_users {
  __typename: 'User';
  id: string;
  name: string;
  email: string;
  phonenumber: string;
  role: Role;
  active: boolean;
  picture: contractorUsers_contractor_users_picture | null;
  clients: contractorUsers_contractor_users_clients[];
}

export interface contractorUsers_contractor_requests_client {
  __typename: 'Client';
  id: string;
  companyName: string;
}

export interface contractorUsers_contractor_requests_userRequests_user_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface contractorUsers_contractor_requests_userRequests_user {
  __typename: 'User';
  id: string;
  name: string;
  email: string;
  phonenumber: string;
  role: Role;
  active: boolean;
  picture: contractorUsers_contractor_requests_userRequests_user_picture | null;
}

export interface contractorUsers_contractor_requests_userRequests {
  __typename: 'UserRequest';
  id: string;
  accessStatus: UserRequestAccessStatus;
  user: contractorUsers_contractor_requests_userRequests_user;
}

export interface contractorUsers_contractor_requests {
  __typename: 'ContractorRequestOutput';
  id: string;
  accessStatus: ContractorRequestAccessStatus;
  client: contractorUsers_contractor_requests_client;
  userRequests: contractorUsers_contractor_requests_userRequests[];
}

export interface contractorUsers_contractor {
  __typename: 'Contractor';
  id: string;
  companyName: string;
  users: contractorUsers_contractor_users[];
  requests: contractorUsers_contractor_requests[];
}

export interface contractorUsers {
  contractor: contractorUsers_contractor;
}

export interface contractorUsersVariables {
  id: string;
  search?: string | null;
  archived?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: contractorRequestedUsers
// ====================================================

export interface contractorRequestedUsers_contractor_request_userRequests_user_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface contractorRequestedUsers_contractor_request_userRequests_user {
  __typename: 'User';
  id: string;
  name: string;
  email: string;
  phonenumber: string;
  role: Role;
  active: boolean;
  picture: contractorRequestedUsers_contractor_request_userRequests_user_picture | null;
}

export interface contractorRequestedUsers_contractor_request_userRequests {
  __typename: 'UserRequest';
  id: string;
  accessStatus: UserRequestAccessStatus;
  user: contractorRequestedUsers_contractor_request_userRequests_user;
}

export interface contractorRequestedUsers_contractor_request {
  __typename: 'ContractorRequestOutput';
  id: string;
  accessStatus: ContractorRequestAccessStatus;
  userRequests: contractorRequestedUsers_contractor_request_userRequests[];
}

export interface contractorRequestedUsers_contractor {
  __typename: 'Contractor';
  id: string;
  request: contractorRequestedUsers_contractor_request | null;
}

export interface contractorRequestedUsers {
  contractor: contractorRequestedUsers_contractor;
}

export interface contractorRequestedUsersVariables {
  contractorId: string;
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskResponsibleAccept
// ====================================================

export interface taskResponsibleAccept_taskResponsibleAccept_comments_author_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface taskResponsibleAccept_taskResponsibleAccept_comments_author {
  __typename: 'User';
  id: string;
  name: string;
  companyName: string;
  picture: taskResponsibleAccept_taskResponsibleAccept_comments_author_picture | null;
}

export interface taskResponsibleAccept_taskResponsibleAccept_comments {
  __typename: 'TaskComment';
  id: string;
  message: string;
  createdAt: any;
  archived: boolean;
  author: taskResponsibleAccept_taskResponsibleAccept_comments_author;
}

export interface taskResponsibleAccept_taskResponsibleAccept {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
  comments: taskResponsibleAccept_taskResponsibleAccept_comments[];
}

export interface taskResponsibleAccept {
  taskResponsibleAccept: taskResponsibleAccept_taskResponsibleAccept;
}

export interface taskResponsibleAcceptVariables {
  taskId: string;
  comment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskResponsibleReject
// ====================================================

export interface taskResponsibleReject_taskResponsibleReject_comments_author_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface taskResponsibleReject_taskResponsibleReject_comments_author {
  __typename: 'User';
  id: string;
  name: string;
  companyName: string;
  picture: taskResponsibleReject_taskResponsibleReject_comments_author_picture | null;
}

export interface taskResponsibleReject_taskResponsibleReject_comments {
  __typename: 'TaskComment';
  id: string;
  message: string;
  createdAt: any;
  archived: boolean;
  author: taskResponsibleReject_taskResponsibleReject_comments_author;
}

export interface taskResponsibleReject_taskResponsibleReject {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
  comments: taskResponsibleReject_taskResponsibleReject_comments[];
}

export interface taskResponsibleReject {
  taskResponsibleReject: taskResponsibleReject_taskResponsibleReject;
}

export interface taskResponsibleRejectVariables {
  taskId: string;
  comment: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: notificationsToMe
// ====================================================

export interface notificationsToMe_notificationsToMe_nodes_payload {
  __typename: 'NotificationPayloadOutput';
  entityId: string | null;
  siteId: string | null;
  name: string | null;
  company: string | null;
  date: string | null;
}

export interface notificationsToMe_notificationsToMe_nodes {
  __typename: 'NotificationOutput';
  id: string;
  read: boolean;
  createdAt: any;
  deletedAt: any | null;
  type: NotificationType;
  payload: notificationsToMe_notificationsToMe_nodes_payload | null;
}

export interface notificationsToMe_notificationsToMe {
  __typename: 'PaginatedNotificationOutput';
  totalNodes: number;
  totalPages: number;
  page: number;
  nodes: notificationsToMe_notificationsToMe_nodes[];
}

export interface notificationsToMe {
  notificationsToMe: notificationsToMe_notificationsToMe;
}

export interface notificationsToMeVariables {
  pagination?: PaginationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: notificationSetToRead
// ====================================================

export interface notificationSetToRead_notificationSetToRead {
  __typename: 'NotificationOutput';
  id: string;
  read: boolean;
  deletedAt: any | null;
}

export interface notificationSetToRead {
  notificationSetToRead: notificationSetToRead_notificationSetToRead;
}

export interface notificationSetToReadVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: notificationDelete
// ====================================================

export interface notificationDelete_notificationDelete {
  __typename: 'NotificationOutput';
  id: string;
  read: boolean;
  deletedAt: any | null;
}

export interface notificationDelete {
  notificationDelete: notificationDelete_notificationDelete;
}

export interface notificationDeleteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: taskForUser
// ====================================================

export interface taskForUser_task {
  __typename: 'Task';
  id: string;
  type: TaskType;
  status: TaskStatus;
}

export interface taskForUser {
  task: taskForUser_task;
}

export interface taskForUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: taskTimerForUser
// ====================================================

export interface taskTimerForUser_task_timer {
  __typename: 'TaskTimerOutput';
  timeSpent: number;
  onGoing: boolean;
}

export interface taskTimerForUser_task {
  __typename: 'Task';
  id: string;
  timer: taskTimerForUser_task_timer;
}

export interface taskTimerForUser {
  task: taskTimerForUser_task;
}

export interface taskTimerForUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskStart
// ====================================================

export interface taskStart_taskStart_timer {
  __typename: 'TaskTimerOutput';
  timeSpent: number;
  onGoing: boolean;
}

export interface taskStart_taskStart {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
  timer: taskStart_taskStart_timer;
}

export interface taskStart {
  taskStart: taskStart_taskStart;
}

export interface taskStartVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskComplete
// ====================================================

export interface taskComplete_taskComplete_timer {
  __typename: 'TaskTimerOutput';
  timeSpent: number;
  onGoing: boolean;
}

export interface taskComplete_taskComplete {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
  timer: taskComplete_taskComplete_timer;
}

export interface taskComplete {
  taskComplete: taskComplete_taskComplete;
}

export interface taskCompleteVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskSubmitSuppInfo
// ====================================================

export interface taskSubmitSuppInfo_taskSubmitSuppInfo {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
}

export interface taskSubmitSuppInfo {
  taskSubmitSuppInfo: taskSubmitSuppInfo_taskSubmitSuppInfo;
}

export interface taskSubmitSuppInfoVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskTimerPause
// ====================================================

export interface taskTimerPause_taskTimerPause_timer {
  __typename: 'TaskTimerOutput';
  timeSpent: number;
  onGoing: boolean;
}

export interface taskTimerPause_taskTimerPause {
  __typename: 'Task';
  id: string;
  timer: taskTimerPause_taskTimerPause_timer;
}

export interface taskTimerPause {
  taskTimerPause: taskTimerPause_taskTimerPause;
}

export interface taskTimerPauseVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskTimerResume
// ====================================================

export interface taskTimerResume_taskTimerResume_timer {
  __typename: 'TaskTimerOutput';
  timeSpent: number;
  onGoing: boolean;
}

export interface taskTimerResume_taskTimerResume {
  __typename: 'Task';
  id: string;
  timer: taskTimerResume_taskTimerResume_timer;
}

export interface taskTimerResume {
  taskTimerResume: taskTimerResume_taskTimerResume;
}

export interface taskTimerResumeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: taskDetails
// ====================================================

export interface taskDetails_task_approval {
  __typename: 'User';
  companyName: string;
  name: string;
  phonenumber: string;
}

export interface taskDetails_task_site {
  __typename: 'Site';
  id: string;
  name: string;
  address: string;
}

export interface taskDetails_task {
  __typename: 'Task';
  deadline: string | null;
  documentDeadline: string | null;
  contractorResponsibleWorkOrderId: string | null;
  id: string;
  idReadable: string;
  urgent: boolean | null;
  tasktitle: string | null;
  subform: string;
  notes: string | null;
  reOccurenceType: ReOccurenceType;
  approval: taskDetails_task_approval | null;
  site: taskDetails_task_site;
  status: TaskStatus;
}

export interface taskDetails {
  task: taskDetails_task;
}

export interface taskDetailsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: taskAttachments
// ====================================================

export interface taskAttachments_task_attachments {
  __typename: 'File';
  id: string;
  extension: string;
  originalName: string;
}

export interface taskAttachments_task {
  __typename: 'Task';
  id: string;
  status: TaskStatus;
  attachments: taskAttachments_task_attachments[];
}

export interface taskAttachments {
  task: taskAttachments_task;
}

export interface taskAttachmentsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: taskAssets
// ====================================================

export interface taskAssets_task_assets_asset_system {
  __typename: 'System';
  id: string;
  name: string;
  type: SystemType;
}

export interface taskAssets_task_assets_asset_drawings {
  __typename: 'Drawing';
  id: string;
}

export interface taskAssets_task_assets_asset {
  __typename: 'AssetOutput';
  id: string;
  name: string;
  type: AssetType;
  system: taskAssets_task_assets_asset_system;
  drawings: taskAssets_task_assets_asset_drawings[];
}

export interface taskAssets_task_assets {
  __typename: 'TaskAsset';
  id: string;
  status: TaskAssetStatus;
  compliant: boolean | null;
  asset: taskAssets_task_assets_asset;
}

export interface taskAssets_task {
  __typename: 'Task';
  id: string;
  type: TaskType;
  adhoc: boolean;
  assets: taskAssets_task_assets[];
}

export interface taskAssets {
  task: taskAssets_task;
}

export interface taskAssetsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskAttachFile
// ====================================================

export interface taskAttachFile_taskAttachFile_attachments {
  __typename: 'File';
  id: string;
  extension: string;
  originalName: string;
}

export interface taskAttachFile_taskAttachFile {
  __typename: 'Task';
  id: string;
  attachments: taskAttachFile_taskAttachFile_attachments[];
}

export interface taskAttachFile {
  taskAttachFile: taskAttachFile_taskAttachFile;
}

export interface taskAttachFileVariables {
  taskId: string;
  fileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: taskRemoveAttachment
// ====================================================

export interface taskRemoveAttachment_taskRemoveAttachment {
  __typename: 'Task';
  id: string;
}

export interface taskRemoveAttachment {
  taskRemoveAttachment: taskRemoveAttachment_taskRemoveAttachment;
}

export interface taskRemoveAttachmentVariables {
  taskId: string;
  fileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTaskAssetForm
// ====================================================

export interface getTaskAssetForm_taskAsset_asset {
  __typename: 'AssetOutput';
  id: string;
  name: string;
  type: AssetType;
}

export interface getTaskAssetForm_taskAsset_formTemplate {
  __typename: 'FormTemplate';
  id: string;
  content: string;
}

export interface getTaskAssetForm_taskAsset {
  __typename: 'TaskAsset';
  id: string;
  asset: getTaskAssetForm_taskAsset_asset;
  status: TaskAssetStatus;
  formTemplate: getTaskAssetForm_taskAsset_formTemplate;
  formValues: string | null;
}

export interface getTaskAssetForm {
  taskAsset: getTaskAssetForm_taskAsset;
}

export interface getTaskAssetFormVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateTaskAssetForm
// ====================================================

export interface updateTaskAssetForm_taskAssetFormValuesUpdate {
  __typename: 'TaskAsset';
  id: string;
  status: TaskAssetStatus;
  formValues: string | null;
  compliant: boolean | null;
}

export interface updateTaskAssetForm {
  taskAssetFormValuesUpdate: updateTaskAssetForm_taskAssetFormValuesUpdate;
}

export interface updateTaskAssetFormVariables {
  id: string;
  formValues: string;
  draft?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: assetsForUser
// ====================================================

export interface assetsForUser_useraAssets_system {
  __typename: 'System';
  id: string;
  type: SystemType;
  name: string;
}

export interface assetsForUser_useraAssets_owner_picture {
  __typename: 'File';
  srcUrl: string;
}

export interface assetsForUser_useraAssets_owner {
  __typename: 'User';
  id: string;
  name: string;
  picture: assetsForUser_useraAssets_owner_picture | null;
}

export interface assetsForUser_useraAssets_coordinates {
  __typename: 'AssetCoordinatesOutput';
  longitude: number;
  latitude: number;
}

export interface assetsForUser_useraAssets_drawings_file {
  __typename: 'File';
  id: string;
  originalName: string;
  extension: string;
}

export interface assetsForUser_useraAssets_drawings {
  __typename: 'Drawing';
  id: string;
  drawingId: string;
  file: assetsForUser_useraAssets_drawings_file;
}

export interface assetsForUser_useraAssets_upcomingWorks {
  __typename: 'Task';
  id: string;
  type: TaskType;
}

export interface assetsForUser_useraAssets_recentWorks {
  __typename: 'Task';
  id: string;
  type: TaskType;
}

export interface assetsForUser_useraAssets {
  __typename: 'AssetOutput';
  id: string;
  department: string | null;
  system: assetsForUser_useraAssets_system;
  name: string;
  type: AssetType;
  createdAt: any;
  owner: assetsForUser_useraAssets_owner;
  status: AssetStatus;
  coordinates: assetsForUser_useraAssets_coordinates | null;
  additionalInformation: string | null;
  expireOption: boolean | false;
  expiration: string | null;
  drawings: assetsForUser_useraAssets_drawings[];
  upcomingWorks: assetsForUser_useraAssets_upcomingWorks[] | null;
  recentWorks: assetsForUser_useraAssets_recentWorks[] | null;
}

export interface assetsForUser_paginated {
  nodes: assetsForUser_useraAssets[];
  totalNodes: number;
  page: number;
  totalPages: number;
}

export interface assetsForUser {
  useraAssets: assetsForUser_paginated;
}

export interface assetsForUserVariables {
  id: string;
  search?: string | null;
  type?: string | null;
  systemType?: string | null;
  owner?: string | null;
  pagination?: Pagination;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: contractorHome
// ====================================================

export interface contractorHome_user_clients_tasks_nodes {
  __typename: 'Task';
  id: string;
  type: TaskType;
  notes: string | null;
  adhoc: boolean;
  deadline: string | null;
  urgent: boolean | null;
  assets: tasksForClient_site_tasks_nodes_assets[];
  assetsCount: number;
  compliant: boolean | null;
  status: TaskStatus;
}

export interface contractorHome_user_clients_tasks {
  __typename: 'PaginatedTaskOutput';
  nodes: contractorHome_user_clients_tasks_nodes[];
}

export interface contractorHome_user_clients {
  __typename: 'Client';
  id: string;
  companyName: string;
  tasks: contractorHome_user_clients_tasks;
}

export interface contractorHome_user {
  __typename: 'User';
  id: string;
  clients: contractorHome_user_clients[];
}

export interface contractorHome {
  user: contractorHome_user;
}

export interface contractorHomeVariables {
  id: string;
  accessStatus?: UserRequestAccessStatus | null;
  upcoming?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: tasksForUser
// ====================================================

export interface tasksForUser_user_tasks_site {
  __typename: 'Site';
  id: string;
  companyName: string;
}

export interface tasksForUser_user_tasks {
  __typename: 'Task';
  id: string;
  type: TaskType;
  notes: string | null;
  adhoc: boolean;
  deadline: string | null;
  status: TaskStatus;
  site: tasksForUser_user_tasks_site;
  assets: tasksForClient_site_tasks_nodes_assets[];
  assetsCount: number;
  urgent: boolean | null;
  compliant: boolean | null;
}

export interface tasksForUser_user_clients {
  __typename: 'Client';
  id: string;
  companyName: string;
}

export interface tasksForUser_user {
  __typename: 'User';
  id: string;
  tasks: tasksForUser_user_tasks[];
  clients: tasksForUser_user_clients[];
}

export interface tasksForUser {
  user: tasksForUser_user;
}

export interface tasksForUserVariables {
  userId: string;
  dayDateRange?: DayDateRange | null;
  clientId?: string | null;
  urgent?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: tasksForClientFields
// ====================================================

export interface tasksForClientFields_approval {
  __typename: 'User';
  id: string;
}

export interface tasksForClientFields_internalResponsible {
  __typename: 'User';
  id: string;
  name: string;
}

export interface tasksForClientFields_contractorResponsible {
  __typename: 'Contractor';
  id: string;
  companyName: string;
}

export interface tasksForClientFields_assets_asset_system {
  __typename: 'System';
  name: string;
}

export interface tasksForClientFields_assets_asset {
  __typename: 'AssetOutput';
  system: tasksForClientFields_assets_asset_system;
}

export interface tasksForClientFields_assets {
  __typename: 'TaskAsset';
  asset: tasksForClientFields_assets_asset;
}

export interface tasksForClientFields {
  __typename: 'Task';
  id: string;
  idReadable: string;
  type: TaskType;
  approval: tasksForClientFields_approval | null;
  internalResponsible: tasksForClientFields_internalResponsible | null;
  contractorResponsible: tasksForClientFields_contractorResponsible | null;
  assets: tasksForClientFields_assets[];
  compliant: boolean | null;
  contractorResponsibleWorkOrderId: string | null;
  deadline: string | null;
  documentDeadline: string | null;
  status: TaskStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: taskForClientFields
// ====================================================

export interface taskForClientFields_internalResponsible {
  __typename: 'User';
  id: string;
  name: string;
}

export interface taskForClientFields_contractorResponsible {
  __typename: 'Contractor';
  id: string;
  companyName: string;
}

export interface taskForClientFields_approval_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
  originalName: string;
}

export interface taskForClientFields_approval {
  __typename: 'User';
  id: string;
  name: string;
  picture: taskForClientFields_approval_picture | null;
}

export interface taskForClientFields_attachments {
  __typename: 'File';
  id: string;
  originalName: string;
  srcUrl: string;
}

export interface taskForClientFields_assets_asset_system {
  __typename: 'System';
  id: string;
  name: string;
}

export interface taskForClientFields_assets_asset {
  __typename: 'AssetOutput';
  id: string;
  name: string;
  system: taskForClientFields_assets_asset_system;
}

export interface taskForClientFields_assets {
  __typename: 'TaskAsset';
  id: string;
  compliant: boolean | null;
  asset: taskForClientFields_assets_asset;
}

export interface taskForClientFields_correctiveOf {
  __typename: 'Task';
  id: string;
  idReadable: string;
}

export interface taskForClientFields_corrective {
  __typename: 'Task';
  id: string;
  idReadable: string;
}

export interface taskForClientFields {
  __typename: 'Task';
  id: string;
  idReadable: string;
  type: TaskType;
  internalResponsible: taskForClientFields_internalResponsible | null;
  contractorResponsible: taskForClientFields_contractorResponsible | null;
  contractorResponsibleWorkOrderId: string | null;
  approval: taskForClientFields_approval | null;
  assignedAt: any | null;
  notes: string | null;
  tasktitle: string | null;
  urgent: boolean | null;
  attachments: taskForClientFields_attachments[];
  status: TaskStatus;
  assets: taskForClientFields_assets[];
  correctiveOf: taskForClientFields_correctiveOf | null;
  corrective: taskForClientFields_corrective | null;
  deadline: string | null;
  documentDeadline: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: taskForOperativeFields
// ====================================================

export interface taskForOperativeFields_internalResponsible {
  __typename: 'User';
  id: string;
  name: string;
}

export interface taskForOperativeFields_contractorResponsible {
  __typename: 'Contractor';
  id: string;
  companyName: string;
}

export interface taskForOperativeFields_approval_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
  originalName: string;
}

export interface taskForOperativeFields_approval {
  __typename: 'User';
  id: string;
  name: string;
  picture: taskForOperativeFields_approval_picture | null;
}

export interface taskForOperativeFields_attachments {
  __typename: 'File';
  id: string;
  originalName: string;
  srcUrl: string;
}

export interface taskForOperativeFields_assets_asset {
  __typename: 'AssetOutput';
  id: string;
  name: string;
}

export interface taskForOperativeFields_assets {
  __typename: 'TaskAsset';
  id: string;
  compliant: boolean | null;
  asset: taskForOperativeFields_assets_asset;
}

export interface taskForOperativeFields_correctiveOf {
  __typename: 'Task';
  id: string;
  idReadable: string;
}

export interface taskForOperativeFields_corrective {
  __typename: 'Task';
  id: string;
  idReadable: string;
}

export interface taskForOperativeFields {
  __typename: 'Task';
  id: string;
  idReadable: string;
  type: TaskType;
  internalResponsible: taskForOperativeFields_internalResponsible | null;
  contractorResponsible: taskForOperativeFields_contractorResponsible | null;
  contractorResponsibleWorkOrderId: string | null;
  approval: taskForOperativeFields_approval | null;
  assignedAt: any | null;
  notes: string | null;
  tasktitle: string | null;
  urgent: boolean | null;
  attachments: taskForOperativeFields_attachments[];
  status: TaskStatus;
  assets: taskForOperativeFields_assets[];
  correctiveOf: taskForOperativeFields_correctiveOf | null;
  corrective: taskForOperativeFields_corrective | null;
  deadline: string | null;
  documentDeadline: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: getContractorFields
// ====================================================

export interface getContractorFields_logo {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface getContractorFields {
  __typename: 'Contractor';
  id: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  status: ContractorStatus;
  expertise: Expertise[];
  rating: number | null;
  logo: getContractorFields_logo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: taskCommentFields
// ====================================================

export interface taskCommentFields_author_picture {
  __typename: 'File';
  id: string;
  srcUrl: string;
}

export interface taskCommentFields_author {
  __typename: 'User';
  id: string;
  name: string;
  companyName: string;
  picture: taskCommentFields_author_picture | null;
}

export interface taskCommentFields {
  __typename: 'TaskComment';
  id: string;
  message: string;
  createdAt: any;
  archived: boolean;
  author: taskCommentFields_author;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AssetStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum CompliantStatus {
  false = 'false',
  true = 'true',
}

export enum AssetType {
  AIR_MONITOR = 'AIR_MONITOR',
  BIOFILTER = 'BIOFILTER',
  BUNDS = 'BUNDS',
  DRAIN = 'DRAIN',
  GENERAL_FACILITY = 'GENERAL_FACILITY',
  GREASE_TRAP = 'GREASE_TRAP',
  GROUND_WATER = 'GROUND_WATER',
  GULLEY = 'GULLEY',
  INTERCEPTOR = 'INTERCEPTOR',
  MANHOLE = 'MANHOLE',
  MISCELLANEOUS = 'MISCELLANEOUS',
  NOISE_MONITOR = 'NOISE_MONITOR',
  ODOUR_MONITOR = 'ODOUR_MONITOR',
  PUMP = 'PUMP',
  SAMPLING_POINT = 'SAMPLING_POINT',
  SURFACE_WATER = 'SURFACE_WATER',
  EQUIPMENT_VEHICLES = 'EQUIPMENT_VEHICLES',
  PEST_CONTROL_POINT = 'PEST_CONTROL_POINT',
  TANKS = 'TANKS',
  PITS = 'PITS',
  FIRE_EQUIPMENT = 'FIRE_EQUIPMENT',
}

export enum ClientStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum ContractorRequestAccessStatus {
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
  REQUESTED = 'REQUESTED',
}

export enum ContractorStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum Day {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

export enum DocumentCategory {
  AER = 'AER',
  CAD_DRAWING = 'CAD_DRAWING',
  RAMS = 'RAMS',
  DRAWING = 'DRAWING',
  EMERGENCY_RESPONSE_PLAN = 'EMERGENCY_RESPONSE_PLAN',
  FER = 'FER',
  FIREWATER_RETENTION = 'FIREWATER_RETENTION',
  GENERAL = 'GENERAL',
  GROUND_WATER_REPORT = 'GROUND_WATER_REPORT',
  MEETING_MINUTES = 'MEETING_MINUTES',
  METHOD_STATEMENT = 'METHOD_STATEMENT',
  NUTRIENT_MANAGEMENT_PLAN = 'NUTRIENT_MANAGEMENT_PLAN',
  ODOUR_MANAGEMENT_PLAN = 'ODOUR_MANAGEMENT_PLAN',
  PERMIT = 'PERMIT',
  REPORT = 'REPORT',
  REPORT_NON_COMPLIANCE = 'REPORT_NON_COMPLIANCE',
  RISK_ASSESMENT = 'RISK_ASSESMENT',
  TOOLBOX_TALK = 'TOOLBOX_TALK',
  WID_PERFORMANCE_REPORT = 'WID_PERFORMANCE_REPORT',
}

export enum DocumentStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum DrawingStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum Expertise {
  AIR = 'AIR',
  BOREHOLES = 'BOREHOLES',
  BUNDS = 'BUNDS',
  DRAIN_PIPE_CCTV = 'DRAIN_PIPE_CCTV',
  DRAIN_PIPE_REPAIR = 'DRAIN_PIPE_REPAIR',
  GULLEY_REPAIR = 'GULLEY_REPAIR',
  GULLEY_SURVEY = 'GULLEY_SURVEY',
  INTEGRITY_TESTING = 'INTEGRITY_TESTING',
  INTERCEPTOR_REPAIR = 'INTERCEPTOR_REPAIR',
  INTERCEPTOR_SURVEY = 'INTERCEPTOR_SURVEY',
  MANHOLE_REPAIR = 'MANHOLE_REPAIR',
  MANHOLE_SURVEYING = 'MANHOLE_SURVEYING',
  NOISE = 'NOISE',
  ODOUR = 'ODOUR',
  PUMP_REPAIR = 'PUMP_REPAIR',
  PUMP_SURVEY = 'PUMP_SURVEY',
  WATER_SAMPLING = 'WATER_SAMPLING',
  WATER_TESTING = 'WATER_TESTING',
}

export enum LogType {
  mutation = 'mutation',
  query = 'query',
}

export enum Month {
  APRIL = 'APRIL',
  AUGUST = 'AUGUST',
  DECEMBER = 'DECEMBER',
  FEBRUARY = 'FEBRUARY',
  JANUARY = 'JANUARY',
  JULY = 'JULY',
  JUNE = 'JUNE',
  MARCH = 'MARCH',
  MAY = 'MAY',
  NOVEMBER = 'NOVEMBER',
  OCTOBER = 'OCTOBER',
  SEPTEMBER = 'SEPTEMBER',
}

export enum NotificationScheduleType {
  DAY1 = 'DAY1',
  DAY3 = 'DAY3',
  NON_COMPLIANCE = 'NON_COMPLIANCE',
  WEEK1 = 'WEEK1',
}

export enum NotificationType {
  CONTRACTOR_ACCEPTED = 'CONTRACTOR_ACCEPTED',
  CONTRACTOR_DENIED = 'CONTRACTOR_DENIED',
  CONTRACTOR_REQUESTED = 'CONTRACTOR_REQUESTED',
  CONTRACTOR_USER_ACCEPTED = 'CONTRACTOR_USER_ACCEPTED',
  CONTRACTOR_USER_DENIED = 'CONTRACTOR_USER_DENIED',
  CONTRACTOR_USER_REQUESTED = 'CONTRACTOR_USER_REQUESTED',
  TASK_ACCEPTED = 'TASK_ACCEPTED',
  TASK_ASSIGNED_TO_CONTRACTOR = 'TASK_ASSIGNED_TO_CONTRACTOR',
  TASK_ASSIGNED_TO_CONTRACTOR_USER = 'TASK_ASSIGNED_TO_CONTRACTOR_USER',
  TASK_ASSIGNED_TO_INTERNAL = 'TASK_ASSIGNED_TO_INTERNAL',
  TASK_CONTRACTOR_ACCEPTED = 'TASK_CONTRACTOR_ACCEPTED',
  TASK_CONTRACTOR_REJECTED = 'TASK_CONTRACTOR_REJECTED',
  TASK_REJECTED = 'TASK_REJECTED',
}

export enum ReOccurenceType {
  MONTHLY = 'MONTHLY',
  NA = 'NA',
  NEVER = 'NEVER',
  WEEKLY = 'WEEKLY',
  YEARLY = 'YEARLY',
  DAILY = 'DAILY',
}

export enum ReOccurenceWeekIndex {
  FIRST = 'FIRST',
  FOURTH = 'FOURTH',
  LAST = 'LAST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
}

export enum Role {
  CLIENT_ENVIRONMENTAL_OFFICER = 'CLIENT_ENVIRONMENTAL_OFFICER',
  CLIENT_OPERATIVE = 'CLIENT_OPERATIVE',
  CLIENT_TEAM_MEMBER = 'CLIENT_TEAM_MEMBER',
  CONTRACTOR_OPERATIONS_MANAGER = 'CONTRACTOR_OPERATIONS_MANAGER',
  CONTRACTOR_TECHNICIAN = 'CONTRACTOR_TECHNICIAN',
  PROJECT_MANAGER = 'PROJECT_MANAGER',
}

export enum SystemType {
  AIR = 'AIR',
  BUNDS = 'BUNDS',
  FOUL = 'FOUL',
  NOISE = 'NOISE',
  ODOUR = 'ODOUR',
  PROCESS = 'PROCESS',
  STORM = 'STORM',
  GENERAL_FACILITY = 'GENERAL_FACILITY',
  DUST = 'DUST',
  EQUIPMENT_VEHICLES = 'EQUIPMENT_VEHICLES',
  MISCELLANEOUS = 'MISCELLANEOUS',
  BIOFILTER = 'BIOFILTER',
  GROUND_WATER = 'GROUND_WATER',
  FIRE_EQUIPMENT = 'FIRE_EQUIPMENT',
}

export enum TaskAssetStatus {
  COMPLETED = 'COMPLETED',
  INTACT = 'INTACT',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum TaskStatus {
  APPROVED_CLOSED = 'APPROVED_CLOSED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  CONSULTANT_APPROVED = 'CONSULTANT_APPROVED',
  CONSULTANT_REJECTED = 'CONSULTANT_REJECTED',
  CONTRACTOR_ACCEPTED = 'CONTRACTOR_ACCEPTED',
  CONTRACTOR_REJECTED = 'CONTRACTOR_REJECTED',
  IN_PROGRESS = 'IN_PROGRESS',
  REJECTED = 'REJECTED',
  REJECTED_CLOSED = 'REJECTED_CLOSED',
  REVIEW_REQ_ACCEPTED = 'REVIEW_REQ_ACCEPTED',
  REVIEW_REQ_REJECTED = 'REVIEW_REQ_REJECTED',
  REVIEW_REQ_SENT = 'REVIEW_REQ_SENT',
  SENT = 'SENT',
  SUPP_INFO_SUBMITTED = 'SUPP_INFO_SUBMITTED',
  UNASSIGNED = 'UNASSIGNED',
}

export enum ModuleType {
  compliance = 'compliance',
  facility_management = 'facility_management',
}

export enum TaskType {
  AUDIT = 'AUDIT',
  COMPLIANCE_CHECK = 'COMPLIANCE_CHECK',
  OTHER = 'OTHER',
  REPAIR = 'REPAIR',
  SAMPLING = 'SAMPLING',
  SURVEY = 'SURVEY',
  TESTING = 'TESTING',
  TOOLBOX_TALK = 'TOOLBOX_TALK',
  HEALTH_AND_SAFETY = 'HEALTH_AND_SAFETY',
  CLEANING = 'CLEANING',
  PEST_CONTROL = 'PEST_CONTROL',
  REPORT = 'REPORT',
}

export enum UserRequestAccessStatus {
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
  REQUESTED = 'REQUESTED',
}

export interface AssetCoordinatesInput {
  latitude: number;
  longitude: number;
}

export interface AssetCreate {
  name: string;
  department?: string | null;
  additionalInformation?: string | null;
  coordinates?: AssetCoordinatesInput | null;
  specificInfo?: SpecificInfoInput | null;
  systemId: string;
  type: AssetType;
  siteId: string;
  ownerId: string;
  drawingIds: string[];
  expireOption?: boolean | false;
  expiration?: string;
}

export interface AssetUpdate {
  id: string;
  name?: string | null;
  department?: string | null;
  additionalInformation?: string | null;
  coordinates?: AssetCoordinatesInput | null;
  specificInfo?: SpecificInfoInput | null;
  systemId?: string | null;
  type?: AssetType | null;
  siteId?: string | null;
  ownerId?: string | null;
  drawingIds?: string[] | null;
  expireOption?: boolean | false;
  expiration?: string;
}

export interface ClientCreateInput {
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  twoFactorAuth?: boolean | null;
  logoId?: string | null;
}

export interface ClientUpdateInput {
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  twoFactorAuth: boolean;
  logoId?: string | null;
}

export interface ContractorDetails {
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  logoId?: string | null;
  expertise: Expertise[];
}

export interface ContractorRequestAccessRights {
  siteIds: string[];
  systemTypes: SystemType[];
  contractNumber: string;
  contractExpiracy: string;
  workOrders: WorkOrderInput[];
  managerId: string;
  canBeListedAsReference: boolean;
  twoFactorAuth: boolean;
  customMessage?: string | null;
}

export interface DayDateRange {
  minDayDate: string;
  maxDayDate: string;
}

export interface DocumentCreate {
  name: string;
  fileId: string;
  siteId: string;
  category: DocumentCategory;
  systemIds: string[];
  assetIds: string[];
}

export interface DocumentUpdate {
  name?: string | null;
  category?: DocumentCategory | null;
}

export interface DrawingCreateInput {
  siteId: string;
  systemType: SystemType;
  files: string[];
}

export interface DrawingUpdateInput {
  id: string;
  siteId: string;
  systemType: SystemType;
  fileId: string;
}

export interface PaginationInput {
  page?: number | null;
  pageSize?: number | null;
}

export interface PumpInput {
  name: string;
  pass: string;
}

export interface ReOccurenceInput {
  type: ReOccurenceType;
  endDate?: any | null;
  months?: Month[] | null;
  weekIndex?: ReOccurenceWeekIndex | null;
  day?: Day | null;
  days?: Day[] | null;
}

export interface SiteCreate {
  name: string;
  address: string;
}

export interface SiteUpdate {
  name: string;
  address: string;
  id?: string | null;
}

export interface SpecificInfoInput {
  pumps: PumpInput[];
}

export interface SubscriptionCreate {
  idReadable: string;
  billingFrequency: string;
  startingMonth: number;
  sitesMaxNumber: number;
  contractorsMaxNumber: number;
  sites: SiteCreate[];
}

export interface SubscriptionUpdate {
  idReadable: string;
  billingFrequency: string;
  startingMonth: number;
  sitesMaxNumber: number;
  contractorsMaxNumber: number;
  sites: SiteUpdate[];
}

export interface SystemCreateInput {
  type: SystemType;
  name: string;
}

export interface SystemUpdateInput {
  name: string;
}

export interface TaskCreateInput {
  siteId: string;
  assetIds: string[];
  type: TaskType;
  contractorId?: string | null;
  workOrderId?: string | null;
  internalUserId?: string | null;
  approvalUserId: string;
  tasktitle?: string | null;
  notes?: string | null;
  deadline: string;
  documentDeadline: string;
  reOccurence?: ReOccurenceInput | null;
  correctiveOfTaskId?: string | null;
  urgent?: boolean | null;
  fileIds: string[];
  adhoc?: boolean | null;
  subform?: string  | null;
  projectid?: string  | null;
  taskstart?: string  | null;
}

export interface TaskUpdateInput {
  contractorId?: string | null;
  assetIds: string[];
  type: TaskType;
  workOrderId?: string | null;
  internalUserId?: string | null;
  approvalUserId?: string | null;
  notes?: string | null;
  tasktitle?: string | null;
  deadline?: string | null;
  documentDeadline?: string | null;
  urgent?: boolean | null;
  fileIds?: string[] | null;
  applyChangesToFutureOccurences?: boolean | null;
  subform?: string  | null;
  projectid?: string  | null;
  taskstart?: string  | null;
}

export interface UserCreate {
  name: string;
  email: string;
  phonenumber: string;
  isAdmin: boolean;
  siteIds?: string[] | null;
  role: Role;
  fullAccess: boolean;
  adhoc: boolean;
  sendEmail: boolean;
  message?: string | null;
  imgId?: string | null;
  notificationSchedule?: NotificationScheduleType[] | null;
  notificationStatus?: TaskStatus[] | null;
}

export interface UserUpdate {
  name: string;
  email: string;
  phonenumber: string;
  isAdmin: boolean;
  siteIds?: string[] | null;
  role: Role;
  sendEmail: boolean;
  message?: string | null;
  imgId?: string | null;
  fullAccess: boolean;
  fcmId?: string | null;
  adhoc: boolean;
  notificationSchedule?: NotificationScheduleType[] | null;
  notificationStatus?: TaskStatus[] | null;
}

export interface WorkOrderInput {
  id: string;
  expiracy: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
// UPDATE FW 2022-04-10 For Assets Pagination
export interface assetsForSitePaged {
  site: assetsForSitePaged_site;
}

export interface assetsForSitePaged_site {
  __typename: 'Site';
  id: string;
  pageassets: assetsForSitePaged_site_assets;
}

export interface assetsForSitePaged_site_assets {
  __typename: 'PaginatedAssetOutputOutput';
  nodes: assetsForSitePaged_site_assets_nodes[];
  totalNodes: number;
  page: number;
  totalPages: number;
}

export interface assetsForSitePaged_site_assets_nodes {
  __typename: 'AssetOutput';
  id: string;
  site: assetsForSite_site_assets_site;
  upcomingWorks: assetsForSite_site_assets_upcomingWorks[] | null;
  recentWorks: assetsForSite_site_assets_recentWorks[] | null;
  department: string | null;
  system: assetsForSite_site_assets_system;
  name: string;
  type: AssetType;
  createdAt: any;
  owner: assetsForSite_site_assets_owner;
  status: AssetStatus;
  coordinates: assetsForSite_site_assets_coordinates | null;
  additionalInformation: string | null;
  drawings: assetsForSite_site_assets_drawings[];
  compliant: boolean | null;
  expireOption: boolean | false;
  expiration: string | null;
  pumpInfo: assetsForSite_site_assets_pumpInfo[] | null;
}
export interface assetsForSitePagedVariables {
  siteId: string;
  pagination?: PaginationInput | null;
  search?: string | null;
  type?: AssetType | null;
  systemType?: SystemType | null;
  systemRef?: string | null;
  owner?: string | null;
}

// FW 2022-04-12 Operative Home Paged
export interface operativeHomePaged_user {
  __typename: 'User';
  id: string;
  taskspaged: operativeHomePaged_user_tasks;
}

export interface operativeHomePaged {
  user: operativeHomePaged_user;
}

export interface operativeHomePagedVariables {
  id: string;
  withoutRejected?: boolean | null;
  status?: string
}


export interface operativeHomePaged_user_tasks_nodes {
  __typename: 'Task';
  id: string;
  type: TaskType;
  notes: string | null;
  adhoc: boolean;
  deadline: string | null;
  urgent: boolean | null;
  assetsCount: number;
  assets: tasksForClient_site_tasks_nodes_assets[];
  compliant: boolean | null;
  status: TaskStatus;
  site: operativeHome_user_tasks_site;
}

export interface operativeHomePaged_user_tasks {
  __typename: 'PaginatedTaskOutput';
  nodes: operativeHomePaged_user_tasks_nodes[];
  totalNodes: number;
  page: number;
  totalPages: number;
}
export interface operativeHomePagedVariables {
  id: string;
  pagination?: PaginationInput | null;
  withoutRejected?: boolean | null;
  status?: string
}


export interface getAssetForRegisterPaged_asset {
  __typename: 'AssetOutput';
  id: string;
  department: string | null;
  system: getAssetForRegister_asset_system;
  name: string;
  type: AssetType;
  createdAt: any;
  owner: getAssetForRegister_asset_owner;
  status: AssetStatus;
  coordinates: getAssetForRegister_asset_coordinates | null;
  additionalInformation: string | null;
  drawings: getAssetForRegister_asset_drawings[];
  expireOption: boolean | false;
  expiration: string;
}

export interface getAssetForRegisterPaged {
  asset: getAssetForRegisterPaged_site_assets;
}

export interface getAssetForRegisterPaged_site {
  __typename: 'PaginatedAssetOutputOutput';
  id: string;
  pageassets: assetsForSitePaged_site_assets;
}

export interface getAssetForRegisterPaged_pagedAssetsById {
  __typename: 'PaginatedAssetOutputOutput';
  pagedAssetsById: getAssetForRegisterPaged_site_assets;
}

export interface getAssetForRegisterPaged_site_assets {
  __typename: 'PaginatedAssetOutputOutput';
  nodes: assetsForSitePaged_site_assets_nodes[];
  totalNodes: number;
  page: number;
  totalPages: number;
}