const enClients = {
  upcomingTasks: 'Upcoming activities',
  addSubscription: 'Add Subscription',
  editSubscription: 'Edit Subscription',
  subscriptionDetails: 'Subscription Details',
  subscriptionId: 'Subscription ID',
  subscriptionIdAlreadyExists: "Subscription ID '{{id}}' is already exists.",
  billingFrequency: 'Frequency of Billing',
  startingMonth: 'Starting Month',
  numberOfSites: 'Number of Sites',
  numberOfContractors: 'Number of Contractors',
  site: 'Site',
  site_optionalPlural: 'Site(s)',
  siteName: 'Site Name',
  siteAddress: 'Site Address',
  addSite: 'Add Another Site',
  assetTypes: 'Asset Types',
  assetType: 'Asset Type',
  users: 'Users',
  assets: 'Assets',
  noAssets: 'No Assets',
  searchAssetsPlaceholder: 'Search by Asset Name. Drawing ID, etc.',
  addAsset: 'Add Asset',
  editAsset: 'Edit Asset',
  editAsset_plural: 'Edit {{count}} Assets',
  addAssetError: 'Can not add asset',
  noSitesForClient: 'Please add at least one Site for the Client.',
  noAssetsForClient: "Please allow at least one Asset Type for the Client's Subscription.",
  noUsersForClient: 'Please add at least one User for the Client.',
  department: 'Department',
  departmentPlaceholder: 'Maintenance',
  systemType: 'System Type',
  system_optionalPlural: 'System(s)',
  assetId: 'Asset ID',
  assetName: 'Asset Name',
  assetNamePlaceholder: 'Odour Monitoring point1',
  assetOwner: 'Asset Owner',
  drawingId: 'Drawing ID',
  drawing: 'Drawing',
  addDrawing: 'Add Another Drawing',
  assetCoordinates: 'Asset Coordinates',
  assetCoordinatesExample: '47.482668, 19.066347',
  assetCoordinatesWrongFormat: 'Invalid format for coordinates',
  assetCoordinatesFormatHelper: 'Correct format is like: $t(assetCoordinatesExample)',
  additionalInformation: 'Additional Information',
  additionalInformationPlaceholder: 'Measurements w23x23cm',
  exportAssetsErrorTitle: 'Could not download template',
  importAssetsTitle: 'Upload asset list template',
  importAssetsErrorTitle: 'Could not upload template',
  importAssetsErrorRequired: 'These fields are required:',
  importAssetsErrorObjectNotFound: "Couldn't find any object for these values:",
  importAssetsErrorSystemIsNotAssociatedForAssetType:
    'These Asset Types can not be associated for the selected System Types:',
  importAssetsErrorDrawingsAreNotFromTheAssetSite: 'These Drawings are not from the selected Site:',
  importAssetsErrorSystemIsNotFromTheAssetSite: 'These Systems are not from the selected Site:',
  noSubscription: 'Your client has no valid subscription',
  noAllowedAssets: "Your client's subscription has no allowed asset types",
  systemsTitle: 'Manage System References',
  addSystem: 'Add System Reference',
  systemName: 'System Reference',
  systemName_short: 'System Ref',
  numberOfAssets: 'No of Assets',
  editSystem: 'Edit System Reference',
  noSystemsForFilter: 'There are no {{type}} Systems in {{site}}.',
  expiry: 'Expire Compliance After Set Period'
};
export default enClients;
